import React, { createContext, useMemo, useState } from 'react';

const DEFAULT_ROWS_PER_PAGE = 50;

export const SearchForContext = createContext({
  resetValues: () => {},
  page: 0,
  setPage: (_n) => {},
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  setRowsPerPage: (_n) => {},
  count: 0,
  setCount: () => {},
  search: {},
  setSearch: (_q) => {},
  searchColumn: null,
  setSearchColumn: () => {},
  loading: false,
  setLoading: (_l) => {},
  hasResults: false,
  setHasResults: (_r) => {}
});

export const SearchForContextWrapper = ({ children }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState({});
  const [searchColumn, setSearchColumn] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasResults, setHasResults] = useState(false);

  const resetValues = () => {
    setPage(0);
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    setCount(0);
    setSearch({});
    setSearchColumn(null);
    setLoading(false);
    setHasResults(false);
  };

  const memoSearchForCtx = useMemo(() => ({
    resetValues,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
    setCount,
    search,
    setSearch,
    searchColumn,
    setSearchColumn,
    loading,
    setLoading,
    hasResults,
    setHasResults
  }), [
    page,
    rowsPerPage,
    count,
    search,
    searchColumn,
    loading,
    hasResults
  ]);

  return (
    <SearchForContext.Provider value={memoSearchForCtx}>
      {children}
    </SearchForContext.Provider>
  );
};
