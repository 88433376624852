import React from 'react';
import { Box } from '@mui/material';
import { ODSelect } from '@OptimalDynamics/core-ai-common-ui';

/**
 * THIS FILE EXISTS ENTIRELY TO MAKE TYPESCRIPT HAPPY
 * Delete once the problem is resolved via the component library
 * 
 * TS isn't failing build locally but it is failing build in AWS
 * This component IS used successfully in a JSX file
 * So making this file JSX entirely to negate TS's complaints
 */

const ODSelectWrapper = (props) => (
  <Box>
    <ODSelect {...props} />
  </Box>
);

export default ODSelectWrapper;
