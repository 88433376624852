import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Typography } from '@mui/material';
import CampaignSharp from '@mui/icons-material/CampaignSharp';
import CheckCircleOutlineSharp from '@mui/icons-material/CheckCircleOutlineSharp';
import ScheduleSharp from '@mui/icons-material/ScheduleSharp';
import HistoryToggleOffSharp from '@mui/icons-material/HistoryToggleOffSharp';
import HomeSharp from '@mui/icons-material/HomeSharp';
import TimerSharp from '@mui/icons-material/TimerSharp';
import EmojiFoodBeverageSharp from '@mui/icons-material/EmojiFoodBeverageSharp';
import FlagSharp from '@mui/icons-material/FlagSharp';
import ThreeSixtySharp from '@mui/icons-material/ThreeSixtySharp';
import { ODLaneIcon } from '@OptimalDynamics/core-ai-common-ui';
import { DATE_TIME_SLASH_SPACE_DIV, DEFAULT_TIMEZONE, SHORT_DATE_TIME_AT_DIV, SHORT_DATE_TIME_TZ, getBrowserizedTime, getLocalizedTimeRange } from '../../../utils/datetimes';
import { setOfferedLoadDetails } from '../../../store/actions/dispatchingAction';
import {
  IN_PROGRESS,
  LOADED,
  TMS,
  LOAD_SEARCH,
  HOLD,
  ETA,
  ETD,
  TIME_AT_HOME,
  EMPTY_TO_LOAD,
  EMPTY_TO_HOME,
  LOAD,
  NOT_STARTED,
  COMPLETED,
  POST_LOAD_EMPTY,
  PRESIM_END,
  DO_NOT_LINK
} from '../helpers/constants';
import { getOriginLabel, getDestinationLabel } from '../helpers/dispatchingScripts';
import { DetailCardTitle, DetailCardAccordion } from '../DetailCard';
import {
  DetailCardTimeline,
  HoldRow,
  HomeRow,
  LocationRow,
  EventRow,
  Mileage,
  generateTimestamp,
  trimHos
} from '../DetailCard/DetailCardTimeline';
import { AssignmentDatumProps, DriverPlanDetailCardProps, DriverPlanItem, EmptyDriverPlanItem, HoldDriverPlanItem, HomeTimeDriverPlanItem, LoadedDriverPlanItem } from '../types';

const ERROR_418 = 'Error 418';

export const PLAN_IDENTIFIERS = {
  [HOLD]: {
    icon: <TimerSharp />,
    label: 'Estimated Driver Wait or HOS Break'
  },
  [LOADED]: {
    icon: <ODLaneIcon />,
    label: null, // This *must* be uniquely overwritten during execution
    description: undefined // This is supposed to be the Shipper ID
  },
  [EMPTY_TO_LOAD]: {
    icon: <ThreeSixtySharp />,
    label: 'Empty To Loaded'
  },
  [EMPTY_TO_HOME]: {
    icon: <ThreeSixtySharp />,
    label: 'Empty To Home'
  },
  [TIME_AT_HOME]: {
    icon: <HomeSharp />,
    label: 'Estimated Time at Home'
  },
  [POST_LOAD_EMPTY]: {
    icon: <ThreeSixtySharp />,
    label: 'Post-Load Empty'
  },
  [PRESIM_END]: {
    icon: <ThreeSixtySharp />,
    label: 'Empty'
  },
  default: {
    icon: <EmojiFoodBeverageSharp />,
    label: ERROR_418
  }
};

export const getTimeLabel = (
  startTime: string,
  endTime: string,
  isLoad: boolean,
  status: string | null = null,
  noPreAssignmentSimResultsMvpWithStatesDetail = false,
  startTimeFormat: string = SHORT_DATE_TIME_AT_DIV,
  endTimeFormat: string = SHORT_DATE_TIME_AT_DIV) => {
  const startMoment = moment.tz(startTime, startTimeFormat, DEFAULT_TIMEZONE);
  const endMoment = moment.tz(endTime, endTimeFormat, DEFAULT_TIMEZONE);

  const loadStatus = isLoad ? status : null;

  if (!loadStatus && !noPreAssignmentSimResultsMvpWithStatesDetail) {
    if (endMoment.isBefore()) return COMPLETED;
    if (startMoment.isSameOrBefore()) return IN_PROGRESS;
  } else {
    if (loadStatus !== NOT_STARTED) return loadStatus;
  }

  if (startMoment.isBetween(moment(), moment().add(4, 'h'))) {
    return `${!!loadStatus ? 'Pick Up' : 'Start'} within 4 hrs`;
  }

  return NOT_STARTED;
};

const AssignmentDatum = ({ label, value }: AssignmentDatumProps) => (
  <Box
    sx={{
      height: '40px',
      borderBottom: '1px solid',
      borderColor: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}
  >
    <strong>{label}</strong>
    {value}
  </Box>
);

const MissingTypeHandlingNotice = () => (
  <>
    <Typography><strong>I&rsquo;m a teapot!</strong></Typography>
    <Typography sx={{ whiteSpace: 'normal' }}>
      Oops! This feature is still under development. If you see this, please let an engineer know.
    </Typography>
  </>
);

export const RelativeTime = ({ label, selected }: { label: string | null, selected: boolean }) => {
  const color = selected ? 'text.primary' : 'text.secondary';
  const [timeLabelColor, timeIcon] = (
    (timeLabel) => {
      switch (timeLabel) {
        case COMPLETED:
          return ['text.primary', <CampaignSharp />];
        case IN_PROGRESS:
          return ['text.primary', <CheckCircleOutlineSharp sx={{ color: 'success.main' }} />];
        case NOT_STARTED:
          return [color, <HistoryToggleOffSharp sx={{ color }} />];
        default:
          return [color, <ScheduleSharp sx={{ color }} />];
      }
    }
  )(label);

  return (
    <>
      <Typography sx={{ flexGrow: '1', textAlign: 'right', color: timeLabelColor }}>{label}</Typography>
      <Box sx={{ height: '32px', width: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {timeIcon}
      </Box>
    </>
  );
};

export const DriverPlanDetailCard = ({
  plan, index, selected, randomization, setHOSStatusData, handleUnassignSelect
}: DriverPlanDetailCardProps) => {
  const { showRelaxedMatches, orgUseRelaxedDispatching, noPreAssignmentSimResultsMvpWithStatesDetail } = useFlags();
  const dispatch = useDispatch();
  const isLoad = plan.move_type === LOADED;
  
  const timeLabel = getTimeLabel(plan.start_time, plan.end_time, isLoad, (plan as DriverPlanItem).status || null, noPreAssignmentSimResultsMvpWithStatesDetail);
  if (plan.move_type === HOLD && timeLabel === COMPLETED) return <></>;

  // @ts-expect-error TS objects to using a string as an index key
  let identifier = isLoad ? { ...PLAN_IDENTIFIERS[LOADED], label: plan.load_id } : PLAN_IDENTIFIERS[plan.move_type];
  identifier = identifier ?? PLAN_IDENTIFIERS.default;

  return (
    <DetailCardTitle
      key={`planned-load-${randomization}-${index}`}
      id={`assign-drivers-slideout-plan-${index}`}
      title="Match"
      source={!!(plan as LoadedDriverPlanItem)?.assignment && !(plan as LoadedDriverPlanItem).assignment?.overall_rating ? LOAD_SEARCH : TMS}
      rating={(plan as LoadedDriverPlanItem)?.assignment?.overall_rating}
      isRelaxed={showRelaxedMatches && orgUseRelaxedDispatching && (plan as LoadedDriverPlanItem)?.assignment?.is_relaxed}
      active={true}
      selected={selected}
      relativeTime={<RelativeTime label={timeLabel} selected={selected} />}
      handleClick={!handleUnassignSelect ? undefined : () => handleUnassignSelect(plan)}
    >
      <DetailCardAccordion
        key={`plan-${index}`}
        id={`assign-drivers-slideout-plan-agenda-item-${index}`}
        type={isLoad ? LOAD : DO_NOT_LINK}
        identifier={identifier}
        selected={selected}
        onShowOfferedLoad={() => {
          dispatch(setOfferedLoadDetails({
            load_id: (plan as LoadedDriverPlanItem).load_id,
            load_data: plan
          }));
        }}
      >
        {identifier.label === ERROR_418 && <MissingTypeHandlingNotice />}

        {!!(plan as LoadedDriverPlanItem)?.assignment && (
          <Box sx={{ marginTop: '-16px', mb: 2, borderColor: 'inherit' }}>
            <AssignmentDatum
              label="Date Assigned"
              value={getBrowserizedTime((plan as LoadedDriverPlanItem).assignment?.assigned_datetime, DATE_TIME_SLASH_SPACE_DIV, SHORT_DATE_TIME_TZ)}
            />
            <AssignmentDatum label="User" value={(plan as LoadedDriverPlanItem).assignment?.user_email ?? ''} />
          </Box>
        )}

        <DetailCardTimeline selected={selected}>
          {!!(plan as LoadedDriverPlanItem).empty_mileage_before && <Mileage mileage={(plan as LoadedDriverPlanItem).empty_mileage_before} />}
          {(() => {
            switch (plan.move_type) {
              case HOLD:
                return (
                  <HoldRow
                    start={plan.start_time}
                    end={plan.end_time}
                    timezone={(plan as HoldDriverPlanItem).hold_timezone}
                    location={(plan as HoldDriverPlanItem).hold_loc}
                    selected={selected}
                  />
                );
              case LOADED:
                const load = plan as LoadedDriverPlanItem;
                return (
                  <>
                    {(timeLabel !== IN_PROGRESS) && (
                      <LocationRow
                        label={getOriginLabel(load.orig_live_flag, noPreAssignmentSimResultsMvpWithStatesDetail)}
                        estimates={noPreAssignmentSimResultsMvpWithStatesDetail ? undefined : {
                          [ETD]: generateTimestamp(load.start_time, load.pickup_timezone)
                        }}
                        timezone={load.pickup_timezone}
                        location={load.pickup_loc}
                        selected={selected}
                        hideAppointment
                        hos={trimHos(load?.hos_start_status)}
                        openHosDialog={() => {
                          setHOSStatusData({
                            hos_status: load?.hos_start_status,
                            road_mins_remain_end: load?.road_mins_remain_start_info,
                            duty_mins_remain_end: load?.duty_mins_remain_start_info,
                            eight_days_mins_remain_end: load?.eight_days_mins_remain_start_info,
                            avail_for_dispatch_et: load?.start_time,
                            avail_timezone: load?.pickup_timezone
                          });
                        }}
                      />
                    )}
                    <LocationRow
                      label={getDestinationLabel(load.dest_drop_flag, noPreAssignmentSimResultsMvpWithStatesDetail)}
                      estimates={noPreAssignmentSimResultsMvpWithStatesDetail ? undefined : {
                        [ETA]: generateTimestamp(load.end_time, load.dropoff_timezone)
                      }}
                      timezone={load.dropoff_timezone}
                      location={load.dropoff_loc}
                      mileage={load.dist_from_dest}
                      icon={<FlagSharp />}
                      selected={selected}
                      hideAppointment
                      hos={trimHos(load?.hos_end_status)}
                      openHosDialog={() => {
                        setHOSStatusData({
                          hos_status: load?.hos_end_status,
                          road_mins_remain_end: load?.road_mins_remain_end_info,
                          duty_mins_remain_end: load?.duty_mins_remain_end_info,
                          eight_days_mins_remain_end: load?.eight_days_mins_remain_end_info,
                          avail_for_dispatch_et: load?.end_time,
                          avail_timezone: load?.dropoff_timezone
                        });
                      }}
                    />
                  </>
                );
              case TIME_AT_HOME:
              case EMPTY_TO_HOME:
                const home = plan as HomeTimeDriverPlanItem;
                return (
                  <HomeRow
                    label={plan.move_type === TIME_AT_HOME ? 'Home Time' : 'Home'}
                    time={plan.start_time}
                    timeWindow={getLocalizedTimeRange(plan.start_time, plan.end_time, home.location_timezone, SHORT_DATE_TIME_AT_DIV)}
                    timezone={home.location_timezone}
                    location={home.location}
                    selected={selected}
                    mileage={(plan as EmptyDriverPlanItem)?.empty_miles}
                  />
                );
              case EMPTY_TO_LOAD:
              case POST_LOAD_EMPTY:
              case PRESIM_END:
                const empty = plan as EmptyDriverPlanItem;
                return (
                  <LocationRow
                    label="Destination"
                    mileage={empty.empty_miles}
                    location={empty.dest_loc}
                    timezone={empty.dest_timezone}
                    estimates={{
                      [ETA]: generateTimestamp(plan.end_time, empty.dest_timezone)
                    }}
                    selected={selected}
                  />
                );
              default:
                return (
                  <EventRow icon={<EmojiFoodBeverageSharp />} color="colors.accessiblePurple">
                    <MissingTypeHandlingNotice />
                  </EventRow>
                );
            }
          })()}
        </DetailCardTimeline>
      </DetailCardAccordion>
    </DetailCardTitle>
  );
};
