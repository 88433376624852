/* eslint-disable no-shadow */
import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { sortBy } from 'lodash';
import moment from 'moment-timezone';
import { Box, Link, Typography, useTheme } from '@mui/material';
import {
  ODButton,
  ODDialog,
  ODLaneIcon,
  ODLaneSearchIcon,
  ODHandIcon,
  ODTable,
  ODTableHead,
  ODTableRow,
  ODTableCell,
  ODTableBody,
  ODTooltip,
  ODNoLoadIcon
} from '@OptimalDynamics/core-ai-common-ui';
import AddSharp from '@mui/icons-material/AddSharp';
import AttachMoneySharp from '@mui/icons-material/AttachMoneySharp';
import CheckSharp from '@mui/icons-material/CheckSharp';
import CloseSharp from '@mui/icons-material/CloseSharp';
import HomeSharp from '@mui/icons-material/HomeSharp';
import PeopleSharp from '@mui/icons-material/PeopleSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import SearchSharp from '@mui/icons-material/SearchSharp';
import StarSharp from '@mui/icons-material/StarSharp';
import EventNoteSharp from '@mui/icons-material/EventNoteSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import { RootState } from '../../../store/reducers';
import { fetchOfferedLoadDetails, setOfferedLoadDetails } from '../../../store/actions/dispatchingAction';
import instance from '../../../utils/axios_instance';
import { getLocalizedTime, AMERICAN_DATE_TIME, DEFAULT_TIMEZONE, SHORT_DATE_TIME_AT_DIV, getLocalizedTimeRange } from '../../../utils/datetimes';
import { DriverLoadDetailContext } from '../../../utils/context/driverLoadDetailContext';
import { ConfirmContainer } from '../../../common';
import ODTabs from '../../../common/ODTabs';
import { TuneOffIcon } from '../../../common/icons';
import BetaChip from '../../../common/BetaChip';
import RightDrawer from '../components/RightDrawer';
import ODLoaderLabeled from '../components/ODLoader';
import { DispatchingSummaryTitle } from '../DispatchingSummary';
import { DetailCardTitle, DetailCardAccordion, NoAvailableMatchesAccordion, EventRow } from '../DetailCard';
import { DriverPlanDetailCard, getTimeLabel } from './DriverPlanDetailCard';
import PermitsAndRequirementsDialog from '../shared/PermitsAndRequirementsDialog';
import OfferedLoadDialog from '../shared/OfferedLoadsDialog';
import SearchForModal from '../shared/SearchForModal';
import SearchDialog from '../shared/SearchDialog';
import LoadsSearch from './LoadsSearch';
import SelectedDriverCard from './SelectedDriverCard';
import { LoadsSlideoutCardTimeline } from './LoadsSlideoutCardTimeline';
import { DetailCardIdentifier, DriverEvent, DriverPlanItem, LoadOption, LoadsSlideoutProps, SourceSearch, Selection } from '../types';
import { DriverIdentifier, dynamicDescription } from '../helpers/dynamicDescription';
import transformTopOptions from '../helpers/transformTopOptions';
import {
  BROKERAGE,
  DISABLE_BROKERAGE,
  DISABLE_HOME_TIME,
  DISABLE_TMS_NOT_READY,
  DRIVER,
  DRIVER_HOLD,
  EMPTY_TO_HOME,
  LOAD,
  LOADED,
  MATCHES,
  PLANNED,
  PLANNEDONLY,
  RELAXED,
  SELECT_ICON_CHECKBOX,
  SELECT_ICON_RADIO,
  EVENTS,
  HOLD,
  COMPLETED,
  DO_NOT_LINK
} from '../helpers/constants';
import { SWITCH_TO_ASSET } from '../../loadAcceptance/loadAcceptanceConstants';
import { dynamicBrokerageDescription } from '../helpers/dynamiqueBrokerageDescription';

const LONG_EMPTY_DRIVE_HOME = 99.5;
const DRIVERS_SEARCH_COLUMNS = [
  { label: 'Load ID', key: 'load_id' },
  { label: 'Shipper ID', key: 'shipper_id' },
  { label: 'Pick Up Location', key: 'pickup_location' },
  { label: 'Drop Off Location', key: 'dropoff_location' }
];

const NothingToSeeHere = ({ label }: { label: string }) => (
  <Box
    sx={{
      height: 40,
      backgroundColor: 'colors.neutral0',
      color: 'text.secondary',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px'
    }}
  >
    <InfoOutlined sx={{ mr: 2 }} />
    {label}
  </Box>
);

interface TotalDatumProps {
  label: string;
  value: string;
  icon: ReactElement;
  onClick?: () => void;
}

const TotalDatum = ({ label, value, icon, onClick }: TotalDatumProps) => {
  const { theUiBlueItself } = useFlags();
  if (theUiBlueItself) {
    return (
      <Box>
        <Box
          onClick={onClick}
          sx={{
            height: '32px',
            width: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...(!!onClick ? { ':hover': { cursor: 'pointer', color: 'text.secondary' } } : {})
          }}
        >
          {icon}
        </Box>
        <Typography>
          <strong>{`${label}: `}</strong>
          {value}
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      <Typography><strong>{label}</strong></Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

interface TotalsProps {
  loadedMi: number;
  emptyMi: number;
  profit: number;
  revenue: number;
  showSimRate?: () => void;
}

const TotalsRow = ({ loadedMi, emptyMi, profit, revenue, showSimRate }: TotalsProps) => {
  const { showDispatchingTotals, showDispatchTotalProfit, theUiBlueItself } = useFlags();
  if (!showDispatchingTotals) return <></>;

  return (
    <Box
      className="dispatching-totals"
      sx={{
        height: theUiBlueItself ? '32px' : '40px',
        py: theUiBlueItself ? '12px' : '4px',
        borderTop: '1px solid',
        borderColor: 'inherit',
        backgroundColor: 'inherit',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '> div': {
          height: '100%',
          display: 'flex',
          alignItems: theUiBlueItself ? 'center' : 'unset',
          flexDirection: theUiBlueItself ? 'row' : 'column',
          columnGap: 1,
          px: 2,
          '&:not(:last-of-type)': {
            borderRight: 1,
            borderColor: 'inherit',
          }
        }
      }}
    >
      <TotalDatum
        label="Loaded Miles"
        value={loadedMi.toLocaleString('en-US', { maximumFractionDigits: 0 })}
        icon={<ODLaneIcon />}
      />
      <TotalDatum
        label="Empty Miles"
        value={emptyMi.toLocaleString('en-US', { maximumFractionDigits: 0 })}
        icon={<ODNoLoadIcon />}
      />
      {showDispatchTotalProfit ? (
        <TotalDatum
          label="Profit Dollar Value"
          value={profit.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
          icon={<AttachMoneySharp />}
        />
      ) : (
        <TotalDatum
          label={!!showSimRate ? 'Simulated Revenue' : 'Line Haul Revenue'}
          value={revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
          icon={<AttachMoneySharp />}
          onClick={showSimRate}
        />
      )}
    </Box>
  );
};

const LoadsSlideout = ({ onClose, index, currentDriver, handleStepThrough, count, setRefreshDashboard, setHOSStatusData, setExplainPtaDialogOpen }: LoadsSlideoutProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    orgUse2WayDispatching,
    dispatchSearchByLoad,
    dispatchingUnassign,
    showPlanCards,
    showRelaxedMatches,
    orgUseRelaxedDispatching,
    disableDispatchingAssignment,
    showShipperName,
    showAllDispatchMatches,
    useFreightFinder,
    internalSearchV2,
    theUiBlueItself,
    showSourceSuggestions,
    assignBrokerageLoads
  } = useFlags();
  const [searchParams, setSearchParams] = useSearchParams();
  const { driverId } = useParams();
  const pointers = useRef<HTMLDivElement[] | never[]>([]);
  const [randomization, setRandomization] = useState(nanoid());
  const [totalOptionsCount, setTotalOptionsCount] = useState(0);
  const [topOptions, setTopOptions] = useState<LoadOption[][] | never[]>([]);
  const [selection, setSelection] = useState<Selection | null>(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [driverEvents, setDriverEvents] = useState<DriverEvent[] | []>([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [openFinder, setOpenFinder] = useState(false);
  const [simRateOpen, setSimRateOpen] = useState(false);
  const [sourceSearch, setSourceSearch] = useState<SourceSearch | null>(null);
  const [loading, setLoading] = useState(false);
  const [driverPlans, setDriverPlans] = useState<DriverPlanItem[]>([]);
  const [permitsDialogData, setPermitsDialogData] = useState<LoadOption | null>(null);
  const [selectedPlannedOptions, setSelectedPlannedOptions] = useState<DriverPlanItem[]>([]);
  const { handleClickDriver } = useContext(DriverLoadDetailContext);
  const engineRunId = useSelector((state: RootState) => state.dispatchingReducer?.engineRunId);
  const offeredLoadDetails = useSelector((state: RootState) => state.dispatchingReducer?.offeredLoadDetails);
  const dataUpdated = useSelector((state: RootState) => state.dispatchingReducer?.dataUpdated);
  const viewType = searchParams.get('slideout_view_type');
  const optimalNode = useRef(false as ReactElement | boolean);

  useEffect(() => {
    if (!viewType) return;
    if (!!currentDriver) {
      if (viewType === PLANNED) {
        getDriverPlan();
      } else if (viewType === EVENTS) {
        getDriverEvents();
      } else {
        getTopMatches();
        setSelection(null);
      }
      setTopOptions([]);
      setDriverPlans([]);
      setRandomization(nanoid());
      optimalNode.current = false;
    } else {
      onClose();
    }
  }, [currentDriver?.driver.driver_id, viewType, showAllDispatchMatches, dataUpdated]);

  useEffect(() => {
    setSelectedPlannedOptions([]);
  }, [currentDriver]);

  const getOfferedLoadDetails = (movementId: string | number) => {
    dispatch(fetchOfferedLoadDetails(movementId));
  };

  const getDriverPlan = () => {
    setLoading(true);
    instance
      .get(`/dispatching/pre-dispatch-state/${currentDriver?.driver.driver_id}/`)
      .then((res) => {
        setDriverPlans(res.data.results);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const getTopMatches = () => {
    let url = `dispatching/driver-top-options/${currentDriver?.driver.driver_id}/?offset=0&limit=${showAllDispatchMatches ? '12' : '4'}`;
    if (viewType === RELAXED) url = `${url}&is_relaxed=true`;
    setLoading(true);
    
    instance.get(url)
      .then((res) => {
        const transformedOptions = transformTopOptions(res.data.results, 'assignment_rank', 'driver_second_rank');
        setTotalOptionsCount(res.data.total_options);
        setTopOptions(transformedOptions);
        if (!!transformedOptions.length) {
          const firstIdx = transformedOptions.findIndex((opt) => opt[0].active);
          if (firstIdx > -1) {
            const firstActive = transformedOptions[firstIdx];
            setSelection({ index: firstIdx, length: firstActive.length });
          }
        }
        pointers.current[0]?.scrollIntoView({ behavior: 'auto', block: 'start' });
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const getMoreMatches = () => {
    let url = `dispatching/driver-top-options/${currentDriver?.driver.driver_id}/?offset=${topOptions.length}&limit=4`;
    if (viewType === RELAXED) url = `${url}&is_relaxed=true`;
    setLoading(true);
    
    instance.get(url)
      .then((res) => {
        const transformedOptions = transformTopOptions(res.data.results, 'assignment_rank', 'driver_second_rank');
        setTopOptions((prevOptions) => (prevOptions as LoadOption[][]).concat(transformedOptions));
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const getDriverEvents = () => {
    const url = `dispatching/driver-events/${currentDriver?.driver?.driver_id}`;
    setLoading(true);

    instance.get(url)
      .then((res) => {
        const events = res.data.events.map((e: DriverEvent) => {
          e.selected = false;
          return e;
        });
        setDriverEvents(events);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const getSelectedOption = () => {
    if (!!selection) return topOptions[selection?.index]?.slice(0, selection.length);
    return [];
  };

  const handleConfirmationDecision = async (confirmationDecision: boolean) => {
    if (!confirmationDecision) {
      setOpenConfirmation(false);
    } else {
      const option = getSelectedOption();
      const assignments = option.filter((opt) => opt.movement_type === LOADED && !!opt.active).map((load) => (
        {
          driver_id: currentDriver.driver.driver_id,
          load_id: load?.load_id,
          movement_option_id: load.movement_option_id,
          overall_rating: load.overall_rating,
          assignment_rank: load.assignment_rank,
        }
      ));
      await instance.post('/dispatching/drivers/loads-assignments/', { assignments, engine_run_id: engineRunId })
        .then((response) => {
          if (response && response.status === 200) {
            onClose();
            setRefreshDashboard(true);
          }
        })
        .catch((response) => console.error(response.data.errors))
        .finally(() => setOpenConfirmation(false));
    }
  };

  const handleSelectLoad = (loadArr: LoadOption[], refIdx: number) => {
    setSelection({ index: refIdx, length: loadArr.length });
    pointers.current[refIdx].scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const checkIsDisabled = () => {
    if (disableDispatchingAssignment) return true;
    const option = getSelectedOption();
    if (!orgUse2WayDispatching && option?.some((opt) => opt.movement_type === EMPTY_TO_HOME)) return true;
    if (orgUse2WayDispatching && !option?.some((opt) => opt.movement_type === LOADED)) return true;
    if (orgUse2WayDispatching && option?.some((opt) => opt.movement_type === LOADED && opt?.tms_ready === false)) return true;
    if (orgUse2WayDispatching && option?.some((opt) => !assignBrokerageLoads && opt.internal_source === BROKERAGE)) return true;
    if (option?.every((opt) => !opt.active || opt.movement_type === DRIVER_HOLD)) return true;
    return false;
  };

  const handleUnassignSelect = (opt: DriverPlanItem) => {
    if (moment.tz(opt.start_time, SHORT_DATE_TIME_AT_DIV, DEFAULT_TIMEZONE).isSameOrBefore()) {
      setSelectedPlannedOptions([opt]);
    } else {
      setSelectedPlannedOptions(driverPlans.filter((plan: DriverPlanItem) => plan.order_index >= opt.order_index));
    }
  };

  const handleClickRules = () => setSearchParams((prev) => ([...prev.entries(), ['show_rules', 'true']]));

  const handleExternalSearch = () => {
    const option = getSelectedOption();
    const sourceLoad = option.find((opt) => opt?.has_source);
    if (!sourceLoad) setSourceSearch(null);
    else {
      const searchSubmove = sourceLoad?.submoves?.find((move) => !!move?.source_search);
      setSourceSearch(searchSubmove?.source_search ?? null);
    }
    setOpenFinder(true);
  };

  const isBrokerage = () => (currentDriver?.internal_source === BROKERAGE || getSelectedOption()?.some((l) => l?.internal_source === BROKERAGE));

  const isSourceRequest = () => 
    showSourceSuggestions && (currentDriver?.has_source || getSelectedOption()?.some((l) => l?.has_source));

  const renderInternalSearchTable = () => (
    <LoadsSearch
      currentDriver={currentDriver}
      optimalMatch={sortBy(topOptions[0], (o) => o.driver_second_rank).find((mvt) => !!mvt.load_id)}
      optimalMatchNode={optimalNode}
      driverAvailable={currentDriver && moment.tz(currentDriver.avail_datetime, AMERICAN_DATE_TIME, DEFAULT_TIMEZONE).utc()}
      onClean={() => {
        setOpenSearch(false);
        setOpenFinder(false);
        setSourceSearch(null);
        onClose();
        setRefreshDashboard(true);
      }}
    />
  );

  const eitherPlanView = viewType?.match(/plan/gm) ?? false;
  const dataPresent = eitherPlanView ? driverPlans.length > 0 : topOptions.length > 0;

  const noAvailNode = <NoAvailableMatchesAccordion type={DRIVER} />;
  if (!loading && topOptions.length === 0 && !eitherPlanView) {
    optimalNode.current = noAvailNode;
  }

  return (
    <>
      <RightDrawer open={!!viewType}>
        <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%', backgroundColor: theme.palette.colors.white }}>
          <DispatchingSummaryTitle
            onClose={onClose}
            onClickPrev={(!driverId && index > 0) ? (() => handleStepThrough(-1)) : null}
            onClickNext={(!driverId && index < count - 1) ? (() => handleStepThrough(1)) : null}
          />
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', borderStyle: 'solid', borderWidth: '1px 0px', borderColor: 'level3' }}>
            <SelectedDriverCard
              currentDriver={currentDriver}
              onClickHos={() => setHOSStatusData(currentDriver)}
              onClickRules={handleClickRules}
              openExplainPtaDialog={() => setExplainPtaDialogOpen(true)}
            />
          </Box>
          {viewType !== PLANNEDONLY && (
            <>
              {!!viewType && (
                <Box sx={{ px: 2, borderBottom: '1px solid', borderColor: 'level3' }}>
                  <ODTabs
                    value={viewType}
                    fullWidth={true}
                    condense={showPlanCards && showRelaxedMatches}
                    setValue={(_e, v) => {
                      searchParams.set('slideout_view_type', v);
                      setSearchParams(searchParams);
                    }}
                    tabOptions={
                      [
                        {
                          value: MATCHES,
                          label: `(${currentDriver?.preferred_options_count ?? 0}) Business Match${currentDriver?.preferred_options_count !== 1 ? 'es' : ''}`,
                          icon: <StarSharp />
                        },
                        ...(showRelaxedMatches ? [{
                          value: RELAXED,
                          label: `(${currentDriver?.relaxed_options_count ?? 0}) Exception Match${currentDriver?.relaxed_options_count !== 1 ? 'es' : ''}`,
                          icon: <TuneOffIcon />
                        }] : []),
                        ...(showPlanCards ? [{
                          value: PLANNED,
                          label: `(${currentDriver?.planned_options_count ?? 0}) Assigned`,
                          icon: <CheckCircleSharp sx={{ color: 'success.main' }} />
                        }] : []),
                        {
                          value: EVENTS,
                          label: 'Scheduled Events',
                          icon: <EventNoteSharp />
                        },
                      ]
                    }
                  />
                </Box>
              )}
            </>
          )}
          {(loading && !dataPresent) && (
            <Box sx={{ width: '100%', height: '85%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ODLoaderLabeled />
            </Box>
          )}
          <Box
            sx={{
              overflow: 'auto',
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '16px',
              flex: '1 1 auto',
              height: '100%'
            }}
          >
            {viewType === EVENTS && !eitherPlanView && !loading && (
              <>
                {!!driverEvents.length ? driverEvents.map((event, eventIndex) => (
                  <DetailCardTitle
                    key={`driver-event-${randomization}-${eventIndex}`}
                    id={`driver-event-${eventIndex}`}
                    rating={undefined}
                    isRelaxed={false}
                    active={true}
                    selected={event.selected}
                    source={EVENTS}
                    handleClick={() => setDriverEvents(driverEvents.map((e, i) => {
                      if (i === eventIndex) {
                        e.selected = !e.selected;
                      }
                      return e;
                    }))}
                  >
                    <DetailCardAccordion
                      key={`driver-event-${eventIndex}`}
                      id={`driver-event-accordion-${eventIndex}`}
                      identifier={{ label: event.event_type, description: '', icon: <EventNoteSharp /> }}
                      type={DO_NOT_LINK}
                      selected={event.selected}
                    >
                      <EventRow icon={<EventNoteSharp />} color="colors.accessibleLightBlue">
                        <Box sx={{ display: 'flex', flexDirection: 'column', height: 48, justifyContent: 'center' }}>
                          <strong>{event.event_type}</strong>
                          {event.location ? `${event.location}: ` : 'No Location Provided | '}
                          {getLocalizedTimeRange(event.start_time, event.end_time, event.timezone, AMERICAN_DATE_TIME)}
                        </Box>
                      </EventRow>
                    </DetailCardAccordion>
                  </DetailCardTitle>
                )) : (
                  <NothingToSeeHere label="No Scheduled Events" />
                )}
              </>
            )}
            {eitherPlanView && viewType !== EVENTS && !loading && (
              <>
                {!driverPlans || (driverPlans?.length === 1 && driverPlans[0].move_type === HOLD && getTimeLabel(driverPlans[0].start_time, driverPlans[0].end_time, false) === COMPLETED) ? (
                  <NothingToSeeHere label="No Planned Loads" />
                ) : (
                  <Box sx={{ borderBottom: '1px solid', borderColor: 'level3', pb: 2 }}>
                    <Typography sx={{ whiteSpace: 'normal' }}>
                      The loads below are synced with this driver&rsquo;s assignments in your TMS.&nbsp;
                      Some times for movements in the plan may be estimated based on these loads and scheduled events.
                    </Typography>
                  </Box>
                )}
                {driverPlans?.map((plan: DriverPlanItem, i: number) => {
                  const selected = selectedPlannedOptions.findIndex((opt) => plan.order_index === opt.order_index) > -1;

                  return (
                    <DriverPlanDetailCard
                      plan={plan}
                      index={i}
                      selected={selected}
                      handleUnassignSelect={dispatchingUnassign && handleUnassignSelect}
                      randomization={randomization}
                      setHOSStatusData={setHOSStatusData}
                    />
                  );
                })}
              </>
            )}
            {!eitherPlanView && viewType !== EVENTS && (
              <>
                {(!loading && topOptions.length === 0) && noAvailNode}
                {topOptions.map((topLevelLoad, i) => {
                  const isTour = topLevelLoad.length > 1;
                  const sampleLoad = topLevelLoad[0];
    
                  if (topOptions.length === 1 && !isTour && sampleLoad?.movement_type === DRIVER_HOLD) {
                    optimalNode.current = noAvailNode;
                    return noAvailNode;
                  }

                  const optionSelected = selection?.index === i;
    
                  const isTourWithConflict = () => orgUse2WayDispatching && isTour
                    && topLevelLoad.some((load) => {
                      if (load.internal_source === BROKERAGE) return true;
                      if (load.movement_type === LOADED && !load.tms_ready) {
                        return !(showSourceSuggestions && load.has_source);
                      }
                      return false;
                    });

                  let totals = null;
                  totals = topLevelLoad.reduce((totals, load) => {
                    totals.loadedMi += parseFloat(load.loaded_miles);
                    totals.emptyMi += parseFloat(load.deadhead_miles);
                    const revenue = parseFloat(load.hard_dollar_revenue);
                    totals.revenue += revenue;
                    const cost = parseFloat(load.hard_dollar_cost); // This value is already negative
                    totals.profit += revenue + cost;
                    return totals;
                  }, { loadedMi: 0, emptyMi: 0, revenue: 0, profit: 0 });

                  const isActive = sampleLoad?.active;

                  const node = (
                    <DetailCardTitle
                      key={`top-level-load-${randomization}-${i}`}
                      id={`assign-drivers-slideout-recommendation-${i}`}
                      ref={(opt: HTMLDivElement) => { pointers.current[i] = opt; }}
                      title="Load Match"
                      rating={sampleLoad?.overall_rating}
                      secondary_rating={sampleLoad?.assignment_rank}
                      active={isActive}
                      selected={optionSelected}
                      handleClick={() => handleSelectLoad(topLevelLoad, i)}
                      isTourWithConflict={isTourWithConflict()}
                      isRelaxed={showRelaxedMatches && orgUseRelaxedDispatching && sampleLoad?.is_relaxed}
                    >
                      {theUiBlueItself && (
                        <TotalsRow
                          {...totals}
                          showSimRate={(showSourceSuggestions && topLevelLoad.some((l) => l.has_source)) ? (() => setSimRateOpen(true)) : undefined}
                        />
                      )}
                      {topLevelLoad.map((load, j) => {
                        let assignable = false;
                        const isSource = showSourceSuggestions && load.has_source;
                        let identifier: DetailCardIdentifier | Record<string, never> = {};
                        if (load.movement_type === DRIVER_HOLD) {
                          identifier = {
                            icon: <ODHandIcon sx={{ height: '16px !important', width: '16px !important' }} />,
                            label: 'Wait For Loads',
                            description: null
                          };
                        } else if (load.movement_type === EMPTY_TO_HOME) {
                          identifier = {
                            icon: <HomeSharp />,
                            label: isSource ? 'Source to Home' : 'Home Time',
                            description: null
                          };
                        } else {
                          assignable = !isSource;
                          identifier = {
                            icon: <ODLaneIcon />,
                            label: isSource ? 'Source Load' : load.load_id,
                            description: !isSource && (showShipperName ? (
                              <Box sx={{ display: 'flex' }}>
                                {assignBrokerageLoads && load?.internal_source === BROKERAGE && (
                                  <>
                                    <Box>Brokerage</Box>
                                    &nbsp;&#47;&nbsp;
                                  </>
                                )}
                                <ODTooltip title="Shipper ID">
                                  <Box>{load.shipper_id}</Box>
                                </ODTooltip>
                                {!!load.shipper_name && (
                                  <>
                                    &nbsp;&#47;&nbsp;
                                    <ODTooltip title="Shipper Name">
                                      <Box>
                                        {load.shipper_name}
                                      </Box>
                                    </ODTooltip>
                                  </>
                                )}
                              </Box>
                            ) : load.shipper_id)
                          };
                        }
                        if (isSource) identifier.icon = <SearchSharp />;

                        const loadSelected = optionSelected && (j < (selection?.length ?? 0));

                        const getWarning = (loadOption: LoadOption) => {
                          if (!orgUse2WayDispatching) return '';

                          if (!isTour && !isSource && loadOption.movement_type === EMPTY_TO_HOME) {
                            if (parseFloat(loadOption.deadhead_miles) >= LONG_EMPTY_DRIVE_HOME) return DISABLE_HOME_TIME;
                          }

                          if (loadOption.internal_source === BROKERAGE) return assignBrokerageLoads ? SWITCH_TO_ASSET : DISABLE_BROKERAGE;
                          if (!loadOption.tms_ready && assignable) return DISABLE_TMS_NOT_READY;
                          return '';
                        };

                        const selectToggle = () => setSelection({ index: i, length: loadSelected ? j : j + 1 });
                        
                        return (
                          <DetailCardAccordion
                            key={`subload-${j}`}
                            id={`assign-drivers-slideout-sub-recommendation-${j}`}
                            identifier={identifier}
                            sequenceNumber={isTour ? j + 1 : 0}
                            assignedId={load.assigned_driver_id}
                            selected={loadSelected}
                            hasPrerequisite={load.has_prerequisite}
                            type={assignable ? LOAD : DO_NOT_LINK}
                            defaultCollapse={showAllDispatchMatches}
                            onOpenPermit={() => {
                              if (![EMPTY_TO_HOME, DRIVER_HOLD].includes(load?.movement_type)) {
                                setPermitsDialogData(load);
                              }
                            }}
                            onShowOfferedLoad={() => {
                              getOfferedLoadDetails(load.movement_option_id);
                            }}
                            warningReason={getWarning(load)}
                            selectIcon={isActive ? (j === 0 ? SELECT_ICON_RADIO : SELECT_ICON_CHECKBOX) : undefined}
                            onClickSelect={() => {
                              if (!sampleLoad?.active) return;
                              if (j === 0) handleSelectLoad(topLevelLoad, i);
                              else selectToggle();
                            }}
                          >
                            {load.movement_type === DRIVER_HOLD ? (
                              <Typography sx={{ whiteSpace: 'normal' }}>
                                If you wait, there is a high probability that better dispatch options will become available. No action is required.
                              </Typography>
                            ) : (
                              <>
                                <LoadsSlideoutCardTimeline
                                  currentDriver={currentDriver}
                                  loadOption={load}
                                  selected={loadSelected && load.active}
                                  showAvailable={j === 0}
                                  openHosDialog={() => setHOSStatusData(currentDriver)}
                                  openExplainPtaDialog={() => setExplainPtaDialogOpen(true)}
                                />
                                {j > 0 && load.movement_type !== EMPTY_TO_HOME && load.active && !isTourWithConflict() && (
                                  <ODButton
                                    id={`load-tour-${loadSelected ? 'deselect' : 'add'}-button`}
                                    variant="slideout"
                                    sx={{ height: '40px', marginTop: '16px', justifyContent: 'center' }}
                                    onClick={selectToggle}
                                  >
                                    {`${loadSelected ? 'Deselect' : 'Add'} Load No. ${j + 1}`}
                                  </ODButton>
                                )}
                              </>
                            )}
                          </DetailCardAccordion>
                        );
                      })}
                      {!theUiBlueItself && <TotalsRow {...totals} />}
                    </DetailCardTitle>
                  );

                  if (!optimalNode.current) optimalNode.current = node;
                  return node;
                })}
                {dataPresent && (
                  loading ? <ODLoaderLabeled /> : (
                    (!showAllDispatchMatches && topOptions.length !== totalOptionsCount) && (
                      <ODButton
                        id="top-loads-slideout-see-more"
                        variant="slideout"
                        endIcon={<AddSharp />}
                        sx={{ justifyContent: 'center', minHeight: '40px' }}
                        onClick={getMoreMatches}
                      >
                        See More Matches
                      </ODButton>
                    ))
                )}
              </>
            )}
          </Box>
          {(!loading || dataPresent) && (!eitherPlanView || dispatchingUnassign) && viewType !== EVENTS && (
            <Box sx={{ height: 40, px: 2, py: 1, borderTop: 1, borderColor: 'level3', display: 'flex', flex: '0 1 auto', columnGap: '16px' }}>
              {!eitherPlanView && (
                <>
                  {dispatchSearchByLoad && (
                    <>
                      <ODButton
                        id="search-for-load"
                        startIcon={<SearchSharp />}
                        sx={{ justifyContent: 'center', flex: '1 1' }}
                        onClick={() => {
                          if (useFreightFinder && showSourceSuggestions) handleExternalSearch();
                          else if (internalSearchV2) setOpenFinder(true);
                          else setOpenSearch(true);
                        }}
                      >
                        <Box sx={{ display: 'flex', alignContent: 'center', gap: 2 }}>
                          {(useFreightFinder && !internalSearchV2) ? 'Internal Loads' : 'Search for Load'}
                          {(useFreightFinder && internalSearchV2) && <BetaChip />}
                        </Box>
                      </ODButton>
                      {(useFreightFinder && !internalSearchV2) && (
                        <ODButton
                          id="freight-finder-search"
                          startIcon={<SearchSharp />}
                          sx={{ justifyContent: 'center', flex: '1 1' }}
                          onClick={handleExternalSearch}
                        >
                          <Box sx={{ display: 'flex', alignContent: 'center', gap: 2 }}>
                            External Sources
                            <BetaChip />
                          </Box>
                        </ODButton>
                      )}
                    </>
                  )}
                  <ODButton
                    data-action="assign-load"
                    data-testid="dispatching-assign-load-button"
                    sx={{ justifyContent: 'center', flex: '1 1' }}
                    variant={dispatchSearchByLoad ? 'blue' : 'slideoutBlue'}
                    disabled={checkIsDisabled()}
                    onClick={() => setOpenConfirmation(true)}
                    startIcon={<CheckSharp />}
                  >
                    Assign Load
                  </ODButton>
                </>
              )}
              {(dispatchingUnassign && eitherPlanView) && (
                <ODButton
                  data-action="unassign-load"
                  data-testid="dispatching-unassign-load-button"
                  sx={{ justifyContent: 'center', width: '100%' }}
                  variant={dispatchSearchByLoad ? 'blue' : 'slideoutBlue'}
                  disabled={!selectedPlannedOptions.length || selectedPlannedOptions.some((opt) => moment.tz(opt.start_time, SHORT_DATE_TIME_AT_DIV, DEFAULT_TIMEZONE).isSameOrBefore())}
                  // onClick={() => setOpenConfirmation(true)} // will be part of another ticket
                  startIcon={<CloseSharp />}
                >
                  Unassign
                </ODButton>
              )}
            </Box>
          )}
        </Box>
        <ConfirmContainer
          open={openConfirmation}
          onAction={(bool: boolean) => handleConfirmationDecision(bool)}
          title={
            assignBrokerageLoads && isBrokerage() && isSourceRequest()
              ? 'Assign & Send Source Request'
              : 'Confirm Assignment'
          }
          confirmTxt="Confirm"
          source="slideout"
        >
          {(assignBrokerageLoads && isBrokerage()) && (isSourceRequest() ? (
            <Typography sx={{ pb: 1 }}>
              Sending this request will move the driver to &lsquo;needs sourcing&rsquo; queue. Loads currently assigned in your TMS as Brokerage will automatically be changed to asset.
            </Typography>
          ) : (
            <Typography sx={{ pb: 1 }}>
              This load is currently assigned in your TMS as a brokerage load. By assigning this load, it will automatically be changed from brokerage to asset.
            </Typography>
          ))}
          {(!!selection && assignBrokerageLoads && isBrokerage())
            ? dynamicBrokerageDescription(getSelectedOption(), currentDriver, LOAD)
            : dynamicDescription(getSelectedOption(), currentDriver, LOAD)}
        </ConfirmContainer>
      </RightDrawer>
      <PermitsAndRequirementsDialog
        open={!!permitsDialogData}
        permitRequired={permitsDialogData?.req_permits}  
        permitDriver={currentDriver?.driver?.permits} 
        equipment={permitsDialogData?.equipment} 
        onClose={() => setPermitsDialogData(null)}
        description={() => dynamicDescription(currentDriver?.driver, permitsDialogData)}
      />
      <OfferedLoadDialog 
        open={!!Object.keys(offeredLoadDetails)?.length}
        load={offeredLoadDetails}
        onClose={() => {
          dispatch(setOfferedLoadDetails({}));
        }}
      />
      {simRateOpen && (
        <ODDialog
          open={true}
          title="Simulated Rate Per Mile"
          sx={{ '&.MuiDialog-root': { zIndex: 1500 } }}
          onClose={() => setSimRateOpen(false)}
        >
          <Typography>
            Optimal Dynamics learns from real-time load data to approximate rates when simulating the value of sourcing opportunities. The approximation is slightly below the median rate for the lane. It is recommended that you attempt to bid above this rate to maximize profit, but sourcing a load at this rate would also benefit your network.
          </Typography>
        </ODDialog>
      )}
      {openSearch && (
        <SearchForModal
          titleIcon={<ODLaneSearchIcon />}
          subject={LOAD}
          searchPrompt="Load ID, Shipper ID, Pick Up Location, or Drop Off Location"
          columnSearchOptions={DRIVERS_SEARCH_COLUMNS}
          onClose={() => setOpenSearch(false)}
          currentTable={(
            <ODTable>
              <ODTableHead newStyling={theUiBlueItself}>
                <ODTableRow lastHeader={theUiBlueItself}>
                  <ODTableCell>
                    Driver
                  </ODTableCell>
                  <ODTableCell>
                    Tractor ID
                  </ODTableCell>
                  <ODTableCell>
                    Driver Type
                  </ODTableCell>
                  <ODTableCell>
                    Avail. For Dispatch
                  </ODTableCell>
                  <ODTableCell>
                    Dispatch Location
                  </ODTableCell>
                </ODTableRow>
              </ODTableHead>
              <ODTableBody>
                <ODTableRow>
                  <ODTableCell>
                    <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>
                      {currentDriver?.driver.is_sleeper ? <PeopleSharp /> : <PersonSharp />}
                      <Link
                        variant="default"
                        onClick={() => handleClickDriver(currentDriver?.driver)}
                        component="button"
                      >
                        <DriverIdentifier driver={currentDriver?.driver} />
                      </Link>
                    </Box>
                  </ODTableCell>
                  <ODTableCell>
                    {currentDriver?.driver.tractor_id}
                  </ODTableCell>
                  <ODTableCell>
                    {currentDriver?.driver.driver_type}
                  </ODTableCell>
                  <ODTableCell>
                    {currentDriver && getLocalizedTime(currentDriver.avail_datetime, currentDriver.avail_timezone, AMERICAN_DATE_TIME)}
                  </ODTableCell>
                  <ODTableCell>
                    {currentDriver?.predispatch_final_loc}
                  </ODTableCell>
                </ODTableRow>
              </ODTableBody>
            </ODTable>
          )}
          searchTable={renderInternalSearchTable()}
        />
      )}
      {openFinder && (
        <SearchDialog
          subject={LOAD}
          onClose={() => setOpenFinder(false)}
          currentRow={currentDriver}
          currentCard={(
            <SelectedDriverCard
              currentDriver={currentDriver}
              onClickHos={() => setHOSStatusData(currentDriver)}
              onClickRules={handleClickRules}
              openExplainPtaDialog={() => setExplainPtaDialogOpen(true)}
            />
          )}
          internalSearchTable={renderInternalSearchTable()}
          defaultSearch={sourceSearch}
          openSimRate={() => setSimRateOpen(true)}
        />
      )}
    </>
  );
};

export default LoadsSlideout;
