import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { DRIVER_HOLD } from './constants';

export default function transformTopOptions(options, primarySortKey, secondarySortKey) {
  const sortedAssignmentOptions = orderBy(options, [primarySortKey, secondarySortKey]);
  const uniquePrimaryRanks = uniqBy(options, primarySortKey).map((opt) => opt[primarySortKey]);
  const tempOptions = [];

  for (const rank of uniquePrimaryRanks) {
    let rankGroup = sortedAssignmentOptions.filter((option) => option[primarySortKey] === rank);
    if (rankGroup.length > 1) {
      // removes wait for load from tours
      rankGroup = rankGroup.filter((option) => option.movement_type !== DRIVER_HOLD);
    }
    if (rankGroup.length > 0) tempOptions.push(rankGroup);
  }
  
  return tempOptions;
}
