import React, { useState } from 'react';
import { Box, Link } from '@mui/material';
import ContentCopySharp from '@mui/icons-material/ContentCopySharp';
import TaskAltSharp from '@mui/icons-material/TaskAltSharp';
import { ODTooltip } from '@OptimalDynamics/core-ai-common-ui';

export const CopyableLink = (props) => {
  const { copyText, children, onClick } = props;
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onCopy = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(copyText || children);
    setShowConfirmation(true);
    setTimeout(() => setShowConfirmation(false), 3000);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: '8px',
        alignItems: 'center',
        svg: { opacity: 0 },
        ':hover': {
          transition: '0.5s',
          svg: { opacity: 1 }
        }
      }}
    >
      {!!onClick ? <Link {...props} /> : children}
      <ODTooltip title={showConfirmation ? 'Copied' : 'Copy ID'}>
        {showConfirmation
          ? <TaskAltSharp sx={{ color: 'success.main' }} />
          : <ContentCopySharp className="copy-id-icon" sx={{ ':hover': { color: 'colors.neutral8', cursor: 'pointer' } }} onClick={(event) => onCopy(event)} />}
      </ODTooltip>
    </Box>
  );
};
