/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { createContext, useState, useMemo } from 'react';
/**
 * @constant ['active-recommended', 'primary', 'other']
 */
export const LANE_DETAIL_TABS = Object.freeze(['active-recommended', 'primary', 'other']);

export const LaneDetailTabCtx = createContext({
  tabValue: LANE_DETAIL_TABS[0],
  setTabValue: {}
});

export const LaneDetailTabCtxWrapper = (props) => {
  const [tabValue, _setTabValue] = useState(LANE_DETAIL_TABS[0]);

  const setTabValue = ({
    [LANE_DETAIL_TABS[0]]: () => _setTabValue(LANE_DETAIL_TABS[0]),
    [LANE_DETAIL_TABS[1]]: () => _setTabValue(LANE_DETAIL_TABS[1]),
    [LANE_DETAIL_TABS[2]]: () => _setTabValue(LANE_DETAIL_TABS[2]),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoLaneDetailTabCtxValue = useMemo(() => ({ tabValue, setTabValue }), [tabValue]);

  return (
    <LaneDetailTabCtx.Provider
      value={memoLaneDetailTabCtxValue}
    >
      { props.children }
    </LaneDetailTabCtx.Provider>
  );
};

// export const withLaneDetailTabCtxAsPropsHOC = (WrappedComponent) => ({ children, ...rest }) => (
//   <LaneDetailTabCtx.Consumer>
//     {({ tabValue, setTabValue }) => (
//       <WrappedComponent
//         {...rest}
//         tabValue={tabValue}
//         setTabValue={setTabValue}
//       >
//         { children }
//       </WrappedComponent>
//     )}
//   </LaneDetailTabCtx.Consumer>
// );
