import React from 'react';
import { Avatar, Box } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import PeopleSharp from '@mui/icons-material/PeopleSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import ShuffleSharp from '@mui/icons-material/ShuffleSharp';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import ConnectWithoutContactSharp from '@mui/icons-material/ConnectWithoutContactSharp';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LOADED, DRIVER, LOAD, BROKERAGE } from './constants';

export const IconWrapper = ({ bgColor, children }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '32px',
      width: '32px',
      borderRadius: '16px',
      boxSizing: 'border-box',
      backgroundColor: bgColor || 'inherit',
      '> p': {
        height: '18px',
        width: '18px',
        borderRadius: '9px',
        textAlign: 'center',
        lineHeight: '17px',
      },
    }}
  >
    {children}
  </Box>
);

export const DriverIdentifier = ({ driver }) => {
  const { addDriverNameUi } = useFlags();

  if (!driver) return null;

  const currentDriver = driver.driver || driver;
  const { driver_last_name: lastName, driver_first_name: firstName, driver_id: driverId } = currentDriver;

  if (!addDriverNameUi || (!lastName && !firstName)) return driverId;

  return `${lastName}, ${firstName}`;
};

export const dynamicBrokerageDescription = (selectedObj, targetObj, selectedObjType) => {
  if (!selectedObj || !targetObj) return null;

  if (!selectedObjType) {
    return dynamicBrokerageDescription([targetObj], { driver: selectedObj }, LOAD);
  }

  const filteredSelectedObj = selectedObj.length > 1
    ? selectedObj.filter((opt) => opt.movement_type === LOADED && opt.active)
    : selectedObj;

  return (
    <Box
      sx={{
        minHeight: '20px',
        backgroundColor: 'colors.lightBlue1',
        display: 'flex',
        alignItems: 'flex-start',
        borderRadius: '4px',
        padding: '10px 16px',
        fontSize: '14px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px', mr: 1, mt: 0.5 }}>
        {targetObj?.driver?.is_sleeper ? <PeopleSharp /> : <PersonSharp />}
        <DriverIdentifier driver={selectedObjType === LOAD ? targetObj : filteredSelectedObj?.[0]} />
        <ArrowForward sx={{ mx: 1, mt: 0.5 }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          ...(filteredSelectedObj.length > 1
            ? { flexDirection: 'column', rowGap: '10px' }
            : { flexDirection: 'row', columnGap: '8px' }),
        }}
      >
        {filteredSelectedObj.length > 1 ? (
          filteredSelectedObj.map((obj, index) => (
            <Box key={`confirm-${index}`} sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  fontSize: 12,
                  fontWeight: 600,
                  backgroundColor: 'colors.lightBlue1',
                  color: 'text.primary',
                  border: '1px solid',
                  borderColor: 'level3',
                  borderRadius: '50%',
                }}
              >
                {index + 1}
              </Avatar>
              {obj?.internal_source === BROKERAGE ? (
                <IconWrapper bgColor="warning.main">
                  <ShuffleSharp />
                </IconWrapper>
              ) : obj?.has_source ? (
                <IconWrapper bgColor="colors.neutral6">
                  <ConnectWithoutContactSharp sx={{ color: 'text.inverse' }} />
                </IconWrapper>
              ) : (
                <IconWrapper bgColor="optimalblue.main">
                  <CheckCircleSharp sx={{ color: 'text.inverse' }} />
                </IconWrapper>
              )}
              {selectedObjType === LOAD ? obj?.load_id : <DriverIdentifier driver={obj} />}
            </Box>
          ))
        ) : (
          <>
            <IconWrapper bgColor="warning.main">
              <ShuffleSharp />
            </IconWrapper>
            {selectedObjType === DRIVER ? targetObj?.load_id : filteredSelectedObj?.[0]?.load_id}
          </>
        )}
      </Box>
    </Box>
  );
};
