import React from 'react';
import CalendarTodaySharp from '@mui/icons-material/CalendarTodaySharp';
import DoNotDisturbOnSharp from '@mui/icons-material/DoNotDisturbOnSharp';
import FilterAltSharp from '@mui/icons-material/FilterAltSharp';
import ReportProblemOutlined from '@mui/icons-material/ReportProblemOutlined';

export const REVIEW = 'review';
export const DETAILS = 'details';
export const ALL = 'All';
export const CUSTOM_FILTER = 'Custom Filter';
export const DATA_ISSUE = 'Data Issue';
export const NOT_RELEVANT = 'Not Relevant';
export const PLANNING_HORIZON = 'Planning Horizon';

export const exclusionCategoryOptions = [
  { label: 'Viewing All', value: ALL },
  {
    label: (
      <>
        <ReportProblemOutlined sx={{ mr: 2 }} />
        {DATA_ISSUE}
      </>
    ),
    value: DATA_ISSUE
  },
  { 
    label: (
      <>
        <FilterAltSharp sx={{ mr: 2 }} />
        {CUSTOM_FILTER}
      </>
    ),
    value: CUSTOM_FILTER
  },
  { 
    label: (
      <>
        <DoNotDisturbOnSharp sx={{ mr: 2 }} />
        {NOT_RELEVANT}
      </>
    ),
    value: NOT_RELEVANT
  },
  {
    label: (
      <>
        <CalendarTodaySharp sx={{ mr: 2 }} />
        {PLANNING_HORIZON}
      </>
    ),
    value: PLANNING_HORIZON
  }
];
