/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { debounce, pick, startCase, pickBy } from 'lodash';
import { Box, Link, Skeleton, TableSortLabel } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import CheckSharp from '@mui/icons-material/CheckSharp';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import NotInterestedSharp from '@mui/icons-material/NotInterestedSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import PeopleSharp from '@mui/icons-material/PeopleSharp';
import StarsSharp from '@mui/icons-material/StarsSharp';
import ShuffleSharp from '@mui/icons-material/ShuffleSharp';
import {
  ODAlertBar,
  ODButton,
  ODLaneIcon,
  ODRadio,
  ODTable,
  ODTableBody,
  ODTableCell,
  ODTableHead,
  ODTableRow,
  ODTooltip
} from '@OptimalDynamics/core-ai-common-ui';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SHORT_DATE_TIME, DATE_TIME_WITH_SEC_T_DIV, getLocalizedTimeRange, DEFAULT_TIMEZONE } from '../../../utils/datetimes';
import { SearchForContext } from '../../../utils/context/searchForContext';
import instance from '../../../utils/axios_instance';
import { formatWithUnit } from '../../../utils/math';
import { DriverIdentifier, dynamicDescription } from '../helpers/dynamicDescription';
import { SearchMatchHighlight } from '../shared/SearchForModal';
import { ConfirmSearchAssignment } from '../shared/ConfirmSearchAssignment';
import PermitsAndRequirementsDialog from '../shared/PermitsAndRequirementsDialog';
import OfferedLoadDialog from '../shared/OfferedLoadsDialog';
import BrokerageLoadDialog from '../shared/BrokerageLoadDialog';
import { confirmAssignment } from '../helpers/dispatchingScripts';
import LoadDispatchMatchCell from '../loads/LoadDispatchMatchCell';
import { DRIVER, LOAD, LOAD_ID } from '../helpers/constants';
import { getHeaderFilter, DATE_RANGE, SELECT, NUMBER_RANGE } from '../../../common/headerFilters';
import { SessionContext } from '../../../utils/context/sessionCtx';
import { dynamicBrokerageDescription, IconWrapper } from '../helpers/dynamiqueBrokerageDescription';

const EXCLUDED = 'Excluded';
const BROKERAGE = 'Brokerage';
const ASSET = 'Asset';
const PLANNED = 'Planned';

const LoadsSearch = ({
  currentDriver,
  optimalMatch,
  optimalMatchNode,
  driverAvailable,
  onClean
}) => {
  const allFlags = useFlags();
  const { disableDispatchingAssignment, internalSearchV2, showRatePerMile, addHasDispatchMatchColumn, assignBrokerageLoads, theUiBlueItself, showSourceSuggestions } = allFlags;
  const [_, setSearchParams] = useSearchParams();
  const { sessionUid } = React.useContext(SessionContext);
  const [selectedLoad, setSelectedLoad] = useState(null);
  const [offeredLoadDetails, setOfferedLoadDetails] = useState({});
  const [permitsAndRequirementsLoad, setPermitsAndRequirementsLoad] = useState(null);
  const [rows, setRows] = useState(null);
  const [ordering, setOrdering] = useState(internalSearchV2 ? 'empty_miles' : 'pickup_start_et');
  const [sort, setSort] = useState('asc');
  const [loadIdFilter, setLoadIdFilter] = useState('');
  const [shipperIdFilter, setShipperIdFilter] = useState('');
  const [tmsAssignmentFilter, setTmsAssignmentFilter] = useState('');
  const [pickupLocationFilter, setPickupLocationFilter] = useState('');
  const [origLiveFlagFilter, setOrigLiveFlagFilter] = useState('');
  const [pickupStartFilter, setPickupStartFilter] = useState(null);
  const [pickupEndFilter, setPickupEndFilter] = useState(internalSearchV2 ? null : driverAvailable);
  const [dropoffLocationFilter, setDropoffLocationFilter] = useState('');
  const [destDropFlagFilter, setDestDropFlagFilter] = useState('');
  const [dropoffStartFilter, setDropoffStartFilter] = useState(null);
  const [dropoffEndFilter, setDropoffEndFilter] = useState(null);
  const [ptaToPickupMinFilter, setPtaToPickupMinFilter] = useState('');
  const [ptaToPickupMaxFilter, setPtaToPickupMaxFilter] = useState('');
  const [dispatchMatchFilter, setDispatchMatchFilter] = useState('Not Assigned');
  const [pickupStartCalendarOpen, setPickupStartCalendarOpen] = useState(false);
  const [pickupEndCalendarOpen, setPickupEndCalendarOpen] = useState(false);
  const [dropoffStartCalendarOpen, setDropoffStartCalendarOpen] = useState(false);
  const [dropoffEndCalendarOpen, setDropoffEndCalendarOpen] = useState(false);
  const [confirmAssignmentOpen, setConfirmAssignmentOpen] = useState(false);
  const [brokerageLoadId, setBrokerageLoadId] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const tmsFilterOptions = [ASSET.toLowerCase(), BROKERAGE.toLowerCase(), EXCLUDED.toLowerCase(), PLANNED.toLowerCase()];
  const loadTypeOptions = ['Live Load', 'PreLoad'];
  const optimalMatchOptions = ['No Match', 'Has Match', 'Assigned', 'Not Assigned'];
  const [optimalMatchRow, setOptimalMatchRow] = useState(null);
  const [debouncedSearchHandler] = useState(() => (
    debounce((searchKeyword, filters, params) => fetchLoads(searchKeyword, filters, params), 300)
  ));
  const { resetValues, page, setPage, rowsPerPage, setCount, search, searchColumn, setHasResults, setLoading } = useContext(SearchForContext);

  const DRIVER_HEADERS = [
    { label: 'Load ID', sortKey: 'load_id', type: SELECT, onChange: setLoadIdFilter, currentValue: loadIdFilter, filterOptionsKey: 'load_id', width: 150 },
    { label: 'Shipper ID', sortKey: 'shipper_id', type: SELECT, onChange: setShipperIdFilter, currentValue: shipperIdFilter, filterOptionsKey: 'shipper_id', width: 120 },
    { label: 'TMS Assignment', sortKey: 'tms_assignment', type: SELECT, onChange: setTmsAssignmentFilter, currentValue: tmsAssignmentFilter, filterOptionsKey: 'tms_filter_options', width: 100, flag: !internalSearchV2 },
    { label: 'Pickup Location', sortKey: 'pickup_location', type: SELECT, onChange: setPickupLocationFilter, currentValue: pickupLocationFilter, filterOptionsKey: 'pickup_location', width: 230 },
    { label: 'Origin Type', sortKey: 'orig_live_flag', type: SELECT, onChange: setOrigLiveFlagFilter, currentValue: origLiveFlagFilter, filterOptionsKey: 'load_type_options', width: 100 },
    { label: 'Pickup Window', sortKey: 'pickup_start_et', type: DATE_RANGE, startFilterValue: pickupStartFilter, setStartFilterValue: setPickupStartFilter, endFilterValue: pickupEndFilter, setEndFilterValue: setPickupEndFilter, startCalendarOpen: pickupStartCalendarOpen, endCalendarOpen: pickupEndCalendarOpen, setStartCalendarOpen: setPickupStartCalendarOpen, setEndCalendarOpen: setPickupEndCalendarOpen, startHelperText: 'Appt Starts After', endHelperText: 'Appt Ends After', width: 230 },
    { label: 'Dropoff Location', sortKey: 'dropoff_location', type: SELECT, onChange: setDropoffLocationFilter, currentValue: dropoffLocationFilter, filterOptionsKey: 'dropoff_location', width: 230 },
    { label: 'Destination Type', sortKey: 'dest_drop_flag', type: SELECT, onChange: setDestDropFlagFilter, currentValue: destDropFlagFilter, filterOptionsKey: 'load_type_options', width: 120 },
    { label: 'Dropoff Window', sortKey: 'dropoff_start_et', type: DATE_RANGE, startFilterValue: dropoffStartFilter, setStartFilterValue: setDropoffStartFilter, endFilterValue: dropoffEndFilter, setEndFilterValue: setDropoffEndFilter, startCalendarOpen: dropoffStartCalendarOpen, endCalendarOpen: dropoffEndCalendarOpen, setStartCalendarOpen: setDropoffStartCalendarOpen, setEndCalendarOpen: setDropoffEndCalendarOpen, startHelperText: 'Appt Starts After', endHelperText: 'Appt Ends After', width: 230 },
    { label: 'Dispatch Match', sortKey: 'optimal_match', width: 170, onChange: setDispatchMatchFilter, currentValue: dispatchMatchFilter, type: SELECT, filterOptionsKey: 'optimal_match_options', flag: addHasDispatchMatchColumn },
    { label: 'Permits & Requirements', sortKey: 'permits', type: SELECT, filterOptionsKey: 'permits_options', onChange: () => null, disabled: true, width: 160 },
    { label: 'TMS Assignment', sortKey: 'tms_assignment', type: SELECT, onChange: setTmsAssignmentFilter, currentValue: tmsAssignmentFilter, filterOptionsKey: 'tms_filter_options', width: 115, flag: internalSearchV2 },
    { label: 'Rate Per Mile', sortKey: 'blank', width: 100, flag: showRatePerMile },
    { label: 'Driver Deadhead', sortKey: 'empty_miles', width: 156, type: NUMBER_RANGE, setMinFilter: setPtaToPickupMinFilter, setMaxFilter: setPtaToPickupMaxFilter, allowMaxEmpty: true, allowMinEmpty: true, minFilterValue: ptaToPickupMinFilter, maxFilterValue: ptaToPickupMaxFilter, flag: internalSearchV2 },
    { label: 'Assign Load', sortKey: 'origin_to_pickup_miles', width: 100, type: SELECT, disabled: true, flag: internalSearchV2 },
  ];

  const headers = DRIVER_HEADERS.filter((dHeader) => dHeader.flag ?? true);

  const isBrokerage = assignBrokerageLoads && (selectedLoad?.internal_source === 'BROKERAGE' || selectedLoad?.tms_assignment === BROKERAGE);

  const convertOptimalMatch = () => ({
    ...pick(optimalMatch, ['load_id', 'shipper_id', 'assigned_driver_id', 'dropoff_timezone', 'pickup_timezone', 'equipment', 'req_permits', 'tms_ready', 'dest_drop_flag', 'orig_live_flag', 'has_source']),
    tms_assignment: startCase(optimalMatch?.internal_source?.toLowerCase()),
    pickup_location: `${optimalMatch.pickup_city}, ${optimalMatch.pickup_state} | ${optimalMatch.pickup_location}`,
    pickup_start_et: optimalMatch.pickup_start_time ? moment.tz(optimalMatch.pickup_start_time, DEFAULT_TIMEZONE).utc().format(SHORT_DATE_TIME) : null,
    pickup_end_et: optimalMatch.pickup_end_time ? moment.tz(optimalMatch.pickup_end_time, DEFAULT_TIMEZONE).utc().format(SHORT_DATE_TIME) : null,
    dropoff_start_et: optimalMatch.dropoff_start_time ? moment.tz(optimalMatch.dropoff_start_time, DEFAULT_TIMEZONE).utc().format(SHORT_DATE_TIME) : null,
    dropoff_end_et: optimalMatch.dropoff_end_time ? moment.tz(optimalMatch.dropoff_end_time, DEFAULT_TIMEZONE).utc().format(SHORT_DATE_TIME) : null,
    dropoff_location: `${optimalMatch.destination_city}, ${optimalMatch.destination_state} | ${optimalMatch.dropoff_location}`,
    empty_miles: parseInt(optimalMatch.deadhead_miles, 10),
    rate_per_mile: parseInt(optimalMatch.rate_per_miles, 10),
  });

  const fetchFilterOptions = async (searchKeyword, filterParams) => {
    await instance
      .get(`dispatching/loads/search-filter-options/${searchKeyword}${filterParams ? `?${filterParams}` : ''}`)
      .then((res) => {
        res.data.tms_filter_options = tmsFilterOptions;
        res.data.load_type_options = loadTypeOptions;
        res.data.optimal_match_options = optimalMatchOptions;
        setFilterValues(res.data);
      })
      .catch((err) => console.error(err));
  };

  const fetchLoads = async (searchKeyword, filters, params) => {
    setRows(null);
    const filterParams = new URLSearchParams(filters).toString();
    const paginationParams = new URLSearchParams(params).toString();
    setLoading(true);
    fetchFilterOptions(searchKeyword, filterParams);
    await instance
      .get(`dispatching/loads/search/${searchKeyword}?${paginationParams}${filterParams ? `&${filterParams}` : ''}`)
      .then((res) => {
        let results = [...res.data.results];
        const existingOptimalMatch = results.find((result) => result.load_id === optimalMatch?.load_id);
        if (existingOptimalMatch) {
          setOptimalMatchRow(existingOptimalMatch);
          results = results.filter((result) => result.load_id !== optimalMatch?.load_id);
        } else if (optimalMatch) {
          setOptimalMatchRow(convertOptimalMatch());
        }
        if (internalSearchV2) setHasResults(results.length > 0);
        setRows(results);
        setCount(res.data.count);
      })
      .catch((err) => console.error(err))
      .finally(() => { setLoading(false); });
  };

  const populateDriverObject = (row) => {
    row.driver = {
      driver_id: row?.optimal_match,
      driver_first_name: row?.driver_first_name,
      driver_last_name: row?.driver_last_name,
      is_sleeper: row?.is_sleeper,
      home_city: row?.pickup_location?.split('|')[0].split(',')[0],
      home_state: row?.pickup_location?.split('|')[0].split(',')[1],
      permits: row?.req_permits
    };
    return row;
  };

  useEffect(() => {
    const params = {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      ordering: sort === 'asc' ? ordering : `-${ordering}`,
      search_session_uid: sessionUid,
      ...(!!searchColumn && { type: searchColumn.key })
    };

    const { keyword, driverId, driverPk: _pk, params: searchPanel } = search;
    let filters = { driver_id: driverId };
    let dates = {};
    if (internalSearchV2) {
      if (!!keyword) params.type = LOAD_ID;
      else {
        filters = {
          ...filters,
          origin: searchPanel?.pickUpCity,
          origin_radius: searchPanel?.pickUpRadius,
          destination: searchPanel?.dropOffCity,
          destination_radius: searchPanel?.dropOffRadius,
          pta_to_pickup_miles_min: ptaToPickupMinFilter,
          pta_to_pickup_miles_max: ptaToPickupMaxFilter,
        };
        dates = {
          pickup_start: searchPanel?.pickUpStart,
          pickup_end: searchPanel?.pickUpEnd,
          dropoff_start: searchPanel?.dropOffStart,
          dropoff_end: searchPanel?.dropOffEnd,
        };
      }
    }

    // Override the Search Panel filters as applicable
    filters = {
      ...filters,
      load_id: loadIdFilter,
      shipper_id: shipperIdFilter,
      tms_assignment: tmsAssignmentFilter,
      dispatch_match_filter: dispatchMatchFilter.replace(/ /g, '').toLowerCase(),
      pickup_zip: pickupLocationFilter?.split(', ')[2], // API will ignore origin if this field is present
      dropoff_zip: dropoffLocationFilter?.split(', ')[2], // API will ignore destination if this field is present
      ...(!!origLiveFlagFilter && { orig_live_flag: origLiveFlagFilter === 'Live Load' }),
      ...(!!destDropFlagFilter && { dest_drop_flag: destDropFlagFilter === 'PreLoad' })
    };
    dates = {
      ...dates,
      pickup_start_min: pickupStartFilter,
      pickup_end_min: pickupEndFilter,
      dropoff_start_min: dropoffStartFilter,
      dropoff_end_min: dropoffEndFilter,
    };

    Object.entries(dates).forEach(([key, date]) => {
      filters[key] = date?.tz(DEFAULT_TIMEZONE)?.format(DATE_TIME_WITH_SEC_T_DIV);
    });
    filters = pickBy(filters, (v) => v === false || v === 0 || !!v);

    setSelectedLoad(null);
    debouncedSearchHandler(keyword ?? '', filters, params);
    window.scrollTo({ top: 0 });
  }, [
    page,
    rowsPerPage,
    ordering,
    sort,
    search,
    tmsAssignmentFilter,
    shipperIdFilter,
    pickupLocationFilter,
    dropoffLocationFilter,
    origLiveFlagFilter,
    destDropFlagFilter,
    dropoffEndFilter,
    dropoffStartFilter,
    pickupEndFilter,
    pickupStartFilter,
    loadIdFilter,
    ptaToPickupMaxFilter,
    ptaToPickupMinFilter,
    dispatchMatchFilter,
    searchColumn
  ]);

  useEffect(() => {
    if (!!setPage) setPage(0);
  }, [
    rowsPerPage,
    ordering,
    sort,
    search,
    tmsAssignmentFilter,
    shipperIdFilter,
    pickupLocationFilter,
    dropoffLocationFilter,
    dropoffEndFilter,
    dropoffStartFilter,
    pickupEndFilter,
    pickupStartFilter,
    origLiveFlagFilter,
    destDropFlagFilter,
    ptaToPickupMaxFilter,
    ptaToPickupMinFilter,
    loadIdFilter
  ]);

  const handleSortClick = (value) => {
    if (ordering === value) {
      setSort(sort === 'asc' ? 'desc' : 'asc');
    } else {
      setSort('asc');
      setOrdering(value);
    }
  };

  const getTmsAssignmentCell = (row) => (
    <Box sx={{ display: 'flex', alignItems: 'center', '& .MuiSvgIcon-root': { mr: 1 } }}>
      {(() => {
        switch (row.tms_assignment) {
          case EXCLUDED:
            return (
              <>
                <NotInterestedSharp />
                <Link
                  component="button"
                  variant="default"
                  onClick={() => {
                    setSearchParams({
                      show_exclusions: true,
                      exclusion_type: LOAD,
                      excluded_item: row?.load_id
                    });
                  }}
                >
                  {row.tms_assignment}
                </Link>
              </>
            );
          case BROKERAGE:
            if (assignBrokerageLoads) return row.tms_assignment;
            return (
              <>
                <InfoOutlined />
                <Link
                  component="button"
                  variant="default"
                  onClick={() => {
                    setBrokerageLoadId(row.load_id);
                  }}
                >
                  {row.tms_assignment}
                </Link>
              </>
            );
          case PLANNED:
            return (
              <Link
                component="button"
                variant="default"
                sx={{ display: 'flex', alignItems: 'center' }}
                onClick={() => setSearchParams((prev) => ([...prev.entries(), ['show_history', true]]))}
              >
                <CheckSharp />
                {row.tms_assignment}
              </Link>
            );
          default:
            return row.tms_assignment;
        }
      })()}
    </Box>
  );

  const getAdditionalTableStyles = () => {
    const style = {};
    headers.forEach((header, index) => {
      const right = headers.slice(index + 1).reduce((prev, curr) => prev + curr.width + 16, 0);
      style[`&& .MuiTableCell-root:nth-of-type(${index + 1})`] = {
        maxWidth: `${header.width}px`,
        width: `${header.width}px`,
        minWidth: `${header.width}px`,
        [`&:nth-last-child(-n+${showRatePerMile ? 4 : 3})`]: {
          position: 'sticky',
          right: `${right}px !important`,
          zIndex: 3,
          '&:not(.MuiTableCell-head)': {
            backgroundColor: 'inherit',
            zIndex: 2,
          }
        },
      };
    });
    return style;
  };

  const getAssignmentBarContents = () => (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
      <Box
        sx={{
          width: 'auto',
          display: 'flex',
          alignItems: 'center',
          columnGap: '8px',
        }}
      >
        {currentDriver?.driver?.is_sleeper ? <PeopleSharp /> : <PersonSharp />}
        <DriverIdentifier driver={currentDriver?.driver} />
        <ArrowForward sx={{ mx: 1 }} />
        {assignBrokerageLoads && isBrokerage
          ? <IconWrapper bgColor="warning.main"><ShuffleSharp /></IconWrapper>
          : <ODLaneIcon />}
        {selectedLoad?.load_id}
      </Box>
      <ODButton
        id="assign-load-search"
        variant="blue"
        startIcon={<CheckCircleSharp />}
        onClick={() => setConfirmAssignmentOpen(true)}
        disabled={disableDispatchingAssignment || selectedLoad?.assigned}
      >
        Confirm Load Assignment
      </ODButton>
    </Box>
  );

  return (
    <>
      {!!selectedLoad && internalSearchV2 && (
        <Box
          sx={{
            backgroundColor: 'level1',
            border: '1px solid',
            borderColor: 'level3',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            padding: '8px 16px',
            height: '40px',
            mb: 2
          }}
        >
          {getAssignmentBarContents()}
        </Box>
      )}
      <Box
        sx={{
          overflowY: 'auto',
          position: 'relative',
          height: (internalSearchV2 && rows?.length === 0) ? 'max-content'
            : `calc(100%${!!selectedLoad && internalSearchV2 ? ' - 73px' : ''})`
        }}
      >
        <ODTable
          stickyHeader
          sx={internalSearchV2 && {
            flex: '1 1 auto',
            [`&& .MuiTableCell-root:nth-last-of-type(${showRatePerMile ? 4 : 3})`]: {
              borderLeft: 1, borderLeftColor: 'level2', pl: 2
            },
            '& .MuiTableCell-root > *': {
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            },
            ...getAdditionalTableStyles()
          }}
        >
          <ODTableHead newStyling={theUiBlueItself}>
            <ODTableRow lastHeader={theUiBlueItself}>
              {headers.map((header) => (
                <ODTableCell key={header.value}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', my: 1, rowGap: 1 }}>
                    <TableSortLabel
                      active={ordering === header.sortKey}
                      hideSortIcon={!header.sort}
                      direction={sort}
                      onClick={() => handleSortClick(header.sortKey)}
                      classes={{ icon: 'sort-icon-lite' }}
                      id={`${header.sortKey}-sorting-Arrow`}
                      sx={{ display: 'flex', flexDirection: 'row' }}
                    >
                      {header.label}
                    </TableSortLabel>
                    {getHeaderFilter({ header, filterValues, searchTerm: search?.keyword })}
                  </Box>
                </ODTableCell>
              ))}
            </ODTableRow>
            {optimalMatchRow && (
              <ODTableRow
                id={`dispatch-anything-optimal-match${optimalMatchRow.load_id}`}
                sx={{
                  '&.MuiTableRow-root': {
                    '&:hover': {
                      '& .MuiTableCell-root': { backgroundColor: 'level2' }
                    },
                    '&.Mui-selected': {
                      '& .MuiTableCell-root': { backgroundColor: 'colors.lightBlue1' },
                      '&:hover': {
                        '& .MuiTableCell-root': { backgroundColor: 'colors.lightBlue2' }
                      }
                    }
                  },
                  '&.MuiTableRow-root > .MuiTableCell-root': {
                    backgroundColor: 'level1',
                    top: '91px !important',
                  },
                  '&[disabled]': { 
                    '& .MuiTableCell-root': { backgroundColor: 'colors.neutral0' }
                  }
                }}
                disabled={!assignBrokerageLoads && optimalMatchRow.tms_assignment === BROKERAGE}
                onClick={() => {
                  if (internalSearchV2) return;
                  if (optimalMatchRow.tms_assignment === BROKERAGE) return;
                  setSelectedLoad(optimalMatchRow.load_id === selectedLoad?.load_id ? null : optimalMatchRow);
                }}
                selected={selectedLoad?.load_id === optimalMatchRow.load_id}
              >
                <ODTableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {internalSearchV2 && (
                      <ODRadio
                        sx={{ mr: 1 }}
                        checked={selectedLoad?.load_id === optimalMatchRow.load_id}
                        disabled={optimalMatchRow?.assigned}
                        onClick={() => {
                          if (!assignBrokerageLoads && optimalMatchRow.tms_assignment === BROKERAGE) return;
                          setSelectedLoad(optimalMatchRow.load_id === selectedLoad?.load_id ? null : optimalMatchRow);
                        }}
                      />
                    )}
                    <SearchMatchHighlight
                      content={(
                        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
                          <ODTooltip placement="top" title="Optimal Match">
                            <StarsSharp sx={{ color: 'optimalblue.main' }} />
                          </ODTooltip>
                          {(showSourceSuggestions && optimalMatchRow?.has_source) ? 'Source Load' : (
                            <Link
                              component="button"
                              variant="default"
                              sx={{ fontWeight: 600 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setOfferedLoadDetails({
                                  load_id: optimalMatchRow.load_id,
                                  load_data: {
                                    revenue: optimalMatchRow.revenue,
                                    pickup_appt_type: optimalMatchRow.pickup_appt_type,
                                    dropoff_appt_type: optimalMatchRow.dropoff_appt_type
                                  }
                                });
                              }}
                            >
                              {optimalMatchRow.load_id}
                            </Link>
                          )}
                        </Box>
                      )}
                      matchTerm={optimalMatchRow.load_id}
                      searchTerm={search?.keyword}
                      disabled={!!searchColumn && searchColumn.key !== 'load_id'}
                    />
                  </Box>
                </ODTableCell>
                <ODTableCell sx={{ top: 40 }}>
                  <Box>
                    {!optimalMatchRow?.has_source && (
                      <SearchMatchHighlight
                        content={optimalMatchRow.shipper_id || '-'}
                        matchTerm={optimalMatchRow.shipper_id || '-'}
                        searchTerm={search?.keyword}
                        disabled={!!searchColumn && searchColumn.key !== 'shipper_id'}
                      />
                    )}
                  </Box>
                </ODTableCell>
                {!internalSearchV2 && (
                  <ODTableCell sx={{ '& .MuiTypography-root': { fontWeight: 600 } }}>
                    <Box>
                      {getTmsAssignmentCell(optimalMatchRow) || '-'}
                    </Box>
                  </ODTableCell>
                )}
                <ODTableCell>
                  <Box>
                    <SearchMatchHighlight
                      content={optimalMatchRow.pickup_location || '-'}
                      matchTerm={optimalMatchRow.pickup_location || '-'}
                      searchTerm={search?.keyword}
                      disabled={!!searchColumn && searchColumn.key !== 'pickup_location'}
                    />
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <Box>
                    {!!optimalMatchRow.orig_live_flag ? 'Live Load' : 'PreLoad'}
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <Box>
                    {getLocalizedTimeRange(optimalMatchRow.pickup_start_et, optimalMatchRow.pickup_end_et, optimalMatchRow.pickup_timezone, SHORT_DATE_TIME) || '-'}
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <Box>
                    <SearchMatchHighlight
                      content={optimalMatchRow.dropoff_location || '-'}
                      matchTerm={optimalMatchRow.dropoff_location || '-'}
                      searchTerm={search?.keyword}
                      disabled={!!searchColumn && searchColumn.key !== 'dropoff_location'}
                    />
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <Box>
                    {!!optimalMatchRow.dest_drop_flag ? 'PreLoad' : 'Live Unload'}
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <Box>
                    {getLocalizedTimeRange(optimalMatchRow.dropoff_start_et, optimalMatchRow.dropoff_end_et, optimalMatchRow.dropoff_timezone, SHORT_DATE_TIME) || '-'}
                  </Box>
                </ODTableCell>
                {addHasDispatchMatchColumn && (
                  <ODTableCell data-col-match-label="Dispatch Match">
                    <LoadDispatchMatchCell row={populateDriverObject(optimalMatchRow)} pta={1} />
                  </ODTableCell>
                )}
                <ODTableCell>
                  <Box>
                    <Link
                      component="button"
                      variant="default"
                      sx={{ fontWeight: 600 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setPermitsAndRequirementsLoad(optimalMatchRow);
                      }}
                    >
                      View Details
                    </Link>
                  </Box>
                </ODTableCell>
                {internalSearchV2 && (
                  <>
                    <ODTableCell sx={{ '& .MuiTypography-root': { fontWeight: 600 } }}>
                      <Box>
                        {getTmsAssignmentCell(optimalMatchRow) || '-'}
                      </Box>
                    </ODTableCell>
                    {showRatePerMile && (
                      <ODTableCell>
                        {!!optimalMatchRow.rate_per_mile ? `$${optimalMatchRow.rate_per_mile}` : '-' }
                      </ODTableCell>
                    )}
                    <ODTableCell>
                      {(!!optimalMatchRow.empty_miles || optimalMatchRow.empty_miles === 0) ? formatWithUnit(optimalMatchRow.empty_miles, 'mi') : '-' }
                    </ODTableCell>
                    <ODTableCell>
                      {optimalMatchRow?.assigned ? (
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <CheckCircleSharp sx={{ color: 'success.main' }} />
                          Assigned
                        </Box>
                      ) : (
                        <Link
                          component="button"
                          variant="blue"
                          onClick={() => {
                            if (!assignBrokerageLoads && optimalMatchRow.tms_assignment === BROKERAGE) return;
                            setSelectedLoad(
                              optimalMatchRow.load_id === selectedLoad?.load_id ? null : optimalMatchRow
                            );
                            setConfirmAssignmentOpen(true);
                          }}
                        >
                          Assign Load
                        </Link>
                      )}
                    </ODTableCell>
                  </>
                )}
              </ODTableRow>
            )}
          </ODTableHead>
          <ODTableBody data-testid="dispatching-loads-search-table-body">
            {!rows ? (
              <>
                {[...Array(50)].map((__, i) => (
                  <ODTableRow key={`dispatching-search-skeleton-row-${i}`}>
                    {[...Array(headers.length)].map((___, ind) => (
                      <ODTableCell key={`dispatching-search-skeleton-cell-${ind}`}>
                        <Skeleton height="100%" />
                      </ODTableCell>
                    ))}
                  </ODTableRow>
                ))}
              </>
            ) : (rows.map((row) => (
              <ODTableRow
                key={`dispatch-anything-row-${row.load_id}`}
                id={`dispatch-anything-row-${row.load_id}`}
                disabled={row?.assigned || [EXCLUDED, PLANNED].concat(assignBrokerageLoads ? [] : [BROKERAGE]).includes(row.tms_assignment)}
                sx={{ '&[disabled]': { backgroundColor: 'colors.neutral0' } }}
                onClick={() => {
                  if (internalSearchV2) return;
                  if ([EXCLUDED, BROKERAGE, PLANNED].includes(row.tms_assignment)) return;
                  setSelectedLoad(row.load_id === selectedLoad?.load_id ? null : row);
                }}
                selected={selectedLoad?.load_id === row.load_id}
              >
                <ODTableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {internalSearchV2 && (
                      <ODRadio
                        sx={{ mr: 1 }}
                        checked={selectedLoad?.load_id === row.load_id}
                        disabled={row?.assigned}
                        onClick={() => {
                          if (!assignBrokerageLoads && optimalMatchRow.tms_assignment === BROKERAGE) return;
                          if ([EXCLUDED, PLANNED].includes(row.tms_assignment)) return;
                          setSelectedLoad(row.load_id === selectedLoad?.load_id ? null : row);
                        }}
                      />
                    )}
                    <SearchMatchHighlight
                      content={(
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <ODLaneIcon sx={{ mr: 1 }} />
                          <Link
                            component="button"
                            variant="default"
                            onClick={(e) => {
                              e.stopPropagation();
                              setOfferedLoadDetails({
                                load_id: row.load_id,
                                load_data: {
                                  revenue: row.revenue,
                                  pickup_appt_type: row.pickup_appt_type,
                                  dropoff_appt_type: row.dropoff_appt_type
                                }
                              });
                            }}
                          >
                            {row.load_id}
                          </Link>
                        </Box>
                      )}
                      matchTerm={row.load_id}
                      searchTerm={search?.keyword}
                      disabled={!!searchColumn && searchColumn.key !== 'load_id'}
                    />
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <Box>
                    <SearchMatchHighlight
                      content={row.shipper_id || '-'}
                      matchTerm={row.shipper_id || '-'}
                      searchTerm={search?.keyword}
                      disabled={!!searchColumn && searchColumn.key !== 'shipper_id'}
                    />
                  </Box>
                </ODTableCell>
                {!internalSearchV2 && (
                  <ODTableCell sx={{ '& .MuiTypography-root': { fontWeight: 600 } }}>
                    <Box>
                      {getTmsAssignmentCell(row) || '-'}
                    </Box>
                  </ODTableCell>
                )}
                <ODTableCell>
                  <Box>
                    <SearchMatchHighlight
                      content={row.pickup_location || '-'}
                      matchTerm={row.pickup_location || '-'}
                      searchTerm={search?.keyword}
                      disabled={!!searchColumn && searchColumn.key !== 'pickup_location'}
                    />
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <Box>
                    {!!row.orig_live_flag ? 'Live Load' : 'PreLoad'}
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <Box>
                    {getLocalizedTimeRange(row.pickup_start_et, row.pickup_end_et, row.pickup_timezone, SHORT_DATE_TIME) || '-'}
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <Box>
                    <SearchMatchHighlight
                      content={row.dropoff_location || '-'}
                      matchTerm={row.dropoff_location || '-'}
                      searchTerm={search?.keyword}
                      disabled={!!searchColumn && searchColumn.key !== 'dropoff_location'}
                    />
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <Box>
                    {!!row.dest_drop_flag ? 'PreLoad' : 'Live Unload'}
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <Box>
                    {getLocalizedTimeRange(row.dropoff_start_et, row.dropoff_end_et, row.dropoff_timezone, SHORT_DATE_TIME) || '-'}
                  </Box>
                </ODTableCell>
                {addHasDispatchMatchColumn && (
                  <ODTableCell data-col-match-label="Dispatch Match">
                    <LoadDispatchMatchCell row={populateDriverObject(row)} pta={1} />
                  </ODTableCell>
                )}
                <ODTableCell>
                  <Box>
                    {row.tms_assignment === EXCLUDED
                      ? '-'
                      : (
                        <Link
                          component="button"
                          variant="default"
                          onClick={(e) => {
                            e.stopPropagation();
                            setPermitsAndRequirementsLoad(row);
                          }}
                        >
                          View Details
                        </Link>
                      )}
                  </Box>
                </ODTableCell>
                {internalSearchV2 && (
                  <>
                    <ODTableCell>
                      <Box>
                        {getTmsAssignmentCell(row)}
                      </Box>
                    </ODTableCell>
                    {showRatePerMile && (
                      <ODTableCell>
                        {!!row.rate_per_mile ? `$${row.rate_per_mile}` : '-' }
                      </ODTableCell>
                    )}
                    <ODTableCell>
                      <Box>{(!!row.empty_miles || row.empty_miles === 0) ? formatWithUnit(row.empty_miles, 'mi') : '-' }</Box>
                    </ODTableCell>
                    <ODTableCell>
                      {row?.assigned ? (
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <CheckCircleSharp sx={{ color: 'success.main' }} />
                          Assigned
                        </Box>
                      ) : (
                        <Link
                          className="assign-load-search"
                          component="button"
                          variant="blue"
                          onClick={() => {
                            if ([EXCLUDED, PLANNED].includes(row.tms_assignment)) return;
                            if (!assignBrokerageLoads && row.tms_assignment === BROKERAGE) {
                              return;
                            }
                            setSelectedLoad(row.load_id === selectedLoad?.load_id ? null : row);
                            setConfirmAssignmentOpen(true);
                          }}
                        >
                          Assign Load
                        </Link>
                      )}
                    </ODTableCell>
                  </>
                )}
              </ODTableRow>
            )))}
          </ODTableBody>
        </ODTable>
      </Box>
      {!!selectedLoad && !internalSearchV2 && (
        <Box
          sx={{
            position: 'fixed',
            bottom: '95px',
            width: 'fill-available',
            marginRight: '83px',
            zIndex: 1350,
            '> .MuiPaper-root': {
              minHeight: 'unset !important',
              minWidth: 'unset !important'
            },
          }}
        >
          <ODAlertBar variant="listAction" icon={false} sx={{ position: 'absolute', width: '100%' }}>
            {getAssignmentBarContents()}
          </ODAlertBar>
        </Box>
      )}
      <PermitsAndRequirementsDialog
        open={!!permitsAndRequirementsLoad}
        permitRequired={permitsAndRequirementsLoad?.req_permits}  
        permitDriver={currentDriver?.driver?.permits} 
        equipment={permitsAndRequirementsLoad?.equipment} 
        onClose={() => setPermitsAndRequirementsLoad(null)}
        description={() => dynamicDescription(currentDriver?.driver, permitsAndRequirementsLoad)}
      />
      <OfferedLoadDialog 
        open={!!Object.keys(offeredLoadDetails)?.length}
        load={offeredLoadDetails}
        onClose={() => setOfferedLoadDetails({})}
      />
      <BrokerageLoadDialog
        onClose={() => setBrokerageLoadId(false)}
        loadId={brokerageLoadId}
      />
      {confirmAssignmentOpen && (
        <ConfirmSearchAssignment
          subject={LOAD}
          assignment={isBrokerage ? dynamicBrokerageDescription([currentDriver], selectedLoad, DRIVER) : dynamicDescription([selectedLoad], currentDriver, LOAD)}
          isOptimal={selectedLoad?.load_id === optimalMatch?.load_id}
          hasOptimal={!!optimalMatch}
          optimalMatchNode={optimalMatchNode}
          isBrokerage={isBrokerage}
          onCancel={() => setConfirmAssignmentOpen(false)}
          onConfirm={(isOptimal, reasons, explanation) => {
            if (isOptimal) {
              confirmAssignment(
                currentDriver.engine_run,
                currentDriver.driver.driver_id,
                selectedLoad.load_id,
                optimalMatch.overall_rating,
                optimalMatch.movement_option_id
              );
            } else {
              instance.post(
                '/dispatching/assignment-feedback/',
                {
                  movement_option_id: optimalMatch?.movement_option_id ?? null,
                  engine_run: currentDriver.engine_run,
                  assignment_load_id: selectedLoad.load_id,
                  assignment_driver_id: currentDriver.driver.driver_id,
                  search_query: search?.keyword,
                  reasons_categories: reasons,
                  form_text: explanation
                }
              ).catch((err) => console.error(err));
              confirmAssignment(currentDriver.engine_run, currentDriver.driver.driver_id, selectedLoad.load_id);
            }

            resetValues();
            onClean();
          }}
        />
      )}
    </>
  );
};

export default LoadsSearch;
