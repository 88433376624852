/* eslint-disable @typescript-eslint/no-explicit-any */
import moment, { Moment } from 'moment-timezone';
import { ENGINE_RUN_ERROR_THRESHOLD_MS } from '../common/EngineRunAlert';

export const DEFAULT_TIMEZONE = 'America/New_York';

export const BASIC_DATE = 'YYYY-MM-DD';
export const SHORT_DATE = 'MM/DD';
export const BASIC_TIME = 'HH:mm';
export const BASIC_DATE_SLASH = BASIC_DATE.replace(/-/g, '/');
export const SHORT_FULL_DATE = `YY/${SHORT_DATE}`;
export const SHORT_FULL_DATE_US = `${SHORT_DATE}/YY`;
export const BASIC_TIME_TZ = `${BASIC_TIME} z`;
export const TIME_WITH_SEC = `${BASIC_TIME}:SS`;
export const DATE_TIME_SPACE_DIV = `${BASIC_DATE} ${BASIC_TIME}`;
export const DATE_TIME_SLASH_SPACE_DIV = `${BASIC_DATE_SLASH} ${BASIC_TIME}`;
export const DATE_TIME_WITH_SEC_SPACE_DIV = `${BASIC_DATE} ${TIME_WITH_SEC}`;
export const DATE_TIME_T_DIV = `${BASIC_DATE}T${BASIC_TIME}`;
export const DATE_TIME_T_DIV_TZ = `${BASIC_DATE}T${BASIC_TIME_TZ}`;
export const DATE_TIME_WITH_SEC_T_DIV = `${BASIC_DATE}T${TIME_WITH_SEC}`;
export const DATE_TIME_SLASH_WITH_SEC_T_DIV = `${BASIC_DATE_SLASH}T${TIME_WITH_SEC}`;
export const SHORT_DATE_TIME = `${SHORT_DATE} ${BASIC_TIME}`;
export const SHORT_DATE_TIME_TZ = `${SHORT_DATE_TIME} z`;
export const SHORT_DATE_TIME_AT_DIV = `${SHORT_DATE} @${BASIC_TIME}`;
export const AMERICAN_DATE_TIME = `MM-DD-YYYY ${BASIC_TIME}`;
export const DATA_UPDATED_FORMAT = `${DATE_TIME_T_DIV} zZZ`;

/**
 * We decided that this method should return the Calendar Day differential rather than hour diff % 24.
 * Hence, we parse the original date handed in, then remove the HH:mm:ss etc, & use that in the diff
 */

export const getWindowDayDiff = (startMoment: Moment, endMoment: Moment): number => {
  const startDateTZ = startMoment.tz() as any;
  const endDateTZ = endMoment.tz() as any;
  const startDayMoment = startMoment.clone().tz(startDateTZ).startOf('day');
  const endDayMoment = endMoment.clone().tz(endDateTZ).startOf('day');

  return endDayMoment.diff(startDayMoment, 'days');
};

export const getLocalizedIsoTimeRange = (
  startTime = '',
  endTime = '',
  timezone: string | null = DEFAULT_TIMEZONE
): string => {
  if (!startTime || !endTime) return '';
  const timezoneToUse: string = timezone ?? DEFAULT_TIMEZONE;

  const startMoment: Moment = moment(startTime).tz(timezoneToUse);
  const endMoment: Moment = moment(endTime).tz(timezoneToUse);
  const dayDiff: number = getWindowDayDiff(startMoment, endMoment);

  const startFormatted: string = startMoment.format(SHORT_DATE_TIME);
  const endFormatted: string = endMoment.format(BASIC_TIME_TZ);
  return `${startFormatted}-${endFormatted}${dayDiff === 0 ? '' : ` (+${dayDiff})`}`;
};

export const getLocalizedTimeRange = (
  startTime = '',
  endTime = '',
  timezone: string | null = DEFAULT_TIMEZONE,
  inputFormat: string | null | string[] = DATE_TIME_T_DIV,
  isFlex = false,
  activity: string | null = null
): string => {
  if (!startTime || !endTime) return '';
  const timezoneToUse: string = timezone ?? DEFAULT_TIMEZONE;

  const startMoment: Moment = moment.tz(startTime, inputFormat ?? DATE_TIME_T_DIV, DEFAULT_TIMEZONE).tz(timezoneToUse);
  const endMoment: Moment = moment.tz(endTime, inputFormat ?? DATE_TIME_T_DIV, DEFAULT_TIMEZONE).tz(timezoneToUse);
  const dayDiff: number = getWindowDayDiff(startMoment, endMoment);

  if (isFlex) {
    if (activity === 'pickup') {
      return startMoment.format(SHORT_DATE);
    }
    return `${startMoment.format(SHORT_DATE)}-${endMoment.format(SHORT_DATE)}`;
  }

  const startFormatted: string = startMoment.format(SHORT_DATE_TIME);
  const endFormatted: string = endMoment.format(BASIC_TIME_TZ);

  return `${startFormatted}-${endFormatted}${dayDiff === 0 ? '' : ` (+${dayDiff})`}`;
};

export const getLocalizedTime = (
  timeString: string | undefined | null,
  localTimeZone: string | undefined,
  inputFormat?: string | undefined,
  outputFormat?: string | undefined
): string => (
  moment.tz(timeString ?? '', inputFormat ?? SHORT_DATE_TIME, DEFAULT_TIMEZONE)
    .tz(localTimeZone ?? DEFAULT_TIMEZONE).format(outputFormat ?? SHORT_DATE_TIME_TZ)
);

export const getLocalizedDataUpdateDatetime = (input: string | null | undefined): string => getLocalizedTime(input, moment.tz.guess(), DATA_UPDATED_FORMAT);

// this function calculates the remaining time since dataUpdated to determine when 30 mins has passed
// for showing engine run alert banner, i.e. 30 min - (timeNow - dataUpdated)
export const getEngineRunAlertTimeDiff = (dataUpdated: string): number =>
  ENGINE_RUN_ERROR_THRESHOLD_MS - moment().diff(moment.tz(dataUpdated.split((/.EDT|.EST/g))[0], DATE_TIME_WITH_SEC_T_DIV, DEFAULT_TIMEZONE));

export const compareTimeToNow = (timeString: string | null, localTimeZone?: string | null, inputFormat?: string | null): number => {
  const timezoneToUse: string = localTimeZone ?? DEFAULT_TIMEZONE;
  const pta: Moment = moment.tz(timeString ?? '', inputFormat ?? SHORT_DATE_TIME, DEFAULT_TIMEZONE).tz(timezoneToUse);
  const time_now: Moment = moment().tz(timezoneToUse);
  return pta.diff(time_now);
};

export const getBrowserizedTime = (
  timeString: string | undefined,
  inputFormat: string | undefined,
  outputFormat: string | undefined
): string => {
  const tz: string = moment.tz.guess();
  return getLocalizedTime(timeString, tz, inputFormat, outputFormat);
};

export const getBrowserizedIsoTime = (
  timeString: string | undefined,
  outputFormat: string | undefined
): string => {
  const browserTz: string = moment.tz.guess();
  return moment.tz(timeString, DEFAULT_TIMEZONE).tz(browserTz).format(outputFormat);
};

// for dispatching
export const getSyncTimeDiff = (
  argDateString: string | null,
  syncTime: string | null,
  timezone: string | null,
  argFormat?: string | null
): number | string => {
  const timezoneToUse: string = timezone ?? DEFAULT_TIMEZONE;
  if (!syncTime || !argDateString) return Number.POSITIVE_INFINITY;
  const argMoment: Moment = moment.tz(argDateString, argFormat ?? `${AMERICAN_DATE_TIME}:SS`, DEFAULT_TIMEZONE).tz(timezoneToUse);
  const dataUpdatedMoment: Moment = moment(syncTime, DATE_TIME_T_DIV_TZ);

  const timeDiff: number = argMoment.diff(dataUpdatedMoment, 'minutes');
  const formattedDate: string = argMoment.format(`${SHORT_DATE_TIME_AT_DIV} z`);
  return timeDiff <= 5 ? 'Now' : formattedDate;
};

export const getTimeZoneAcronym = (timezone: string): string => {
  const momentInTimezone = moment.tz(timezone);
  const abbreviation = momentInTimezone.format('z');
  return abbreviation || '';
};
