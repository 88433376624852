import React, { useState, useContext } from 'react';
import { startCase } from 'lodash';
import { Badge, Box, Chip, Menu, MenuItem } from '@mui/material';
import CloseSharp from '@mui/icons-material/CloseSharp';
import FilterAltSharp from '@mui/icons-material/FilterAltSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import SearchSharp from '@mui/icons-material/SearchSharp';
import { ODDialog, ODTableContainer, ODTablePagination, ODSearchBar, ODButton } from '@OptimalDynamics/core-ai-common-ui';
import ODInfoBlurb from '../../../common/ODInfoBlurb';
import { SearchForContext } from '../../../utils/context/searchForContext';

export const SearchMatchHighlight = ({ content, searchTerm, matchTerm, disabled = false }) => {
  if (!!searchTerm && matchTerm?.toLowerCase().indexOf(searchTerm?.toLowerCase()) > -1 && !disabled) {
    return (
      <Chip
        label={content}
        sx={{
          fontSize: '14px',
          height: 28,
          backgroundColor: 'colors.lightBlue2',
          position: 'relative',
          left: -10,
          '& .MuiChip-label': { px: '10px' }
        }}
      />
    );
  }
  return content;
};

const SearchForModal = ({ titleIcon, subject, searchPrompt, currentTable, onClose, columnSearchOptions, searchTable }) => {
  const {
    resetValues, page, setPage, rowsPerPage, setRowsPerPage, count, search: searchTerm, setSearch, searchColumn, setSearchColumn
  } = useContext(SearchForContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const setSearchTerm = (keyword) => setSearch({ keyword });

  const idPrefix = `${subject}-search-for-modal`;

  return (
    <ODDialog
      id={idPrefix}
      title={`Search for ${startCase(subject)}s`}
      titleIcon={titleIcon}
      open={true}
      fullScreen={true}
      onClose={() => { resetValues(); onClose(); }}
    >
      <Box sx={{ px: 2, py: 1, borderTop: 1, borderBottom: 1, borderColor: 'level3', display: 'flex' }}>
        <ODSearchBar
          sx={{
            width: '100%',
            '&.MuiFormControl-root,.MuiInputBase-root': {
              height: '56px !important', paddingLeft: 0
            },
            '&:hover,.Mui-focused': {
              '& .MuiButton-root': { backgroundColor: 'level3' },
              '& #search-divider': { borderColor: 'level3' }
            },
          }} 
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm?.keyword}
          InputProps={{
            startAdornment: (
              <>
                {!!columnSearchOptions.length && (
                  <Box id="search-divider" sx={{ borderRight: 1, borderRightColor: 'level3', pr: 4 }}>
                    <ODButton
                      id={`search-for-${subject}-filter`}
                      variant="100"
                      onClick={handleClick}
                      sx={{
                        mx: 2,
                        ...(open && {
                          backgroundColor: 'optimalblue.main', '& .MuiSvgIcon-root': { color: 'white' }, '.button-content': { color: 'white' }
                        }),
                        justifyContent: 'center'
                      }}
                      startIcon={<FilterAltSharp />}
                      fullWidth
                    >
                      <Badge
                        variant="dot"
                        color="optimalblue"
                        invisible={searchColumn === null}
                        sx={{ '& .MuiBadge-badge': { transform: 'translate(12px, 0)' } }}
                      >
                        Search By
                      </Badge>
                    </ODButton>
                    <Menu
                      id={`search-for-${subject}-menu`}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        sx: {
                          p: '2px',
                          '& .MuiButtonBase-root.MuiMenuItem-root': { fontSize: 14, borderRadius: '4px' },
                          border: 1,
                          borderColor: 'level3',
                          '& .MuiMenuItem-root:hover': { backgroundColor: 'hover' }
                        }
                      }}
                      elevation={0}
                      anchorOrigin={{
                        vertical: 'middle',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        horizontal: -10,
                        vertical: 0
                      }}
                    >
                      <MenuItem
                        id={`search-for-${subject}-menu-all`}
                        selected={searchColumn === null}
                        onClick={() => { setSearchColumn(null); handleClose(); }}
                      >
                        All
                      </MenuItem>  
                      {columnSearchOptions?.map((opt) => (
                        <MenuItem
                          id={`search-for-${subject}-menu-${opt.key}`}
                          selected={searchColumn?.key === opt.key}
                          onClick={() => { setSearchColumn(opt); handleClose(); }}
                        >
                          {opt.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                )}
                <SearchSharp sx={{ mx: 2 }} />
              </>
            ),
            placeholder: `Search by ${searchColumn?.label ?? searchPrompt}`,
            endAdornment: searchTerm && <CloseSharp sx={{ cursor: 'pointer', color: 'text.primary' }} onClick={() => setSearchTerm('')} />,
            inputProps: { id: `search-for-${subject}-search`, sx: { fontSize: 14, '&::placeholder': { color: 'black' } } },
          }}
        />
      </Box>

      <Box sx={{ padding: '16px', flexDirection: 'column', display: 'flex', rowGap: 2 }}>
        <ODInfoBlurb icon={<InfoOutlined />}>
          {`Manually assigning ${subject}s instead of the Optimal Match will reduce the optimization of your network.`}
        </ODInfoBlurb>
        {currentTable}
      </Box>

      <ODTableContainer sx={{ height: 'calc(100vh - 235px)', width: 'unset', px: 2, mt: 1 }}>
        {searchTable}
      </ODTableContainer>
      <ODTablePagination
        sx={{ borderTop: 1, borderColor: 'level3', minHeight: '56px' }}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
        selectId="dispatching-search-pagination-select"
        slotProps={{
          actions: {
            nextButton: { id: `${idPrefix}-page-next` },
            previousButton: { id: `${idPrefix}-page-previous` }
          }
        }}
      />
    </ODDialog>
  );
};

export default SearchForModal;
