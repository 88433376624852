import { DETAILS, REVIEW } from '../../../AppConstants';

export const DISABLE_HOME_TIME = 'homeTime';
export const DISABLE_BROKERAGE = 'brokerage';
export const DISABLE_TMS_NOT_READY = 'tmsNotReady';
export const BROKERAGE = 'BROKERAGE';
export const ASSET = 'ASSET';
export const DRIVER_HOLD = 'DRIVER_HOLD';
export const EMPTY = 'EMPTY';
export const EMPTY_TO_HOME = 'EMPTY_TO_HOME';
export const EMPTY_TO_LOADED = 'EMPTY_TO_LOADED';
export const EMPTY_TO_LOAD = 'EMPTY_TO_LOAD';
export const ETL = 'ETL';
export const LOADED = 'LOADED';
export const POST_LOAD_EMPTY = 'POST_LOAD_EMPTY';
export const HOLD = 'HOLD';
export const TIME_AT_HOME = 'TAH';
export const PRESIM_END = 'PRESIM_END';
export const BY_DRIVER = 'by-driver';
export const BY_LOAD = 'by-load';
export const TMS_REGION = 'tms_region';
export const STATE = 'state';
export const CITY_STATE = 'city_state';
export const SEARCH_RESULTS = 'search-results';
export const ALL_MATCHES = 'all-matches';
export const ASSIGNED = 'assigned';
export const PLANNED = 'planned';
export const PLANNEDONLY = 'planned-only';
export const MATCHES = 'matches';
export const RELAXED = 'relaxed';
export const AVAILABLE = 'available';
export const BEYOND = 'beyond';
export const ALL_TIMES = 'all-times';
export const DROPOFF = 'DROPOFF';
export const PICKUP = 'PICKUP';
export const STOP = 'STOP';
export const HOME = 'HOME';
export const SCHEDULED_HOME = 'SCHEDULED_HOME';
export const RECOMMENDED_HOME = 'RECOMMENDED_HOME';
export const IN_PROGRESS = 'In Progress';
export const COMPLETED = 'Completed';
export const NOT_STARTED = 'Not Started';
export const LOAD = 'load';
export const LOAD_ID = 'load_id';
export const HOME_LC = 'home';
export const NONE_LC = 'none';
export const OPTIMAL = 'optimal';
export const NON_OPTIMAL = 'non-optimal';
export const DRIVER = 'driver';
export const PLAN = 'plan';
export const TMS = 'TMS';
export const LOAD_SEARCH = 'Load Search';
export const ETA = 'ETA';
export const ETD = 'ETD';
export const NO_AVAIL_MATCHES = 'NO AVAIL. MATCHES';
export const SELECT_ICON_RADIO = 'radio';
export const SELECT_ICON_CHECKBOX = 'checkbox';
export const DO_NOT_LINK = 'no-link';
export const EVENTS = 'events';
export const OTHER = 'other';
export const FORECAST = 'forecast';
export const INTERNAL = 'internal';
export const EXTERNAL = 'external';
export const BY_PARAMS = 'by-params';
export const BY_ID = 'by-id';
export const DISPATCHING_HEADERS = 'dispatching-headers';

// Current Network Status Graph Types
export const DRIVER_AVAILABILITY = 'driver_availability';
export const LOAD_AVAILABILITY = 'load_availability';
export const REVENUE_PER_DRIVER = 'revenue_per_driver';
export const PROFIT_PER_DRIVER = 'profit_per_driver';
export const EMPTY_MILEAGE_PERCENT = 'empty_miles_percent';
export const LOADED_MILES_PER_DRIVER = 'loaded_miles_per_driver';
export const AVG_RATE_PER_MILE = 'avg_rate_per_mile';
export const TOTAL_LOADED_MILES = 'total_loaded_miles';
export const TOTAL_LINE_HAUL_REVENUE = 'total_line_haul_revenue';
export const TOTAL_PROFIT = 'total_profit';
export const ACCEPTABLE_MATCHES = 'acceptable_matches';
export const RELAXED_MATCHES = 'relaxed_matches';
export const NO_MATCHES = 'no_avail_matches';
export const HOME_TIMES = 'home_times';

export const initialByDriversColumns = [
  { label: 'Driver Name', value: 'driver_name', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true, flag: 'addDriverNameUi' },
  { label: 'Driver ID', value: 'driver__driver_id', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Driver Type', value: 'driver__driver_type', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Tractor ID', value: 'driver__tractor_id', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Current Location', value: 'current_location', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Home Location', value: 'driver__home_domicile', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Next Event Location', value: 'next_event_location', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Assigned Loads', value: 'assigned_loads', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Dispatch Location', value: 'predispatch_final_loc', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Available for Dispatch', value: 'avail_datetime', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'TMS Region', value: 'driver_available_region_code', parent: DETAILS, visible: false, selectionDisabled: false, sortable: true, flag: 'useRegionCodesDispatching' },
  { label: 'Dispatch Match', value: 'optimized_dispatch', parent: REVIEW, visible: true, selectionDisabled: false, sortable: true },
  { label: 'All Load Matches', value: 'overall_rating', parent: REVIEW, visible: true, selectionDisabled: true, sortable: false },
];

export const initialByLoadsColumns = [
  { label: 'Load ID', value: 'load_id', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Shipper ID', value: 'shipper_id', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Shipper Name', value: 'shipper_name', parent: DETAILS, visible: false, selectionDisabled: false, sortable: true, flag: 'showShipperName' },
  { label: 'Pick Up Location', value: 'pickup_location', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Pick Up Window', value: 'pickup_start_et', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Origin Type', value: 'orig_live_flag', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Drop Off Location', value: 'dropoff_location', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Drop Off Window', value: 'dropoff_start_et', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Destination Type', value: 'dest_drop_flag', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Loaded Mileage', value: 'loaded_mileage', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true },
  { label: 'Revenue', value: 'revenue', parent: DETAILS, visible: true, selectionDisabled: false, sortable: true, flag: 'showRevenueInDispatching' },
  { label: 'TMS Pickup Region', value: 'pickup_region_code', parent: DETAILS, visible: false, selectionDisabled: false, sortable: true, flag: 'useRegionCodesDispatching' },
  { label: 'TMS Dropoff Region', value: 'dropoff_region_code', parent: DETAILS, visible: false, selectionDisabled: false, sortable: true, flag: 'useRegionCodesDispatching' },
  { label: 'Dispatch Match', value: 'optimized_dispatch', parent: REVIEW, visible: true, selectionDisabled: false, sortable: true },
  { label: 'All Driver Matches', value: 'overall_rating', parent: REVIEW, visible: true, selectionDisabled: true, sortable: false },
];
