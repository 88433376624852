import React, { ReactElement, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import { assign, cloneDeep, find } from 'lodash';
import { ODLaneIcon, ODAppMenu, ODAvatar, ODTooltip } from '@OptimalDynamics/core-ai-common-ui';
import { AppBar, Grid, Paper } from '@mui/material';
import CompareArrowsSharp from '@mui/icons-material/CompareArrowsSharp';
import LibraryBooksSharp from '@mui/icons-material/LibraryBooksSharp';
import NotInterestedSharp from '@mui/icons-material/NotInterestedSharp';
import HistorySharp from '@mui/icons-material/HistorySharp';
import RuleSharp from '@mui/icons-material/RuleSharp';
import TimelineSharp from '@mui/icons-material/TimelineSharp';
import { GlobalAlertsDrawerContextWrapper } from '../utils/context';
import cookies from '../utils/cookies';
import { GetUserData, GetUserSettings } from '../store/actions/auth';
import { FetchUserSettings, UpdateUserSettings } from '../store/actions/userSettings';
import { GetEngineRun, GetAlerts } from '../store/actions/notificationAction';
import { TacticalProcurementIcon, TrailerIcon } from '../common/icons';
import draftStore from '../utils/draftStore';
import { RootState } from '../store/reducers';
import { DEFAULT_TIMEZONE, getLocalizedTime } from '../utils/datetimes';
import { BY_DRIVER, BY_LOAD, DISPATCHING_HEADERS, initialByDriversColumns, initialByLoadsColumns } from '../components/dispatching/helpers/constants';
import instance from '../utils/axios_instance';
import { TwoWayLoadHeaders } from '../defaults/headers';
import { LOAD_ACCEPTANCE_HEADERS } from '../components/loadAcceptance/loadAcceptanceConstants';
import { DETAILS } from '../AppConstants';

const LAST_SYNC_ZD_FIELD_ID = '12233454439053';

interface menuOptionObject {
  title: string;
  matchKey: string;
  icon: ReactElement;
  selected: boolean;
  onClick: () => void;
}

declare global {
  interface Window {
    zE: (_obj: string, _action: string, _next?: any) => void;
    openWidget: () => void;
  }
}

interface RightNavButtonProps {
  title: string;
  onClick: () => void;
  icon: ReactElement;
}

const LayoutAppBar = ({ children }: { children: React.ReactNode }) => (
  <AppBar
    position="sticky"
    sx={{
      width: 56,
      backgroundColor: 'colors.white',
      borderLeft: 1,
      borderColor: 'level3',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '2px',
      alignItems: 'center',
      zIndex: 1300,
      pt: 1,
      height: '100vh'
    }}
    elevation={0}
  >
    {children}
  </AppBar>
);

const RightNavButton = ({ title, onClick, icon }: RightNavButtonProps) => (
  <ODTooltip title={title} placement="left">
    <div>
      <ODAvatar variant="transparent" onClick={onClick}>
        {icon}
      </ODAvatar>
    </div>
  </ODTooltip>
);

const Layout = () => {
  const [token, setToken] = useState<null | string>(null);
  const [menuOptions, setMenuOptions] = useState<menuOptionObject[]>([]);
  const [helpOpen, setHelpOpen] = useState(false);
  const { engineRun } = useSelector((state: RootState) => state.notificationReducer) || {};
  const dispatchingDataUpdated = useSelector((state: RootState) => state.dispatchingReducer.dataUpdated) || '';
  const dispatchingEngineRunId = useSelector((state: RootState) => state.dispatchingReducer.engineRunId) || '';
  const notificationEngineRunId = useSelector((state: RootState) => state.notificationReducer.engineRun) || '';
  const notificationDataUpdated = useSelector((state: RootState) => state.notificationReducer.engineRunUpdate) || '';
  const loadAcceptanceDataUpdated = useSelector((state: RootState) => state.loads.dataUpdated) || '';
  const loadAcceptanceEngineRunId = useSelector((state: RootState) => state.loads.engineRunId) || '';
  const forecastingEngineRunId = useSelector((state: RootState) => state.forecasting.engineRunId) || '';
  const forecastingDataUpdated = useSelector((state: RootState) => moment.tz(state.forecasting.lastSynced, DEFAULT_TIMEZONE).tz(moment.tz.guess()).format(`YYYY-MM-DDTHH:mm zZZ`)) || '';
  const launchDarklyUserIdentified = useSelector((state: RootState) => state.auth.launchDarklyUserIdentified);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    showForecastingUiPage,
    showLaUiPage,
    showTpUiPage,
    showCommitmentsUiPage,
    showDispatchingUiPage,
    showFeedbackTracker,
    showTrailerAssignmentsUiPage,
    assignBrokerageLoads
  } = useFlags();
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
  const [open, setOpen] = useState(false);
  const [initialMenuOptions, setInitialMenuOptions] = useState<menuOptionObject[]>([]);
  const updatedByLoadsColumns = [
    ...initialByLoadsColumns.slice(0, 1),
    ...(assignBrokerageLoads
      ? [{ label: 'TMS Assignment',
        value: 'internal_source',
        parent: DETAILS,
        visible: true,
        selectionDisabled: false,
        sortable: true,
        flag: 'showTmsAssignmentColumn', }]
      : []),
    ...initialByLoadsColumns.slice(1),
  ];

  // the following should be used for updating the structure of parts of userSettings. increment the version whenever an update is necessary.
  const checkAndUpdateUserSettingsSchema = async () => {
    await instance
      .get('/user-settings/')
      .then((res) => {
        if (res.data?.version !== '1.4') {
          // dispatching
          let driversColumns = cloneDeep(res.data[DISPATCHING_HEADERS]?.[BY_DRIVER] || initialByDriversColumns);
          let loadsColumns = cloneDeep(res.data[DISPATCHING_HEADERS]?.[BY_LOAD] || updatedByLoadsColumns);

          driversColumns = cloneDeep(initialByDriversColumns).map((defaultCol) => {
            const userCol = find(driversColumns, { value: defaultCol.value });
            if (userCol) assign(defaultCol, { visible: userCol.visible });
            return defaultCol;
          });

          loadsColumns = cloneDeep(updatedByLoadsColumns).map((defaultCol) => {
            const userCol = find(loadsColumns, { value: defaultCol.value });
            if (userCol) assign(defaultCol, { visible: userCol.visible });
            return defaultCol;
          });

          // load acceptance
          let loadAcceptanceHeaders = cloneDeep(res.data?.[LOAD_ACCEPTANCE_HEADERS] || TwoWayLoadHeaders);
          loadAcceptanceHeaders = cloneDeep(TwoWayLoadHeaders).map((defaultCol) => {
            const userCol = find(loadAcceptanceHeaders, { sortKey: defaultCol.sortKey });
            if (userCol) assign(defaultCol, { visible: userCol.visible });
            return defaultCol;
          });

          const tempUserSettings = cloneDeep(res.data);
          tempUserSettings[DISPATCHING_HEADERS] = { ...res.data[DISPATCHING_HEADERS], [BY_DRIVER]: driversColumns, [BY_LOAD]: loadsColumns };
          dispatch(UpdateUserSettings({ ...tempUserSettings, [LOAD_ACCEPTANCE_HEADERS]: loadAcceptanceHeaders, version: '1.4' }));
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (!!token) checkAndUpdateUserSettingsSchema();
  }, [token]);

  useEffect(() => {
    setOpen(
      // if there is no menuOpen session key and window is > 2140px, return true; otherwise return menuOpen session value
      draftStore.getSession('menuOpen') === null
        ? window.matchMedia('(min-width: 2140px)').matches
        : draftStore.getSession('menuOpen')
    );
  }, []);

  useEffect(() => {
    getToken();
  }, [isLoading]);

  useEffect(() => {
    if (!!token) {
      dispatch(FetchUserSettings());
    }
  }, [token, dispatch]);
  
  const getRequiredData = async (newToken: string) => {
    if (newToken) {
      dispatch(GetUserData());
      dispatch(GetEngineRun());
      dispatch(GetUserSettings());
      setToken(newToken);
    }
  };

  useEffect(() => {
    if (engineRun) {
      dispatch(GetAlerts(engineRun));
    }
  }, [engineRun]);

  const getToken = async () => {
    if (isLoading) return;
    if (!isAuthenticated) {
      navigate('/login', { state: { from: location.pathname } });
    }
    const newToken = await getAccessTokenSilently();
    const subdomain = window.location.hostname === 'localhost' ? '' : `${window.location.hostname.split('.')[0]}_`;
    cookies.set(`${subdomain}token`, newToken);
    setToken(newToken);
    getRequiredData(newToken);
  };

  const logoutAuth = async () => {
    logout({ returnTo: window.location.origin });
  };

  // checks menu options against their feature flag values
  useEffect(() => {
    const flaggedMenuOptions: menuOptionObject[] = [];
    if (launchDarklyUserIdentified) {
      if (showForecastingUiPage) {
        flaggedMenuOptions.push(
          { title: 'Load Demand Forecast', matchKey: 'load-demand-forecast', icon: <TimelineSharp />, selected: false, onClick: () => navigate('/load-demand-forecast') }
        );
      }
    
      if (showTpUiPage) {
        flaggedMenuOptions.push(
          { title: 'Tactical Procurement', matchKey: 'tactical-procurement', icon: <TacticalProcurementIcon />, selected: false, onClick: () => navigate('/tactical-procurement') }
        );
      }
    
      if (showLaUiPage) {
        flaggedMenuOptions.push(
          { title: 'Load Acceptance', matchKey: 'load-acceptance', icon: <ODLaneIcon />, selected: true, onClick: () => navigate('/load-acceptance') }
        );
      }
    
      if (showDispatchingUiPage) {
        flaggedMenuOptions.push(
          { title: 'Dispatching', matchKey: 'dispatching', icon: <CompareArrowsSharp />, selected: !showLaUiPage, onClick: () => navigate('/dispatching') }
        );
      }
    
      if (showTrailerAssignmentsUiPage) {
        flaggedMenuOptions.push(
          { title: 'Trailer Assignments', matchKey: 'trailer-assignments', icon: <TrailerIcon />, selected: false, onClick: () => navigate('/trailer-assignments') }
        );
      }
    
      if (showCommitmentsUiPage) {
        flaggedMenuOptions.push(
          { title: 'Commitment Manager', matchKey: 'commitment-manager', icon: <LibraryBooksSharp />, selected: false, onClick: () => navigate('/commitment-manager') }
        );
      }
      setInitialMenuOptions(flaggedMenuOptions);
    }
  }, [launchDarklyUserIdentified, showCommitmentsUiPage, showDispatchingUiPage, showForecastingUiPage, showLaUiPage, showTpUiPage, showTrailerAssignmentsUiPage]);

  // determines which menu option has the `selected` state
  useEffect(() => {
    const updatedMenuOptions = initialMenuOptions.map((option) => {
      if (location.pathname === '/') return option;
      if (location.pathname.indexOf(option.matchKey) > -1) {
        option.selected = true;
      } else {
        option.selected = false;
      }
      return option;
    });
    setMenuOptions(updatedMenuOptions);
  }, [location, showForecastingUiPage, showLaUiPage, showTpUiPage, showCommitmentsUiPage, initialMenuOptions]);

  const handleHideZenDeskWidget = (shouldClose = true) => {
    window.zE('webWidget', 'hide');
    if (shouldClose) window.zE('webWidget', 'close');
    setHelpOpen(false);
  };

  window.zE('webWidget:on', 'open', () => {
    setHelpOpen(true);
  });

  window.zE('webWidget:on', 'close', () => {
    handleHideZenDeskWidget();
  });

  window.zE('webWidget:on', 'chat:popout', () => {
    handleHideZenDeskWidget(false);
  });

  // gets the correct engine run data depending on the user's url location
  const getRelevantData = () => {
    switch (true) {
      case location.pathname.includes('dispatching'):
        return [dispatchingEngineRunId, dispatchingDataUpdated];
      case location.pathname.includes('load-acceptance'):
        return [loadAcceptanceEngineRunId, loadAcceptanceDataUpdated];
      case location.pathname.includes('tactical-procurement'):
        return [notificationEngineRunId, notificationDataUpdated];
      case location.pathname.includes('load-demand-forecast'):
        return [forecastingEngineRunId, forecastingDataUpdated];
      default:
        return ['N/A', 'N/A'];
    }
  };

  return (
    <GlobalAlertsDrawerContextWrapper>
      <div style={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <ODAppMenu
              menuOptions={menuOptions}
              onClickLogout={logoutAuth}
              open={open}
              showFeedbackTracker={showFeedbackTracker}
              onToggle={() => {
                draftStore.setSession('menuOpen', !open);
                setOpen(!open);
              }}
              onClickHelp={() => {
                if (helpOpen) {
                  handleHideZenDeskWidget();
                } else {
                  const [relevantEngineRunId, relevantSyncTime] = getRelevantData();
                  window.openWidget();
                  window.zE('webWidget', 'updateSettings', {
                    webWidget: {
                      contactForm: {
                        fields: [
                          { id: LAST_SYNC_ZD_FIELD_ID,
                            prefill: {
                              '*': relevantSyncTime === 'N/A'
                                ? relevantSyncTime
                                : `${getLocalizedTime(relevantSyncTime.replace(/(EST|EDT)/gm, ''), moment.tz.guess(), 'YYYY-MM-DDTHH:mm ZZ', 'MM/DD HH:mm z')} (ID: ${relevantEngineRunId})`
                            }
                          }
                        ]
                      }
                    }
                  });
                }
              }}
              helpActive={helpOpen}
            />
            <Grid item xs={12} style={{ position: 'relative', width: `calc(100% - ${open ? 280 : 112}px)` }}>
              {token && (
                <Paper square={true} style={{ boxShadow: 'none', position: 'relative' }}>
                  <Outlet />
                </Paper>
              )}
            </Grid>
            {location.pathname.includes('dispatching') && (
              <LayoutAppBar>
                <RightNavButton title="Assignment History" icon={<HistorySharp />} onClick={() => setSearchParams({ ...Object.fromEntries([...searchParams]), show_history: 'true' })} />
                <RightNavButton title="Driver Rules" icon={<RuleSharp />} onClick={() => setSearchParams({ ...Object.fromEntries([...searchParams]), show_rules: 'true' })} />
                <RightNavButton title="Data Errors & Excluded" icon={<NotInterestedSharp />} onClick={() => setSearchParams({ ...Object.fromEntries([...searchParams]), show_exclusions: 'true' })} />
              </LayoutAppBar>
            )}
            {location.pathname.includes('trailer-assignments') && (
              <LayoutAppBar>
                <RightNavButton title="Assignment History" icon={<HistorySharp />} onClick={() => setSearchParams({ ...Object.fromEntries([...searchParams]), show_history: 'true' })} />
                <RightNavButton title="Excluded Trailers" icon={<NotInterestedSharp />} onClick={() => setSearchParams({ ...Object.fromEntries([...searchParams]), show_exclusions: 'true' })} />
              </LayoutAppBar>
            )}
          </Grid>
        </Grid>
      </div>
    </GlobalAlertsDrawerContextWrapper>
  );
};

export default Layout;
