/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useState, useMemo } from 'react';

/**
 * @constant ['view-all', 'outbound', 'inbound']
 */
export const LANE_DETAIL_VIEWS = Object.freeze(['view-all', 'outbound', 'inbound']);

export const LaneDetailViewCtx = createContext({
  viewValue: '',
  setViewValue: {}
});

export const LaneDetailViewCtxWrapper = (props) => {
  const [viewValue, _setViewValue] = useState(LANE_DETAIL_VIEWS[0]);

  const setViewValue = ({
    [LANE_DETAIL_VIEWS[0]]: () => _setViewValue(LANE_DETAIL_VIEWS[0]),
    [LANE_DETAIL_VIEWS[1]]: () => _setViewValue(LANE_DETAIL_VIEWS[1]),
    [LANE_DETAIL_VIEWS[2]]: () => _setViewValue(LANE_DETAIL_VIEWS[2]),
  });

  const memoLaneDetailViewCtxValue = useMemo(() => ({ viewValue, setViewValue }), [viewValue]);

  return (
    <LaneDetailViewCtx.Provider
      value={memoLaneDetailViewCtxValue}
    >
      { props.children }
    </LaneDetailViewCtx.Provider>
  );
};

export const withLaneDetailViewCtxAsPropsHOC = (WrappedComponent) => (props) => (
  <LaneDetailViewCtx.Consumer>
    {({ viewValue, setViewValue }) => (
      <WrappedComponent
        {...props}
        viewValue={viewValue}
        setViewValue={setViewValue}
      >
        { props.children }
      </WrappedComponent>
    )}
  </LaneDetailViewCtx.Consumer>
);
