import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box } from '@mui/material';
import HomeSharp from '@mui/icons-material/HomeSharp';
import HistorySharp from '@mui/icons-material/HistorySharp';
import StarSharp from '@mui/icons-material/StarSharp';
import StarHalfSharp from '@mui/icons-material/StarHalfSharp';
import StarOutlineSharp from '@mui/icons-material/StarOutlineSharp';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import StarsSharp from '@mui/icons-material/StarsSharp';
import { ODNoLoadIcon, ODTooltip } from '@OptimalDynamics/core-ai-common-ui/';
import { TuneOffIcon } from '../../../common/icons';
import {
  DRIVER_HOLD,
  EMPTY_TO_HOME,
  NO_AVAIL_MATCHES,
} from '../helpers/constants';
import { DispatchingDriver, MatchSpecifics } from '../types';

const DriverDispatchMatchCell = ({ row }: { row: DispatchingDriver }) => {
  const { showRelaxedMatches, orgUse2WayDispatching, ignorePlannedDispatchingStatus, showSourceSuggestions } = useFlags();
  const {
    active,
    top_option_load_count,
    first_recommend_movement_type,
    is_relaxed,
    load_id,
    overall_rating,
    preferred_options_count,
    relaxed_options_count,
    movement_option_rank,
    has_source
  } = row;

  let specifics = {} as MatchSpecifics;

  const noAvailMatchesSpecifics = {
    icon: <ODNoLoadIcon />,
    text: NO_AVAIL_MATCHES,
    tooltip: 'No Available Matches'
  };

  const isRelaxed = showRelaxedMatches && is_relaxed;
  const isSource = showSourceSuggestions && has_source;
  let availableOptionsCount = preferred_options_count;
  availableOptionsCount += isRelaxed ? relaxed_options_count : 0;

  const getStarIcon = () => {
    const rating = parseInt(overall_rating, 10);
    switch (true) {
      case rating === 5:
        if (movement_option_rank !== 1) {
          return { star: <StarSharp />, tip: 'Great Load Match' };
        }
        return { star: <StarsSharp sx={{ color: 'optimalblue.main' }} />, tip: 'Optimal Load Match' };
      case rating >= 3:
        return { star: <StarHalfSharp />, tip: 'Good Load Match' };
      default:
        return { star: <StarOutlineSharp />, tip: 'Feasible Load Match' };
    }
  };

  switch (true) {
    case active === false:
      // case to show (for CHR) active === false drivers as no available matches
      // case to be removed when ignorePlannedDispatchingStatus is archived
      if (ignorePlannedDispatchingStatus) specifics = noAvailMatchesSpecifics;
      else {
        // case for drivers who have been assigned/planned
        specifics = {
          icon: <CheckCircleSharp sx={{ color: 'success.main' }} />,
          text: 'PLANNED',
          tooltip: 'Planned'
        };
      }
      if (!!Number(top_option_load_count)) {
        specifics = {
          text: orgUse2WayDispatching ? 'ASSIGNED' : 'ADD TO TMS',
          icon: orgUse2WayDispatching ? <HistorySharp /> : <CheckCircleSharp sx={{ color: 'success.main' }} />,
          tooltip: orgUse2WayDispatching ? 'Assigned' : 'Add to TMS'
        };
      }
      break;
    // case for home time
    case first_recommend_movement_type === EMPTY_TO_HOME:
      specifics = {
        icon: isRelaxed ? <TuneOffIcon /> : <HomeSharp />,
        text: isSource ? 'SOURCE LOAD' : 'HOME TIME',
        tooltip: 'Home Time'
      };
      break;
    // cases for no matches at all
    case availableOptionsCount === 0:
    case availableOptionsCount === 1 && first_recommend_movement_type === DRIVER_HOLD:
    case !showRelaxedMatches && is_relaxed:
    case !showSourceSuggestions && has_source:
      specifics = noAvailMatchesSpecifics;
      break;
    // case for load and tour recommendation
    case (Number(top_option_load_count) > 0):
      const loadStar = getStarIcon();
      const singleLoad = Number(top_option_load_count) === 1;
      const text = isSource ? `SOURCE ${singleLoad ? 'LOAD' : 'TOUR'}`
        : (singleLoad ? load_id : `${Number(top_option_load_count)} LOADS`);
      specifics = {
        icon: isRelaxed ? <TuneOffIcon /> : loadStar.star,
        text,
        tooltip: isRelaxed ? 'Exception Match' : loadStar.tip
      };
      break;
    default:
      return <></>;
  }

  if (Object.keys(specifics).length === 0) return <></>;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
      <ODTooltip title={specifics.tooltip} placement="right">
        {specifics.icon}
      </ODTooltip>
      {specifics.text}
    </Box>
  );
};

export default DriverDispatchMatchCell;
