import React from 'react';
import { CircularProgress } from '@mui/material';
import AddSharp from '@mui/icons-material/AddSharp';
import AssignmentReturnedSharp from '@mui/icons-material/AssignmentReturnedSharp';
import AssignmentTurnedInSharp from '@mui/icons-material/AssignmentTurnedInSharp';
import CheckSharp from '@mui/icons-material/CheckSharp';
import LocalShippingSharp from '@mui/icons-material/LocalShippingSharp';
import RuleSharp from '@mui/icons-material/RuleSharp';
import SaveAltSharp from '@mui/icons-material/SaveAltSharp';
import SwapHorizSharp from '@mui/icons-material/SwapHorizSharp';
import { ODLaneIcon } from '@OptimalDynamics/core-ai-common-ui';
import { AssignmentRejectedSharp } from '../../common/icons';

export const defaultTabs = [
  {
    name: 'Fleet Loads',
    icon: <ODLaneIcon sx={{ margin: '0 16px 0 0', color: 'success.main' }} />,
    value: 'fleet'
  },
  {
    name: 'External Loads',
    icon: <ODLaneIcon sx={{ margin: '0 16px 0 0', color: 'colors.accessiblePurple' }} />,
    value: 'external'
  },
  {
    name: 'Shippers',
    icon: <LocalShippingSharp style={{ margin: '0 16px 0 0' }} />,
    value: 'shippers'
  },
];

export const defaultSortOrder = {
  sortKey: 'load_id',
  column: 'load_id',
  isAsc: false,
};

export const RECOM = 'RECOM';
export const NOT_RECOMMENDED = 'NOT_RECOMMENDED';
export const ACCEPTED = 'ACCEPTED';
export const REJECTED = 'REJECTED';
export const FLEET_TITLE = 'Fleet';
export const EXTERNAL_TITLE = 'External';
export const LOADS_TO_CHANGE = 'to-change';
export const LOADS_ON_ASSET = 'asset';
export const LOADS_ON_BROKERAGE = 'brokerage';
export const POLLING_DATA_FETCH_DELAY = 4000;
export const POLLING_DURATION = 60000;
export const SYNC_UPDATE_POLLING_DURATION = 10000;
export const MATCHES = 'matches';
export const SYNCING = 'syncing';
export const SWITCH_TO_BROKERAGE = 'switch-to-brokerage';
export const SWITCH_TO_ASSET = 'switch-to-asset';
export const CREATE_RULE = 'create-rule';
export const ASSIGN_ASSET = 'assign-asset';
export const ASSIGN_BROKERAGE = 'assign-brokerage';
export const KEEP_ASSET = 'keep-asset';
export const KEEP_BROKERAGE = 'keep-brokerage';
export const KEEP_BULK = 'keep-bulk';
export const ACCEPT_ASSET = 'accept-asset';
export const ACCEPT_BROKERAGE = 'accept-brokerage';
export const REJECT_LOAD = 'reject-load';
export const RULE_ACTIVE = 'active';
export const RULE_CONFLICT = 'conflict';
export const RULE_EXISTS = 'existent';
export const RULE_OTHER = 'other';
export const DETAILS = 'details';
export const REVIEW = 'review';
export const SHIPPER_ID = 'Shipper ID';
export const ORIGIN = 'Origin';
export const DESTINATION = 'Destination';
export const EQUIPMENT = 'Equipment';
export const LOAD_ACCEPTANCE_HEADERS = 'load-acceptance-headers';

export const STALE_ACTIONS = [MATCHES, SYNCING];
export const TENDER_NOT_RECOMMENDED = [REJECT_LOAD, ACCEPT_BROKERAGE];

export const viewtypeOptionsDefault = {
  [LOADS_TO_CHANGE]: { 
    optionLabel: 'Loads to Change',
    icon: <SwapHorizSharp sx={{ color: 'colors.semanticBlue' }} />,
    figureA: null,
    figureB: null 
  },
  [LOADS_ON_ASSET]: { 
    optionLabel: 'Loads on Asset',
    icon: <CheckSharp sx={{ color: 'success.main' }} />,
    figureA: null,
    figureB: null 
  },
  [LOADS_ON_BROKERAGE]: { 
    optionLabel: 'Loads on Brokerage',
    icon: <CheckSharp sx={{ color: 'success.main' }} />,
    figureA: null,
    figureB: null
  }
};

export const viewtypeOptionsTender = {
  [LOADS_ON_BROKERAGE]: { 
    optionLabel: 'Tendered Loads',
    icon: <SwapHorizSharp sx={{ color: 'colors.semanticBlue' }} />,
    figureA: null,
    figureB: null 
  },
  [LOADS_ON_ASSET]: { 
    optionLabel: 'Loads on Asset',
    icon: <CheckSharp sx={{ color: 'success.main' }} />,
    figureA: null,
    figureB: null 
  }
};

export const ACTIONS: Record<string, any> = {
  [CREATE_RULE]: { icon: <RuleSharp />, label: 'Create Rule' },
  [ASSIGN_ASSET]: { icon: <AddSharp />, label: 'Assign to Asset' },
  [ASSIGN_BROKERAGE]: { icon: <AddSharp />, label: 'Assign to Brokerage' },
  [KEEP_ASSET]: { icon: <SaveAltSharp />, label: 'Keep on Asset' },
  [KEEP_BROKERAGE]: { icon: <SaveAltSharp />, label: 'Keep on Brokerage' },
  [KEEP_BULK]: { icon: <SaveAltSharp />, label: 'Keep TMS Assignment' },
  [SWITCH_TO_ASSET]: { icon: <SwapHorizSharp />, label: 'Switch to Asset' },
  [SWITCH_TO_BROKERAGE]: { icon: <SwapHorizSharp />, label: 'Switch to Brokerage' },
  [ACCEPT_ASSET]: { icon: <AssignmentTurnedInSharp />, label: 'Accept on Asset' },
  [ACCEPT_BROKERAGE]: { icon: <AssignmentReturnedSharp />, label: 'Accept on Brokerage' },
  [REJECT_LOAD]: { icon: <AssignmentRejectedSharp />, label: 'Reject Load' },
  [SYNCING]: { icon: <CircularProgress color="success" size="16px" />, label: 'Syncing' }
};
