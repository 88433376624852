import React from 'react';
import FlagSharp from '@mui/icons-material/FlagSharp';
import {
  DetailCardTimeline,
  AvailableTimeRow,
  HomeRow,
  LocationRow,
  StopRow,
  Mileage,
  generateTimestamp
} from '../DetailCard/DetailCardTimeline';
import { getLocalizedTimeRange } from '../../../utils/datetimes';
import {
  DROPOFF,
  HOME,
  LOADED,
  PICKUP,
  RECOMMENDED_HOME,
  SCHEDULED_HOME,
  STOP
} from '../helpers/constants';
import { getOriginLabel, getDestinationLabel } from '../helpers/dispatchingScripts';
import { DriversSlideoutCardTimelineProps, Submove } from '../types';

export const DriversSlideoutCardTimeline = ({ selected, currentLoad, driverOption, showAvailable, openHosDialog, openExplainPtaDialog }: DriversSlideoutCardTimelineProps) => {
  if (!currentLoad) return null;

  const renderSubmoves = (submoves: Submove[]) => (
    <>
      {submoves[0].type === LOADED && (() => {
        const etd = generateTimestamp(driverOption.est_pickup_time, currentLoad.pickup_timezone);
        const elt = getLocalizedTimeRange(driverOption.pickup_est_loading_start_time, driverOption.pickup_est_loading_end_time, currentLoad.pickup_timezone);
        return (
          <LocationRow
            label={currentLoad.orig_live_flag ? 'Origin Live Load' : 'Origin PreLoad Pick Up'}
            start={currentLoad.pickup_start_et}
            end={currentLoad.pickup_end_et}
            estimates={{ ELT: elt, ETD: etd }}
            timezone={currentLoad.pickup_timezone}
            location={currentLoad.pickup_location}
            mileage={driverOption.has_prerequisite ? 'TBD' : driverOption.empty_miles}
            selected={selected}
          />
        );
      })()}
      {submoves.map((move) => {
        switch (move.end_location_type) {
          case PICKUP:
            const etd = generateTimestamp(driverOption.est_pickup_time, move.end_timezone);
            const elt = getLocalizedTimeRange(driverOption.pickup_est_loading_start_time, driverOption.pickup_est_loading_end_time, move.end_timezone);
            return (
              <LocationRow
                label={getOriginLabel(currentLoad.orig_live_flag)}
                start={currentLoad.pickup_start_et}
                end={currentLoad.pickup_end_et}
                estimates={{ ELT: elt, ETD: etd }}
                timezone={move.end_timezone}
                location={move.end_city_state}
                mileage={driverOption.has_prerequisite ? 'TBD' : move.distance}
                selected={selected}
              />
            );
          case DROPOFF:
            const eta = generateTimestamp(driverOption.est_dropoff_time, move.end_timezone);
            const eult = getLocalizedTimeRange(driverOption.dropoff_est_unloading_start_time, driverOption.dropoff_est_unloading_end_time, move.end_timezone);
            return (
              <LocationRow
                label={getDestinationLabel(currentLoad.dest_drop_flag)}
                start={currentLoad.dropoff_start_et}
                end={currentLoad.dropoff_end_et}
                estimates={{ ETA: eta, EULT: eult }}
                timezone={move.end_timezone}
                location={move.end_city_state}
                mileage={move.distance}
                icon={<FlagSharp />}
                selected={selected}
              />
            );
          case HOME:
          case SCHEDULED_HOME:
          case RECOMMENDED_HOME:
            if (move.type.includes(HOME)) {
              const type = move.end_location_type;
              return (
                <HomeRow
                  label={`${type === SCHEDULED_HOME ? 'Scheduled ' : type === RECOMMENDED_HOME ? 'Recommended ' : ''}Home Time`}
                  time={move.start_time}
                  timezone={move.start_timezone}
                  location={move.start_city_state}
                  selected={selected}
                  timeWindow={getLocalizedTimeRange(move.start_time, move.end_time, move.start_timezone)}
                />
              );
            }
            return <Mileage mileage={move.distance} />;
          case STOP:
            return (
              <StopRow
                estimate={move.end_time}
                timezone={move.end_timezone}
                location={move.end_city_state}
                mileage={move.distance}
                selected={selected}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );

  const renderClassic = () => {
    const pickupEtd = generateTimestamp(driverOption.est_pickup_time, currentLoad.pickup_timezone);
    const pickupElt = getLocalizedTimeRange(driverOption.pickup_est_loading_start_time, driverOption.pickup_est_loading_end_time, currentLoad.pickup_timezone);
    const dropoffEta = generateTimestamp(driverOption.est_dropoff_time, currentLoad.dropoff_timezone);
    const dropoffEult = getLocalizedTimeRange(driverOption.dropoff_est_unloading_start_time, driverOption.dropoff_est_unloading_end_time, currentLoad.dropoff_timezone);

    return (
      <>
        <LocationRow
          label={getOriginLabel(currentLoad.orig_live_flag)}
          start={currentLoad.pickup_start_et}
          end={currentLoad.pickup_end_et}
          estimates={{ ELT: pickupElt, ETD: pickupEtd }}
          timezone={currentLoad.pickup_timezone}
          location={currentLoad.pickup_location}
          mileage={driverOption.empty_miles}
          selected={selected}
        />
        <LocationRow
          label={getDestinationLabel(currentLoad.dest_drop_flag)}
          start={currentLoad.dropoff_start_et}
          end={currentLoad.dropoff_end_et}
          estimates={{ ETA: dropoffEta, EULT: dropoffEult }}
          timezone={currentLoad.dropoff_timezone}
          location={currentLoad.dropoff_location}
          mileage={driverOption.loaded_miles}
          icon={<FlagSharp />}
          selected={selected}
        />
      </>
    );
  };

  return (
    <DetailCardTimeline selected={selected}>
      {showAvailable && (
        <AvailableTimeRow
          available={driverOption.avail_for_dispatch_et}
          timezone={driverOption.predispatch_timezone}
          selected={selected}
          hos={driverOption.hos_status}
          openHosDialog={openHosDialog}
          openExplainPtaDialog={openExplainPtaDialog}
          location={driverOption.predispatch_final_loc}
          hasPrerequisite={driverOption.has_prerequisite}
        />
      )}

      {driverOption?.submoves?.length > 0 ? renderSubmoves(driverOption.submoves) : renderClassic()}
    </DetailCardTimeline>
  );
};
