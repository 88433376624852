import React, { ChangeEvent, ReactElement, RefObject, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Typography, TextField } from '@mui/material';
import { ODSlideOut, ODButton } from '@OptimalDynamics/core-ai-common-ui';
import { ConfirmContainer } from '../../../common';
import SelectedOptions from '../../../common/SelectedOptions';
import { DRIVER, LOAD } from '../helpers/constants';
import ODSelectWrapper from '../components/ODSelectWrapper';

const REASONS = {
  not_actionable: 'No Actionable Optimal Match',
  idle_time: 'Driver Idle Time',
  dedicated_lane: 'Dedicated Lane',
  preference: 'Driver Preference',
  home_time: 'Driver Home Time',
  hos_clock: 'Driver HOS Clocks',
  empty_mi: 'Empty Miles',
  loaded_mi: 'Loaded Miles',
  shipper: 'Shipper',
  equipment: 'Equipment',
  origin_loc: 'Origin Location',
  destination_loc: 'Destination Location',
  origin_eta: 'Origin ETA',
  destination_eta: 'Destination ETA',
  stops: 'Stops or Relays',
  other: 'Other'
};

interface ConfirmSearchAssignmentProps {
  subject: 'driver' | 'load';
  assignment: ReactElement;
  onConfirm: (_isOptimal: boolean, _reasons?: string[], _explanation?: string) => void;
  onCancel: () => void;
  isOptimal: boolean;
  hasOptimal: boolean,
  optimalMatchNode?: RefObject<ReactElement>;
  isBrokerage: boolean;
}

export const ConfirmSearchAssignment = ({ subject, assignment, onConfirm, onCancel, isOptimal, hasOptimal, optimalMatchNode, isBrokerage = false }: ConfirmSearchAssignmentProps) => {
  const { useDispatchingFeedbackForm, odet4508AddModalToExplainPta } = useFlags();
  const [selectedReasons, setSelectedReasons] = useState(hasOptimal ? [] : ['not_actionable']);
  const [explanation, setExplanation] = useState('');

  if (isOptimal || !useDispatchingFeedbackForm) {
    return (
      <ConfirmContainer
        title="Confirm Assignment"
        open={true}
        onAction={(bool) => bool ? onConfirm(isOptimal) : onCancel()}
        source={`search-${subject}`}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
          {isBrokerage && (
            <Typography>
              This load is currently assigned in your TMS as a brokerage load. By assigning this load, it will automatically be changed from brokerage to asset.
            </Typography>
          )}
          <Box>{assignment}</Box>
          {!isBrokerage && (
            <Typography>
              {isOptimal && 'This Optimal Match assignment is the most valuable for your network.'}
              {!isOptimal && `A reminder that manually assigning ${subject}s instead of the Optimal Match will reduce the optimization of your network. Please review assignment before confirming.`}
            </Typography>
          )}
        </Box>
      </ConfirmContainer>
    );
  }

  let collapsedOptimalNode = <></>;
  if (optimalMatchNode?.current) {
    const collapsedAccordions = React.Children.map(
      optimalMatchNode?.current?.props.children,
      (child) => { if (child?.type?.name) return React.cloneElement(child, { defaultCollapse: true }); }
    );
    collapsedOptimalNode = React.cloneElement(optimalMatchNode.current, { children: collapsedAccordions });
  }

  const hiddenCardClasses = ['.detail-card-accordion-select', '.estimate-date', '.dispatching-totals', '.avail-timestamp'];
  if (odet4508AddModalToExplainPta) hiddenCardClasses.push('.more-info');

  return (
    <ODSlideOut
      open={true}
      title="Confirm Assignment"
      onClose={onCancel}
      style={{ zIndex: 1300 }}
      elevation={0}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {assignment}
        <Box
          sx={{
            mt: 2,
            mx: -4,
            py: 2,
            px: 4,
            borderWidth: '1px 0',
            borderStyle: 'solid',
            borderColor: 'level3',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: 'calc(100vh - 270px)',
            overflowY: 'scroll'
          }}
        >
          <Typography>
            <strong>{`You are not assigning the top match provided for this ${subject === DRIVER ? LOAD : DRIVER}. `}</strong>
            Please provide your feedback as to why, to help improve matches in the future.
          </Typography>
          <Box sx={{ [hiddenCardClasses.join(',')]: { display: 'none' } }}>
            <Typography sx={{ color: 'text.secondary', fontSize: '12px' }}>Original Top Match</Typography>
            {collapsedOptimalNode}
          </Box>
          <ODSelectWrapper
            id="feedback-reasons"
            multiple
            label="Select Reasons (Required)"
            menuOptions={Object.entries(REASONS).map(([value, label]) => ({ value, label }))}
            value={selectedReasons}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              // @ts-expect-error TS is convinced that this value will only ever be a string
              setSelectedReasons(event.target.value);
            }}
            sx={{ width: '100%' }}
          />
          {selectedReasons.length > 0 && (
            <SelectedOptions
              keyBase="filter-reason"
              // @ts-expect-error TS objects to using a string as an index key
              value={selectedReasons.map((r) => ({ reason: r, label: REASONS[r] }))}
              itemKey="label"
              label="Reason"
              removeOptionCB={({ reason }: { reason: string }) => {
                setSelectedReasons([...selectedReasons].filter((s) => s !== reason));
              }}
            />
          )}
          <TextField
            id="standard-multiline-static"
            label="Elaborate on why the top match wasn't assigned. (Required)"
            multiline
            rows={4}
            value={explanation}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setExplanation(event.target.value)}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            sx={{
              'label.MuiFormLabel-root.Mui-focused': { color: 'text.secondary' },
              '.MuiInputBase-root': {
                border: '1px solid',
                borderRadius: '4px',
                borderColor: 'level3',
                padding: '10px 16px',
                fontSize: '14px',
                '&.MuiInput-underline:before, &.MuiInput-underline:after': { borderBottom: 'unset !important' },
              }
            }}
          />
        </Box>
        <Box sx={{ py: 1, display: 'flex', columnGap: '16px', flexDirection: 'row', '> button': { width: 'calc(50% - 8px)' } }}>
          <ODButton
            id="search-feedback-cancel-button"
            onClick={onCancel}
          >
            Cancel
          </ODButton>
          <ODButton
            variant="blue"
            id="search-feedback-confirm-button"
            onClick={() => onConfirm(false, selectedReasons, explanation)}
            disabled={selectedReasons.length === 0 || !explanation}
          >
            Confirm
          </ODButton>
        </Box>
      </Box>
    </ODSlideOut>
  );
};
