import React from 'react';
import { Box, Typography } from '@mui/material';
import StarsSharp from '@mui/icons-material/StarsSharp';
import StarSharp from '@mui/icons-material/StarSharp';
import StarHalfSharp from '@mui/icons-material/StarHalfSharp';
import StarOutlineSharp from '@mui/icons-material/StarOutlineSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import SyncAltSharp from '@mui/icons-material/SyncAltSharp';
import EventNoteSharp from '@mui/icons-material/EventNoteSharp';
import StickyNote2Sharp from '@mui/icons-material/StickyNote2Sharp';
import { ODLaneSearchIcon } from '@OptimalDynamics/core-ai-common-ui';
import { TuneOffIcon } from '../../../common/icons';
import { DetailCardTitleProps } from '../types';
import { TMS, LOAD_SEARCH, EVENTS } from '../helpers/constants';

export const DetailCardTitle = React.forwardRef(({
  id,
  rating,
  secondary_rating,
  title,
  source,
  active,
  selected,
  handleClick,
  isTourWithConflict,
  isRelaxed,
  isCurrentRow,
  relativeTime,
  children
}: DetailCardTitleProps, ref) => {
  let constructedTitle = '';
  let icon = <></>;
  if (rating || rating === 0) {
    const parsedRating = parseInt(rating as string, 10);
    if (parsedRating === 5) {
      if (parseInt(secondary_rating as string, 10) > 1) {
        constructedTitle = `Great ${title}`;
        icon = <StarSharp />;
      } else {
        constructedTitle = `Optimal ${title}`;
        icon = <StarsSharp sx={{ color: 'optimalblue.main' }} />;
      }
    } else if (parsedRating >= 3) {
      constructedTitle = `Good ${title}`;
      icon = <StarHalfSharp />;
    } else {
      constructedTitle = `Feasible ${title}`;
      icon = <StarOutlineSharp />;
    }
  } else if (source === EVENTS) {
    constructedTitle = 'Scheduled Event';
    icon = <EventNoteSharp />;
  } else {
    constructedTitle = `From ${source}`;
    if (source === TMS) icon = <SyncAltSharp />;
    if (source === LOAD_SEARCH) icon = <ODLaneSearchIcon />;
  }
  if (isTourWithConflict) icon = <InfoOutlined />;
  if (isCurrentRow) {
    constructedTitle = `Selected ${title} Details`;
    icon = <StickyNote2Sharp />;
  }
  if (isRelaxed) {
    constructedTitle = 'Exception Match';
    icon = <TuneOffIcon />;
  }

  const headerColor = () => {
    if (isTourWithConflict) return 'warning.main';

    if (!active && selected) return 'colors.neutral1';
    if (!active) return 'colors.neutral0';
    if (selected) return 'colors.lightBlue1';
    return 'colors.white';
  };

  return (
    <Box
      id={id}
      ref={ref}
      sx={{
        border: '1px solid',
        borderColor: 'level3',
        borderRadius: '4px',
        scrollMargin: '16px 0 16px 0',
        backgroundColor: active ? 'background.default' : 'colors.neutral0',
        color: 'text.primary'
      }}
    >
      <Box
        className="detail-card-title-bar"
        onClick={handleClick}
        sx={{
          backgroundColor: headerColor(),
          borderRadius: '4px 4px 0 0',
          color: 'text.primary',
          height: 40,
          padding: '0px 16px',
          display: 'flex',
          alignItems: 'center',
          columnGap: '16px',
          fontWeight: 'bold',
          ...!!handleClick && { cursor: 'pointer' }
        }}
      >
        <Box sx={{ height: '32px', width: '32px', '> svg': { margin: '7px' } }}>{icon}</Box>
        <Typography sx={{ flexGrow: '1' }}><strong>{constructedTitle}</strong></Typography>
        {!!relativeTime && relativeTime}
      </Box>
      {children}
    </Box>
  );
});
