/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Typography, Link } from '@mui/material';
import AlarmOnSharp from '@mui/icons-material/AlarmOnSharp';
import PlaceSharp from '@mui/icons-material/PlaceSharp';
import HomeSharp from '@mui/icons-material/HomeSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import PersonSharp from '@mui/icons-material/PersonSharp';
import PinDropSharp from '@mui/icons-material/PinDropSharp';
import { ODTooltip } from '@OptimalDynamics/core-ai-common-ui';
import {
  EstimatedTimeProps,
  MileageProps,
  EventRowProps,
  HomeRowProps,
  StopRowProps,
  DetailCardTimelineProps,
  LocationRowProps,
  AvailableTimeRowProps,
  HoldRowProps,
} from '../types';
import { HOSIcon } from '../../../common/icons';
import {
  getLocalizedTimeRange,
  DEFAULT_TIMEZONE,
  SHORT_DATE_TIME,
  SHORT_DATE_TIME_TZ,
  SHORT_DATE_TIME_AT_DIV,
  DATE_TIME_WITH_SEC_SPACE_DIV,
  DATE_TIME_WITH_SEC_T_DIV
} from '../../../utils/datetimes';

const ESTIMATE_TYPE_TEXTS = {
  ETA: 'Estimated Time of Arrival',
  ETD: 'Estimated Time of Departure',
  ELT: 'Estimated Load Start & End Time',
  EULT: 'Estimated Unload Start & End Time'
};

export const generateTimestamp = (time = '', timezone = DEFAULT_TIMEZONE) => {
  if (!time) return '';
  const tzToUse = timezone ?? DEFAULT_TIMEZONE;

  const timeslot = moment.tz(time, [SHORT_DATE_TIME, DATE_TIME_WITH_SEC_SPACE_DIV], DEFAULT_TIMEZONE).tz(tzToUse);
  return timeslot.format(SHORT_DATE_TIME_TZ);
};

export const trimHos = (hos: string) => {
  if (hos?.startsWith('(')) {
    // HOS format is "(R) 18h 4m" or "(8D) 5h 23m" so I'm chopping off the parenthetical and its trailing space.
    // Checking that it is prefixed with ( jic because if the parenthetical isn't present no need to remove it.
    // @ts-expect-error this is not an error I check that it's not undefined in the if above
    return hos.split(')').at(-1).trimStart();
  }
};

const SharedTimeLocationRow = ({ location, time }: { location?: string, time?: string | null }) => {
  const { theUiBlueItself } = useFlags();

  if (!theUiBlueItself || !location || !time) {
    return (
      <>
        {location && <Typography>{location}</Typography>}
        {time && <Typography>{time}</Typography>}
      </>
    );
  }

  return (
    <Typography>{`${location}: ${time}`}</Typography>
  );
};

const getForegroundColor = (selected: boolean) => selected ? 'colors.semanticBlue' : 'colors.neutral4';

export const Mileage = ({ mileage = '0' }: MileageProps) => {
  const miles = parseFloat(mileage).toFixed(0);

  return (
    <Box sx={{ paddingLeft: '48px' }}>
      <Typography sx={{ color: 'text.secondary' }}>
        {mileage === 'TBD' ? mileage : `${miles} mi`}
      </Typography>
    </Box>
  );
};

export const EventRow = ({ icon, color, hos, openHosDialog, openExplainPtaDialog, children }: EventRowProps) => {
  const { odet4508AddModalToExplainPta } = useFlags();
  const showExplainPta = odet4508AddModalToExplainPta && openExplainPtaDialog;
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '32px auto', gridTemplateRows: 'auto', columnGap: '16px', '> div:not(:first-of-type)': { py: 0.5 } }}>
      <Box sx={{ gridColumnStart: 1, backgroundColor: color, borderRadius: '4px' }}>
        <Box sx={{ height: '32px', width: '32px', '& svg': { margin: '7px', color: 'text.inverse' } }}>
          {icon}
        </Box>
      </Box>
      <Box sx={{ gridColumnStart: 2 }}>
        {children}
      </Box>
      {(hos || showExplainPta) && (
        <Box
          sx={{
            gridColumnStart: 3,
            justifySelf: 'end',
            alignSelf: 'end',
            marginBottom: '1px',
            display: 'flex',
            alignItems: 'center',
            columnGap: '8px',
            '& svg': { color: 'text.secondary' }
          }}
        >
          {showExplainPta && (
            <Box className="more-info" sx={{ display: 'flex', columnGap: 1 }}>
              <AlarmOnSharp />
              <Link component="button" onClick={openExplainPtaDialog} sx={{ color: 'text.secondary', textDecoration: 'underline' }}>
                More Info
              </Link>
              {hos && <Box sx={{ borderRight: '1px solid', borderRightColor: 'level3', height: '18px', pr: 1, mr: 1 }} />}
            </Box>
          )}
          {hos && (
            <>
              <HOSIcon />
              <Link component="button" onClick={openHosDialog} sx={{ color: 'text.secondary', textDecoration: 'underline' }}>
                {hos}
              </Link>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

const EstimatedTime = ({ estimates, selected }: EstimatedTimeProps) => {
  const types = Object.keys(estimates);

  // @ts-expect-error TS objects to using a string as an index key
  const tooltipContent = types.length === 1 ? ESTIMATE_TYPE_TEXTS[types[0]]
    : (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* @ts-expect-error TS objects to using a string as an index key */}
        {types.map((type) => (<span>{`${type} = ${ESTIMATE_TYPE_TEXTS[type]}`}</span>))}
      </Box>
    );

  return (
    <Box sx={{ marginTop: '8px', color: getForegroundColor(selected), display: 'flex', columnGap: '8px', alignItems: 'center' }}>
      <ODTooltip title={tooltipContent}>
        <InfoOutlined />
      </ODTooltip>
      <Box>
        <Typography>
          {Object.entries(estimates)?.map(([type, estimate], i) => {
            const [date, ...time] = estimate.split(' ');

            return (
              <>
                <strong>{`${type}: `}</strong>
                <span className="estimate-date">
                  {date}
                  &nbsp;
                </span>
                {time.join(' ')}
                {i !== (types.length - 1) && ` | `}
              </>
            );
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export const AvailableTimeRow = ({ available, timezone, selected, location, hos, openHosDialog, openExplainPtaDialog, hasPrerequisite = false }: AvailableTimeRowProps) => {
  const tzToUse = timezone ?? DEFAULT_TIMEZONE;
  const availableTime = moment.tz(available, SHORT_DATE_TIME_AT_DIV, DEFAULT_TIMEZONE).tz(tzToUse);

  hos = !!hos ? trimHos(hos) : hos;

  return (
    <EventRow
      icon={<AlarmOnSharp sx={{ fill: hasPrerequisite ? 'black' : 'white' }} />}
      color={hasPrerequisite ? 'warning.main' : selected ? 'success.main' : 'colors.neutral4'}
      hos={hos}
      openHosDialog={openHosDialog}
      openExplainPtaDialog={openExplainPtaDialog}
    >
      <Typography><strong>Available For Dispatch</strong></Typography>
      <Typography>
        {`${availableTime.isSameOrBefore() ? 'Now, ' : ''}${location}`}
        <span className="avail-timestamp">{`: ${availableTime.format(SHORT_DATE_TIME_TZ)}`}</span>
      </Typography>
    </EventRow>
  );
};

export const LocationRow = ({
  label, start, end, estimates, timezone, location, mileage, selected, hos, openHosDialog, icon = <PlaceSharp />, hideAppointment = false
}: LocationRowProps) => {
  let timeWindow = '';
  if (!hideAppointment) timeWindow = getLocalizedTimeRange(start, end, timezone, [DATE_TIME_WITH_SEC_T_DIV, SHORT_DATE_TIME]);

  return (
    <>
      {mileage && <Mileage mileage={mileage} />}
      <EventRow icon={icon} color={getForegroundColor(selected)} hos={hos} openHosDialog={openHosDialog}>
        <Typography><strong>{label}</strong></Typography>
        <SharedTimeLocationRow time={timeWindow} location={location} />
        {(!hideAppointment && timeWindow === '') && <Typography sx={{ color: 'text.secondary' }}><em>No Appointment Window</em></Typography>}
        {(estimates) && <EstimatedTime estimates={estimates} selected={selected} />}
      </EventRow>
    </>
  );
};

export const StopRow = ({ estimate, timezone, location, mileage, selected }: StopRowProps) => {
  const formattedEstimate = generateTimestamp(estimate, timezone);

  return (
    <>
      <Mileage mileage={mileage} />
      <EventRow icon={<PinDropSharp />} color={getForegroundColor(selected)}>
        <Typography><strong>Stop</strong></Typography>
        <Typography>{location}</Typography>
        {formattedEstimate && <EstimatedTime estimates={{ ETD: formattedEstimate }} selected={selected} />}
      </EventRow>
    </>
  );
};

export const HoldRow = ({ start, end, timezone, location, selected }: HoldRowProps) => {
  const timeWindow = getLocalizedTimeRange(start, end, timezone, SHORT_DATE_TIME_AT_DIV);

  return (
    <EventRow icon={<PersonSharp />} color={getForegroundColor(selected)}>
      <Typography><strong>Driver Location</strong></Typography>
      <SharedTimeLocationRow time={timeWindow} location={location} />
    </EventRow>
  );
};

export const HomeRow = ({ label, time, timezone, location, mileage, selected, timeWindow }: HomeRowProps) => {
  const formattedTime = generateTimestamp(time, timezone);

  return (
    <>
      {mileage && <Mileage mileage={mileage} />}
      <EventRow icon={<HomeSharp />} color={selected ? 'colors.odBlue5' : 'colors.neutral6'}>
        <Typography><strong>{label}</strong></Typography>
        <SharedTimeLocationRow time={timeWindow} location={location} />
        {formattedTime && <EstimatedTime estimates={{ ETA: formattedTime }} selected={selected} />}
      </EventRow>
    </>
  );
};

export const DetailCardTimeline = ({ selected, children }: DetailCardTimelineProps) => (
  <Box sx={{ display: 'grid' }}>
    <Box
      sx={{
        gridColumn: 1,
        gridRow: 1,
        backgroundColor: selected ? 'colors.lightBlue2' : 'colors.neutral1',
        width: '32px',
        borderRadius: '4px'
      }}
    />
    <Box sx={{ gridColumn: 1, gridRow: 1, display: 'flex', flexDirection: 'column', rowGap: '8px' }}>
      {children}
    </Box>
  </Box>
);
