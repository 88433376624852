import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AlarmOnSharp from '@mui/icons-material/AlarmOnSharp';
import CheckSharp from '@mui/icons-material/CheckSharp';
import CompareArrowsSharp from '@mui/icons-material/CompareArrowsSharp';
import HomeSharp from '@mui/icons-material/HomeSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import PanToolSharp from '@mui/icons-material/PanToolSharp';
import PersonAddAlt1Sharp from '@mui/icons-material/PersonAddAlt1Sharp';
import PriorityHighSharp from '@mui/icons-material/PriorityHighSharp';
import StarHalfSharp from '@mui/icons-material/StarHalfSharp';
import StarOutlineSharp from '@mui/icons-material/StarOutlineSharp';
import StarSharp from '@mui/icons-material/StarSharp';
import ArrowBackSharp from '@mui/icons-material/ArrowBackSharp';
import ArrowForwardSharp from '@mui/icons-material/ArrowForwardSharp';
import { Box, styled, Typography } from '@mui/material';
import { ODAvatar, ODCloseButton, ODLaneIcon } from '@OptimalDynamics/core-ai-common-ui';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TopLoadsIcon } from '../../common/icons';
import StarIconInfoDialog from './shared/StarIconInfoDialog';
import {
  BASIC_TIME,
  SHORT_DATE,
  SHORT_DATE_TIME,
  SHORT_DATE_TIME_AT_DIV,
  DATE_TIME_T_DIV_TZ,
  DATE_TIME_WITH_SEC_T_DIV
} from '../../utils/datetimes';
import { BROKERAGE, DRIVER_HOLD, EMPTY_TO_HOME, LOADED } from './helpers/constants';

const SummaryBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'width' && prop !== 'left' && prop !== 'hasPrerequisite' && prop !== 'assigned' && prop !== 'disabled' && prop !== 'event' && prop !== 'isBrokerage' && prop !== 'tmsNotReady'
})(({ theme, selected, width, left, hasPrerequisite = false, assigned, disabled, isBrokerage, tmsNotReady, event = false }) => ({
  height: 18,
  backgroundColor: theme.palette.disabled,
  '&.MuiBox-root': {
    '& .MuiSvgIcon-root': {
      color: theme.palette.colors.white,
      height: 12,
      width: 12,
      ...(hasPrerequisite && !assigned && selected && { color: theme.palette.colors.black }),
      ...(assigned && selected && { color: theme.palette.colors.black }),
      ...(disabled && { color: theme.palette.text.disabled }),
      ...(selected && tmsNotReady && { color: theme.palette.colors.black }),
      ...(selected && isBrokerage && { color: theme.palette.colors.black }),
    },
    ...(selected && { backgroundColor: theme.palette.optimalblue.main }),
    ...(hasPrerequisite && !assigned && selected && { backgroundColor: theme.palette.colors.yellow }),
    ...(assigned && selected && { backgroundColor: theme.palette.success.main }),
    ...(event && { backgroundColor: theme.palette.colors.neutral6 }),
    ...(event && selected && { backgroundColor: theme.palette.colors.odBlue5 }),
    ...(disabled && { backgroundColor: theme.palette.level3 }),
    ...(selected && isBrokerage && { backgroundColor: theme.palette.warning.main }),
    ...(selected && tmsNotReady && { backgroundColor: theme.palette.warning.main }),
  },
  width,
  display: 'flex',
  alignItems: 'center', 
  justifyContent: 'center',
  left,
  borderRadius: '2px',
  minWidth: 18,
  cursor: 'pointer',
  position: 'absolute',
}));

export const DispatchingSummaryTimeline = ({ topOptions, lastSynced, selectedOption, currentRow, onClick, isDriver = false }) => {
  const planningHorizon = useSelector((state) => state.dispatchingReducer?.planningHorizon);
  const [starInfoExpanded, setStarInfoExpanded] = useState(false);
  const { orgUse2WayDispatching } = useFlags();

  const getStarIcon = (rating) => {
    if (parseFloat(rating) < 3) {
      return <StarOutlineSharp sx={{ color: 'text.disabled', '&.MuiSvgIcon-root': { height: '16px', width: '16px' } }} />;
    }
    if (parseFloat(rating) === 5) {
      return <StarSharp sx={{ color: 'text.disabled', '&.MuiSvgIcon-root': { height: '16px', width: '16px' } }} />;
    }
    return <StarHalfSharp sx={{ color: 'text.disabled', '&.MuiSvgIcon-root': { height: '16px', width: '16px' } }} />;
  };

  const timeDiff = (start, end, startPattern, endPattern) => moment(end, endPattern).diff(moment(start, startPattern), 'minutes');
  const visibleMinutes = planningHorizon * 60; // total number of minutes in summary window
  const visiblePixels = 402; // width in pixels of summary window
  const starRatingOffset = 20; // offset of element due to width of star rating

  const summaryRowContent = (movement, selected, disabled) => {
    const getLoadBar = (startTime, endTime, startFormat = DATE_TIME_WITH_SEC_T_DIV, endFormat = DATE_TIME_WITH_SEC_T_DIV) => {
      const timeSpan = timeDiff(startTime, endTime ?? startTime, startFormat, endFormat) || 0;
      const width = timeSpan ? (visiblePixels * timeSpan / visibleMinutes) : 20;
      const minutesUntilEvent = timeDiff(lastSynced, startTime, DATE_TIME_T_DIV_TZ, startFormat);
      const left = timeSpan ? (visiblePixels * minutesUntilEvent / visibleMinutes) + starRatingOffset + 20 : starRatingOffset + 20;

      return (
        <SummaryBar
          data-testid="loaded-summary-bar"
          sx={{ zIndex: 3 }}
          disabled={disabled}
          hasPrerequisite={movement.has_prerequisite}
          assigned={!!movement.assigned_driver_id}
          selected={selected}
          width={width}
          left={left}
          isBrokerage={orgUse2WayDispatching && movement.internal_source === BROKERAGE}
          tmsNotReady={orgUse2WayDispatching && movement.tms_ready === false}
          key={movement.movement_option_id}
        >
          {(() => {
            if (!!movement.assigned_driver_id) return <CheckSharp />;
            if (orgUse2WayDispatching && (!movement.assigned_driver_id && movement.has_prerequisite) || movement.internal_source === BROKERAGE) return <InfoOutlined />;
            if (orgUse2WayDispatching && movement.tms_ready === false) return <PriorityHighSharp />;
            return <ODLaneIcon />;
          })()}
        </SummaryBar>
      );
    };

    const getDriverHold = (availableDateTime) => {
      const width = 18;
      const startTimeToUse = availableDateTime === 'Now' ? lastSynced : availableDateTime;
      const minutesUntilEvent = timeDiff(lastSynced, startTimeToUse, DATE_TIME_T_DIV_TZ, SHORT_DATE_TIME_AT_DIV);
      const left = availableDateTime === 'Now' ? starRatingOffset + 18 : (visiblePixels * minutesUntilEvent / visibleMinutes) + starRatingOffset + 18;
  
      return (
        <SummaryBar data-testid="hold-summary-bar" sx={{ zIndex: 2 }} selected={selected} width={width} left={left}>
          <PanToolSharp sx={{ color: 'colors.white', '&.MuiSvgIcon-root': { height: 12, width: 12 } }} />
        </SummaryBar>
      );
    };

    const getHomeTime = (startTime, startFormat, availableForDispatch) => {
      const startTimeToUse = startTime === 'Now' ? lastSynced : startTime;
      const endTimeToUse = availableForDispatch;
      const timeSpan = endTimeToUse ? timeDiff(startTimeToUse, endTimeToUse, startTime === 'Now' ? DATE_TIME_T_DIV_TZ : startFormat) : 0;
      const width = (visiblePixels * timeSpan / visibleMinutes) || 18;
      const minutesUntilEvent = timeDiff(lastSynced, startTime, DATE_TIME_T_DIV_TZ, startTime === 'Now' ? DATE_TIME_T_DIV_TZ : startFormat);
      const left = startTime === 'Now' ? starRatingOffset : (visiblePixels * minutesUntilEvent / visibleMinutes) + starRatingOffset;
  
      return (
        <SummaryBar data-testid="home-time-summary-bar" sx={{ zIndex: 1 }} event selected={selected} width={width} left={left}>
          <HomeSharp sx={{ color: 'colors.white', '&.MuiSvgIcon-root': { height: 12, width: 12 } }} />
        </SummaryBar>
      );
    };

    return (
      <>
        {movement?.movement_type === DRIVER_HOLD && getDriverHold(currentRow?.avail_for_dispatch_et ?? movement?.avail_for_dispatch_et)}
        {movement?.movement_type === LOADED && (movement?.estimated_pickup_times
          ? getLoadBar(movement?.estimated_pickup_times, movement?.est_dropoff_time, movement?.movement_option_id === selectedOption?.movement_option_id)
          : getLoadBar(movement?.est_pickup_time, movement?.est_dropoff_time, SHORT_DATE_TIME, SHORT_DATE_TIME))}
        {(!!currentRow?.pto_et_detail || !!movement?.pto_et_detail)
          && getHomeTime(currentRow?.pto_et_detail ?? movement?.pto_et_detail, SHORT_DATE_TIME_AT_DIV, currentRow?.avail_for_dispatch_et ?? movement?.avail_for_dispatch_et)}
        {movement?.movement_type === EMPTY_TO_HOME && getHomeTime(movement.est_dropoff_time, DATE_TIME_WITH_SEC_T_DIV, null)}
      </>
    );
  };

  const getTimeIntervals = () => {
    const scale = planningHorizon > 60 ? 12 : 6;
    const intervals = [];
    for (let i = 0; i < planningHorizon + scale; i += scale) {
      intervals.push(
        <Box key={`time-label-${i}`} sx={{ fontSize: 10, color: 'text.disabled', position: 'relative' }}>
          {moment(lastSynced, DATE_TIME_T_DIV_TZ).add(i, 'h').format(BASIC_TIME)}
        </Box>
      );
    }
    return intervals;
  };

  const getDateIntervals = (rowCount) => {
    const intervals = [];
    const nowMoment = moment(lastSynced, DATE_TIME_T_DIV_TZ);
    const endMoment = nowMoment.clone().add(planningHorizon, 'hours');

    const numberOfDates = endMoment.clone().startOf('day').diff(nowMoment.clone().startOf('day'), 'days');
    Array.from(Array(numberOfDates || 1)).forEach((item, index) => intervals.push(nowMoment.clone().add(index + 1, 'days').startOf('day')));

    const getDatePosition = (date) => {
      const minutesUntilEvent = timeDiff(lastSynced, date, DATE_TIME_T_DIV_TZ);
      return (
        <Box key={`${minutesUntilEvent}-date-label`} sx={{ position: 'absolute', fontSize: 10, color: 'text.disabled', left: (visiblePixels * minutesUntilEvent / visibleMinutes) }}>
          <Box sx={{ borderLeft: '1px solid #000000', opacity: '5%', position: 'absolute', left: '50%', bottom: 15, height: `${rowCount * 20}px`, zIndex: 3 }} />
          {date.format(SHORT_DATE)}
        </Box>
      );
    };

    return (
      <Box sx={{ position: 'relative' }}>
        {intervals.map((date) => getDatePosition(date))}
      </Box>
    );
  };

  const getAvailableForDispatch = (startTime, startFormat, selected) => {
    const width = 18;
    const startTimeToUse = startTime === 'Now' ? lastSynced : startTime;
    const minutesUntilEvent = timeDiff(lastSynced, startTimeToUse, DATE_TIME_T_DIV_TZ, startFormat);
    const left = startTime === 'Now' ? 0 : (visiblePixels * minutesUntilEvent / visibleMinutes);

    return (
      <Box sx={{ width: '100%', height: 18, left, backgroundColor: 'search', ...selected && { backgroundColor: 'colors.lightBlue2' }, display: 'flex', position: 'relative' }}>
        <SummaryBar data-testid="available-summary-bar" sx={{ zIndex: 2 }} selected={selected} width={width} left="-18px">
          <AlarmOnSharp sx={{ color: 'colors.white', '&.MuiSvgIcon-root': { height: 12, width: 12 } }} />
        </SummaryBar>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ height: 40, backgroundColor: 'search', display: 'flex', alignItems: 'center', columnGap: '16px', borderBottom: 1, borderColor: 'level2', padding: '0px 16px' }}>
        {isDriver ? <PersonAddAlt1Sharp /> : <TopLoadsIcon />}
        <Typography fontWeight={600} sx={{ flex: 1 }}>{isDriver ? 'Driver Match Summary' : 'Dispatch Match Summary'}</Typography>
        <ODAvatar variant="transparent" size="small" onClick={() => setStarInfoExpanded(true)}>
          <InfoOutlined />
        </ODAvatar>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ ml: 5, mb: 0.25, mt: 1, display: 'flex', width: 382, justifyContent: 'space-between' }}>
          {getTimeIntervals()}
        </Box>
        <Box sx={{ border: 1, borderColor: 'level2' }}>
          {topOptions?.map((option, index) => {
            const sampleOption = option[0];
            let selected = false;
            if (selectedOption?.length > 0) {
              selected = isDriver
                ? (selectedOption[0].driver_id === sampleOption.driver_id)
                : (selectedOption[0].load_id === sampleOption?.load_id && selectedOption[0].movement_type === sampleOption?.movement_type);
            }

            const checkOptionDisabled = (selOption) => orgUse2WayDispatching &&
              ((selOption.movement_type === DRIVER_HOLD)
              || (selOption.internal_source === BROKERAGE)
              || (selOption.movement_type === LOADED && !selOption.tms_ready));

            return (
              <Box
                key={option?.[0]?.movement_option_id}
                sx={{
                  height: 19,
                  width: 424,
                  display: 'flex',
                  alignItems: 'center',
                  overflowX: 'hidden',
                  position: 'relative',
                  '&:not(:last-of-type)': {
                    borderBottom: 1,
                    borderColor: 'level2',
                  },
                }}
                onClick={() => onClick(option, index, sampleOption.active)}
              >
                <Box sx={{ width: 38, display: 'flex', justifyContent: 'left', alignItems: 'center', backgroundColor: 'white' }}>
                  <Box sx={{ height: 19, width: 19, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {getStarIcon(option?.[0]?.overall_rating)}
                  </Box>
                </Box>
                <Box sx={{ height: 19, width: 'calc(100% - 38px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {getAvailableForDispatch(option?.[0]?.avail_for_dispatch_et ?? currentRow?.avail_for_dispatch_et, SHORT_DATE_TIME_AT_DIV, selected)}
                  {option?.map((movement) => {
                    const optionSelected = selectedOption?.some((opt) => opt.movement_option_id === movement.movement_option_id);
                    const optionDisabled = option?.some((move) => checkOptionDisabled(move));
                    return summaryRowContent(movement, optionSelected, optionDisabled);
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ ml: 5, mb: 1, mt: 0.25, height: 10, display: 'flex', width: 382, justifyContent: 'space-between', overflowX: 'clip' }}>
          {getDateIntervals(topOptions?.length)}
        </Box>
      </Box>
      <StarIconInfoDialog isDriver={isDriver} open={starInfoExpanded} onClose={() => setStarInfoExpanded(false)} />
    </>
  );
};

export const DispatchingSummaryTitle = ({ onClose, onClickPrev, onClickNext, isDriver = false }) => {
  const [searchParams] = useSearchParams();
  const [starInfoExpanded, setStarInfoExpanded] = useState(false);
  const slideoutViewType = searchParams.get('slideout_view_type');
  const { showRelaxedMatches } = useFlags();

  return (
    <>
      <Box sx={{ height: '40px', mx: 2, my: 1, display: 'grid', columnGap: '16px', gridTemplateColumns: '32px 1fr auto', alignItems: 'center' }}>
        {showRelaxedMatches ? (
          <Box sx={{ height: '32px', width: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CompareArrowsSharp />
          </Box>
        ) : (
          <ODAvatar variant="transparent" onClick={() => setStarInfoExpanded(true)}>
            <InfoOutlined />
          </ODAvatar>
        )}
        <Typography sx={{ width: 'calc(110% - 112px)' }}>
          <strong>
            {slideoutViewType?.match(/plan/gm) ? 'Driver Plan' : 'Dispatch Match Details'}
          </strong>
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', columnGap: '2px', borderRight: '1px solid', borderColor: 'level3', mr: 2, pr: 2 }}>
            {onClickPrev && <ODAvatar variant="transparent" onClick={onClickPrev}><ArrowBackSharp /></ODAvatar>}
            {onClickNext && <ODAvatar variant="transparent" onClick={onClickNext}><ArrowForwardSharp /></ODAvatar>}
          </Box>
          <ODCloseButton onClick={onClose} />
        </Box>
      </Box>
      {!showRelaxedMatches && (
        <StarIconInfoDialog isDriver={isDriver} open={starInfoExpanded} onClose={() => setStarInfoExpanded(false)} />
      )}
    </>
  );
};
