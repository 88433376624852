import instance from '../../utils/axios_instance';
import { checkForNewData, pollDispatchingUpdate } from '../actions/dispatchingAction';
import * as types from '../constants/actionTypes';

const dispatchingWare = (store) => (next) => (action) => {
  const result = next(action);
  switch (action.type) {
    case types.POLLING_UPDATE:
      instance
        .get('/dispatching/data-updated/')
        .then((res) => store.dispatch(checkForNewData(res.data.result.latest_run_updated, res.data.result.task_id)));
      break;
    case types.SET_DISPATCHING_ENGINE_RUN_ID: {
      const { engineRunId } = action;
      const prevEngineRunId = store.getState().dispatchingReducer.engineRunId;

      if (engineRunId !== prevEngineRunId) {
        store.dispatch(pollDispatchingUpdate());
      }

      return result;
    }
    default:
      return result;
  }
};

export default dispatchingWare;
