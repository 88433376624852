import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Info from '@mui/icons-material/Info';
import { ODDialog } from '@OptimalDynamics/core-ai-common-ui/';
import { getLocalizedTime } from '../../../utils/datetimes';
import SlideoutLineItem from '../../../common/SlideoutLineItem';
import { HOSDialogProps } from '../types';

const HOSDialog = ({
  open,
  handleClose,
  row,
  sx = {},
  forecastText = 'Clock values are forecasted out to when the driver is available for dispatch at',
}: HOSDialogProps) => {
  if (!row) return null;

  const getMinHosFigure = (status: string) => status?.split(/(\(8D\) )|(\(D\) )|\(R\) /).pop();

  return (
    <ODDialog
      open={open}
      title="HOS Clock"
      onClose={handleClose}
      sx={{ zIndex: '1500 !important', ...sx }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          height="40px"
          sx={{
            backgroundColor: 'accent',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            p: '0 16px',
            marginBottom: '16px'
          }}
        >
          <Typography fontWeight={600}>
            Available Hours:
            &nbsp;
          </Typography>
          <Typography>{getMinHosFigure(row?.hos_status)}</Typography>
        </Grid>
        <Box sx={{ width: '100%' }}>
          <SlideoutLineItem title="11 Hour Drive:" content={row?.road_mins_remain_end || '-'} />
          <SlideoutLineItem title="14 Hour Shift:" content={row?.duty_mins_remain_end || '-'} />
          <SlideoutLineItem title="70 Hour Cycle:" content={row?.eight_days_mins_remain_end || '-'} />
        </Box>
        <Grid
          item
          xs={12}
          sx={{
            borderTopStyle: 1,
            borderTopColor: 'level2',
            pt: '16px',
            display: 'flex',
            color: 'text.unselected'
          }}
        >
          <Box>
            <Info sx={{ mr: '8px' }} />
          </Box>
          <Typography>
            {`${forecastText} ${getLocalizedTime(row?.avail_for_dispatch_et, row.predispatch_timezone || row.avail_timezone)}.`}
          </Typography>
        </Grid>
      </Grid>
    </ODDialog>
  );
};

export default HOSDialog;
