import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { ODLoader } from '@OptimalDynamics/core-ai-common-ui';
import ProtectedRoute from './auth/protectedRoute';
import PageNotFound from './components/404/page_not_found';
import DispatchingTableView from './components/dispatching/DispatchingTableView';
import Layout from './layouts/Layout';
import { LoadAcceptanceCtxWrapper, SettingsContextWrapper } from './utils/context';
import { DefaultContextWrapper, DispatchingCtxWrapper, TacticalProcurementCtxWrapper } from './utils/context/compositeWrappersCtx';
import Login from './components/login/login';

const LoadAcceptance = lazy(() => import('./components/loadAcceptance/LoadAcceptance'));
const Dispatching = lazy(() => import('./components/dispatching/Dispatching'));
const TacticalProcurement = lazy(() => import('./components/tacticalProcurement/TacticalProcurement'));
const LoadAcceptanceDetailsView = lazy(() => import('./components/loadAcceptance/LoadAcceptanceDetailView'));
const CommitmentManager = lazy(() => import('./components/contracts/commitmentManager-view'));
const LoadDemandForecast = lazy(() => import('./components/forecasting/LoadDemandForecast'));
const TrailerAssignments = lazy(() => import('./components/trailerAssignments/TrailerAssignments'));
const TrailerAssignmentsViewMatches = lazy(() => import('./components/trailerAssignments/TAViewMatches/TrailerAssignmentsViewMatches'));

const ExecuteRoutes = ({ errorMessages, validOrg }) => {
  const {
    showLaUiPage, 
    showDispatchingUiPage, 
    showCommitmentsUiPage, 
    showTpUiPage, 
    showForecastingUiPage, 
    showTrailerAssignmentsUiPage,
  } = useFlags();
  const launchDarklyUserIdentified = useSelector((state) => state.auth.launchDarklyUserIdentified);

  const getDefaultPage = () => {
    switch (true) {
      case showLaUiPage:
        return <Navigate to="/load-acceptance" replace={true} />;
      case showDispatchingUiPage:
        return <Navigate to="/dispatching" replace={true} />;
      case showTpUiPage:
        return <Navigate to="/tactical-procurement" replace={true} />;
      case showForecastingUiPage:
        return <Navigate to="/load-demand-forecast" replace={true} />;
      case showTrailerAssignmentsUiPage:
        return <Navigate to="/trailer-assignments" replace={true} />;
      case showCommitmentsUiPage:
        return <Navigate to="/commitment-manager" replace={true} />;
      default:
        return <Navigate to="/load-acceptance" replace={true} />;
    }
  };

  return (
    <Routes>
      <Route path="/login" element={<Login errorMessages={errorMessages} validOrg={validOrg} />} />
      <Route 
        element={(
          <SettingsContextWrapper>
            <Layout />
          </SettingsContextWrapper>
        )}
      >
        {launchDarklyUserIdentified ? (
          <Route
            path="/"
            element={getDefaultPage()}
          />
        ) : (
          <Route
            path="/"
            element={<ODLoader open />}
          />
        )}
        {showLaUiPage && (
          <>
            <Route
              path="/load-acceptance"
              element={(
                <ProtectedRoute>
                  <Suspense fallback={<ODLoader open />}>
                    <LoadAcceptanceCtxWrapper>
                      <LoadAcceptance />
                    </LoadAcceptanceCtxWrapper>
                  </Suspense>
                </ProtectedRoute>
              )}
            >
              <Route path=":loadId" />
            </Route>
            <Route
              path="/load-acceptance-detail"
              element={(
                <ProtectedRoute>
                  <Suspense fallback={<ODLoader open />}>
                    <LoadAcceptanceCtxWrapper>
                      <LoadAcceptanceDetailsView />
                    </LoadAcceptanceCtxWrapper>
                  </Suspense>
                </ProtectedRoute>
              )}
            />
          </>
        )}
        {showDispatchingUiPage && (
          <Route
            path="/dispatching"
            element={(
              <ProtectedRoute>
                <Suspense fallback={<ODLoader open />}>
                  <DispatchingCtxWrapper>
                    <Dispatching />
                  </DispatchingCtxWrapper>
                </Suspense>
              </ProtectedRoute>
            )}
          >
            <Route
              path=""
              element={<Navigate to="by-driver" replace />}
            />
            <Route
              path="by-driver"
              element={<DispatchingTableView />}
            >
              <Route path=":driverId" />
            </Route>
            <Route
              path="by-load"
              element={<DispatchingTableView />}
            >
              <Route path=":loadId" />
            </Route>
          </Route>
        )}
        {showCommitmentsUiPage && (
          <Route
            path="/commitment-manager"
            element={(
              <ProtectedRoute>
                <Suspense fallback={<ODLoader open />}>
                  <DefaultContextWrapper>
                    <CommitmentManager />
                  </DefaultContextWrapper>
                </Suspense>
              </ProtectedRoute>
            )}
          />
        )}
        {showTpUiPage && (
          <Route
            path="/tactical-procurement"
            element={(
              <ProtectedRoute>
                <Suspense fallback={<ODLoader open />}>
                  <TacticalProcurementCtxWrapper>
                    <TacticalProcurement />
                  </TacticalProcurementCtxWrapper>
                </Suspense>
              </ProtectedRoute>
            )}
          />
        )}
        {showForecastingUiPage && (
          <Route
            path="/load-demand-forecast"
            element={(
              <ProtectedRoute>
                <Suspense fallback={<ODLoader open />}>
                  <DefaultContextWrapper>
                    <LoadDemandForecast />
                  </DefaultContextWrapper>
                </Suspense>
              </ProtectedRoute>
            )}
          />
        )}
        {showTrailerAssignmentsUiPage && (
          <>
            <Route
              path="/trailer-assignments"
              element={(
                <ProtectedRoute>
                  <Suspense fallback={<ODLoader open />}>
                    <DefaultContextWrapper>
                      <TrailerAssignments />
                    </DefaultContextWrapper>
                  </Suspense>
                </ProtectedRoute>
              )}
            >
              <Route path=":loadId" />
            </Route>
            <Route
              path="/trailer-assignments-detail/:loadId/"
              element={(
                <ProtectedRoute>
                  <Suspense fallback={<ODLoader open />}>
                    <DefaultContextWrapper>
                      <TrailerAssignmentsViewMatches />
                    </DefaultContextWrapper>
                  </Suspense>
                </ProtectedRoute>
              )}
            />
          </>
        )}
        <Route
          path="*"
          element={<PageNotFound />}
        />
      </Route>
    </Routes>
  );
};

export default ExecuteRoutes;
