import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FlagSharp from '@mui/icons-material/FlagSharp';
import {
  DetailCardTimeline,
  AvailableTimeRow,
  HomeRow,
  LocationRow,
  StopRow,
  Mileage,
  generateTimestamp
} from '../DetailCard/DetailCardTimeline';
import { getLocalizedTimeRange } from '../../../utils/datetimes';
import {
  DROPOFF,
  EMPTY_TO_HOME,
  HOME,
  LOADED,
  PICKUP,
  RECOMMENDED_HOME,
  SCHEDULED_HOME,
  STOP
} from '../helpers/constants';
import { getOriginLabel, getDestinationLabel } from '../helpers/dispatchingScripts';
import { LoadsSlideoutCardTimelineProps, Submove } from '../types';

export const LoadsSlideoutCardTimeline = ({ selected, currentDriver, loadOption, showAvailable, openHosDialog, openExplainPtaDialog }: LoadsSlideoutCardTimelineProps) => {
  const { showSourceSuggestions } = useFlags();

  if (!currentDriver) return null;
  const isSource = showSourceSuggestions && loadOption.has_source;

  const renderSubmoves = (submoves: Submove[]) => (
    <>
      {submoves[0].type === LOADED && (() => {
        const etd = generateTimestamp(loadOption.est_pickup_time, loadOption.pickup_timezone);
        const elt = getLocalizedTimeRange(loadOption.pickup_est_loading_start_time, loadOption.pickup_est_loading_end_time, loadOption.pickup_timezone);
        return (
          <LocationRow
            label={getOriginLabel(loadOption.orig_live_flag, isSource)}
            start={loadOption.pickup_start_time}
            end={loadOption.pickup_end_time}
            estimates={{ ELT: elt, ETD: etd }}
            timezone={loadOption.pickup_timezone}
            location={`${loadOption.pickup_city}, ${loadOption.pickup_state}`}
            mileage={loadOption.deadhead_miles}
            selected={selected}
            hideAppointment={isSource}
          />
        );
      })()}
      {submoves.map((move) => {
        switch (move.end_location_type) {
          case PICKUP:
            const etd = generateTimestamp(loadOption.est_pickup_time, move.end_timezone);
            const elt = getLocalizedTimeRange(loadOption.pickup_est_loading_start_time, loadOption.pickup_est_loading_end_time, move.end_timezone);
            return (
              <LocationRow
                label={getOriginLabel(loadOption.orig_live_flag, isSource)}
                start={loadOption.pickup_start_time}
                end={loadOption.pickup_end_time}
                estimates={{ ELT: elt, ETD: etd }}
                timezone={move.end_timezone}
                location={move.end_city_state}
                mileage={move.distance}
                selected={selected}
                hideAppointment={isSource}
              />
            );
          case DROPOFF:
            const eta = generateTimestamp(loadOption.est_dropoff_time, move.end_timezone);
            const eult = getLocalizedTimeRange(loadOption.dropoff_est_unloading_start_time, loadOption.dropoff_est_unloading_end_time, move.end_timezone);
            return (
              <LocationRow
                label={getDestinationLabel(loadOption.dest_drop_flag, isSource)}
                start={loadOption.dropoff_start_time}
                end={loadOption.dropoff_end_time}
                estimates={{ ETA: eta, EULT: eult }}
                timezone={move.end_timezone}
                location={move.end_city_state}
                mileage={move.distance}
                icon={<FlagSharp />}
                selected={selected}
                hideAppointment={isSource}
              />
            );
          case HOME:
          case SCHEDULED_HOME:
          case RECOMMENDED_HOME:
            if (move.type.includes(HOME)) {
              const type = move.end_location_type;
              return (
                <HomeRow
                  label={`${type === SCHEDULED_HOME ? 'Scheduled ' : type === RECOMMENDED_HOME ? 'Recommended ' : ''}Home Time`}
                  time={move.start_time}
                  timezone={move.start_timezone}
                  location={move.start_city_state}
                  selected={selected}
                  timeWindow={getLocalizedTimeRange(move.start_time, move.end_time, move.start_timezone)}
                />
              );
            }
            return <Mileage mileage={move.distance} />;
          case STOP:
            return (
              <StopRow
                estimate={move.end_time}
                timezone={move.end_timezone}
                location={move.end_city_state}
                mileage={move.distance}
                selected={selected}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );

  const renderClassic = () => {
    if (loadOption.movement_type === EMPTY_TO_HOME) {
      let location = '';
      if (loadOption.destination_city && loadOption.destination_state) {
        location = `${loadOption.destination_city}, ${loadOption.destination_state}`;
      }

      return (
        <>
          <Mileage mileage={loadOption.deadhead_miles} />
          <HomeRow
            label="Home Time"
            time={loadOption.est_dropoff_time}
            timezone={loadOption.dropoff_timezone}
            location={location}
            selected={selected}
          />
        </>
      );
    }

    const pickupEtd = generateTimestamp(loadOption.est_pickup_time, loadOption.pickup_timezone);
    const pickupElt = getLocalizedTimeRange(loadOption.pickup_est_loading_start_time, loadOption.pickup_est_loading_end_time, loadOption.pickup_timezone);
    const dropoffEta = generateTimestamp(loadOption.est_dropoff_time, loadOption.dropoff_timezone);
    const dropoffEult = getLocalizedTimeRange(loadOption.dropoff_est_unloading_start_time, loadOption.dropoff_est_unloading_end_time, loadOption.dropoff_timezone);

    return (
      <>
        <LocationRow
          label={getOriginLabel(loadOption.orig_live_flag, isSource)}
          start={loadOption.pickup_start_time}
          end={loadOption.pickup_end_time}
          estimates={{ ELT: pickupElt, ETD: pickupEtd }}
          timezone={loadOption.pickup_timezone}
          location={`${loadOption.pickup_city}, ${loadOption.pickup_state}`}
          mileage={loadOption.deadhead_miles}
          selected={selected}
        />
        <LocationRow
          label={getDestinationLabel(loadOption.dest_drop_flag, isSource)}
          start={loadOption.dropoff_start_time}
          end={loadOption.dropoff_end_time}
          estimates={{ ETA: dropoffEta, EULT: dropoffEult }}
          timezone={loadOption.dropoff_timezone}
          location={`${loadOption.destination_city}, ${loadOption.destination_state}`}
          mileage={loadOption.loaded_miles}
          icon={<FlagSharp />}
          selected={selected}
        />
      </>
    );
  };

  return (
    <DetailCardTimeline selected={selected}>
      {showAvailable && (
        <AvailableTimeRow
          available={currentDriver.avail_for_dispatch_et}
          timezone={currentDriver.avail_timezone}
          selected={selected}
          hos={currentDriver.hos_status}
          openHosDialog={openHosDialog}
          openExplainPtaDialog={openExplainPtaDialog}
          location={currentDriver.predispatch_final_loc}
        />
      )}

      {loadOption?.submoves?.length > 0 ? renderSubmoves(loadOption.submoves) : renderClassic()}
    </DetailCardTimeline>
  );
};
