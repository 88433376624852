import instance from '../../../utils/axios_instance';

export const confirmAssignment = async (engineRunId, driverId, loadId, rating = null, movementOptionId = null) => {
  if (!engineRunId || !loadId || !driverId) return;
  const submission = {
    engine_run_id: engineRunId,
    assignments: [
      {
        driver_id: driverId,
        load_id: loadId,
        ...(movementOptionId && { movement_option_id: movementOptionId }),
        ...(rating && { overall_rating: rating })
      }
    ]
  };
  const response = await instance.post('/dispatching/assignments/', submission);
  return response;
};

export const getOriginLabel = (origLiveFlag, hideType = false) => {
  if (hideType) return 'Origin Pick Up';
  return origLiveFlag ? 'Origin Live Load' : 'Origin PreLoad Pick Up';
};

export const getDestinationLabel = (destDropFlag, hideType = false) => {
  if (hideType) return 'Destination Dropoff';
  return destDropFlag ? 'Destination PreLoad Dropoff' : 'Destination Live Unload';
};
