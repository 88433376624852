import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteRenderInputParams, InputLabel, SxProps, TextField } from '@mui/material';
import CloseSharp from '@mui/icons-material/CloseSharp';
import SearchSharp from '@mui/icons-material/SearchSharp';

import { debounce } from 'lodash';
import instance from '../utils/axios_instance';

interface LocationSearchParams {
  label: string;
  selectedLocations: { city_state: string; timezone?: string; } | null;
  setSelectedLocations: Dispatch<SetStateAction<{ city_state: string; timezone?: string; } | null>>;
  disabled?: boolean;
  sx?: SxProps | undefined;
}

const CitySearch = ({ label, selectedLocations, setSelectedLocations, disabled = false, sx = undefined }: LocationSearchParams) => {
  const [locationOptions, setLocationOptions] = useState<{ city_state: string; timezone?: string; }[]>([]);
  const [inputText, setInputText] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!!inputText) debouncedFetchLocationOptions(inputText);
    else setLocationOptions([]);
  }, [inputText]);

  const debouncedFetchLocationOptions = useCallback(debounce((started_with) => fetchLocationOptions({ started_with }), 500), []);

  const fetchLocationOptions = async (queryObject: Record<string, string>) => {
    await instance
      .post('/locations/', { ...queryObject })
      .then((res) => {
        const result = res.data.locations;
        const timezones = res.data.timezones;
        const cities = result.map((city_state: string, id: any) => ({ city_state,  timezone: timezones[id]}));
        setLocationOptions(cities);
      })
      .catch((err) => console.error(err));
  };

  const getInputField = (params: AutocompleteRenderInputParams) => {
    const displayValue = 'Search';
    const color = 'text.unselected';
    return (
      <TextField
        {...params}
        variant="standard"
        disabled={disabled}
        id="driver-rule-location-search"
        sx={{ backgroundColor: isOpen ? 'level2' : 'level1' }}
        placeholder={displayValue}
        InputLabelProps={{ sx: { '&.Mui-focused': { color: 'text.disabled' } } }}
        InputProps={{
          ...params.InputProps,
          sx: {
            input: {
              fontSize: '14px',
              '::placeholder': { color, opacity: 1 }
            },
            '&.MuiInput-root': { px: '16px' },
            '&.MuiAutocomplete-inputRoot': { pr: '16px !important' }
          },
          endAdornment: (
            <>
              {(!!selectedLocations && !disabled) && (
                <CloseSharp
                  sx={{
                    color: isOpen || !!selectedLocations ? 'text.primary' : 'text.unselected',
                    cursor: 'pointer',
                    mr: '16px'
                  }}
                  aria-label="clear-all-location-selections"
                  id="clear-all-driver-rule-location-selections"
                  onClick={() => {
                    setSelectedLocations(null);
                    setLocationOptions([]);
                  }}
                />
              )}
              <SearchSharp
                sx={{
                  color: isOpen || !!selectedLocations ? 'text.primary' : 'text.unselected',
                  cursor: 'pointer',
                  padding: 0,
                }}
              />
            </>
          )
        }}
      />
    );
  };

  return (
    <div>
      <InputLabel
        sx={{
          fontSize: 12,
          lineHeight: '18px',
          color: 'text.unselected',
          pb: '4px'
        }}
      >
        {label}
      </InputLabel>
      <Autocomplete
        onInputChange={(event, value) => setInputText(value)}
        getOptionLabel={(option) => option.city_state}
        onChange={(event, value) => setSelectedLocations(value)}
        value={selectedLocations}
        options={locationOptions}
        multiple={false}
        sx={sx}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        renderInput={(params) => getInputField(params)}
        disabled={disabled}
      />
    </div>
  );
};

export default CitySearch;
