import React, { ReactElement, useEffect, useState, useContext, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce, kebabCase } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment, { Moment } from 'moment-timezone';
import { Box, Dialog, Drawer, FilledInput, Link, Typography, InputAdornment, Avatar, CSSObject, styled, TypographyProps } from '@mui/material';
import KeyboardArrowLeftSharp from '@mui/icons-material/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharp from '@mui/icons-material/KeyboardArrowRightSharp';
import AddSharp from '@mui/icons-material/AddSharp';
import AttachMoneySharp from '@mui/icons-material/AttachMoneySharp';
import RemoveSharp from '@mui/icons-material/RemoveSharp';
import SearchSharp from '@mui/icons-material/SearchSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import PersonSharp from '@mui/icons-material/PersonSharp';
import PersonSearchSharp from '@mui/icons-material/PersonSearchSharp';
import ManageSearchSharp from '@mui/icons-material/ManageSearchSharp';
import { ODAvatar, ODButton, ODCloseButton, ODLaneSearchIcon, ODLaneIcon, ODToggle, ODTablePagination } from '@OptimalDynamics/core-ai-common-ui';
import { AMERICAN_DATE_TIME, SHORT_DATE_TIME, DATE_TIME_WITH_SEC_SPACE_DIV, DEFAULT_TIMEZONE, getTimeZoneAcronym } from '../../../utils/datetimes';
import { SearchForContext } from '../../../utils/context/searchForContext';
import { SessionContext } from '../../../utils/context/sessionCtx';
import { FORM_VARIANT, ODDateTimePicker } from '../../../common/ODDatePicker';
import CitySearch from '../../../common/CitySearch';
import ODTabs from '../../../common/ODTabs';
import { DispatchingDriver, DispatchingLoad, SourceSearch } from '../types';
import ODLoaderLabeled from '../components/ODLoader';
import LoadsSearchExternal from '../drivers/LoadsSearchExternal';
import { BY_ID, BY_PARAMS, DRIVER, EXTERNAL, INTERNAL, LOAD } from '../helpers/constants';
import { centsAbbreviate } from '../TabsGraphAccordion';

const DEFAULT_RADIUS = 200;
const MAXIMUM_RADIUS = 1000;
const MIN_MAX_RADIUS = 350;
const MILE_RADIUS_INCREMENT = 25;

const SUBJECT_SPECIFICS = {
  [DRIVER]: {
    modalTitle: 'Search For Drivers',
    searchIcon: <PersonSearchSharp />,
    resultsTitle: 'Driver Results',
    resultsIcon: <PersonSharp />,
    idSearchPrompt: 'Search By Driver',
    paramsSearchPrompt: 'Search By Availability',
    loading: 'Drivers Being Searched...'
  },
  [LOAD]: {
    modalTitle: 'Search For Loads',
    searchIcon: <ODLaneSearchIcon />,
    resultsTitle: 'Load Results',
    resultsIcon: <ODLaneIcon />,
    idSearchPrompt: 'Search By Load ID',
    paramsSearchPrompt: 'Search By Lane',
    loading: 'Loads Being Searched...'
  }
};

const isRadiusInvalid = (value: number, max: number) => (value < 0 || value > max);

interface FieldLabelProps {
  prompt: string;
}

const FieldLabel = ({ prompt }: FieldLabelProps) => (
  <Typography sx={{ fontSize: '12px', color: 'text.secondary' }}>{prompt}</Typography>
);

const openedAvatarMixin = (): CSSObject => ({
  right: 0,
  borderRadius: '4px 1px 1px 4px',
});

const closedAvatarMixin = (): CSSObject => ({
  left: '72px',
  top: '24px',
  position: 'fixed',
  borderRadius: '1px 4px 4px 1px',
});

const ToggleButton = styled(Avatar, { shouldForwardProp: (prop) => prop !== 'open' })<{ open: boolean }>(
  ({ theme, open }) => ({
    width: '16px',
    height: '40px',
    position: 'relative',
    top: 0,
    zIndex: 1300,
    backgroundColor: theme.palette.level3,
    cursor: 'pointer',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...(open && {
      ...openedAvatarMixin(),
      '& .MuiAvatar-root': openedAvatarMixin(),
    }),
    ...(!open && {
      ...closedAvatarMixin(),
      '& .MuiAvatar-root': closedAvatarMixin(),
    }),
  }),
);

const ODInput = styled(FilledInput, { shouldForwardProp: (prop) => prop !== 'sx' })<{ sx?: CSSObject }>(
  ({ theme, sx }) => ({
    width: '100%',
    height: '40px',
    px: 0.5,
    borderRadius: '0px',
    borderBottom: '1px solid',
    borderColor: theme.palette.level3,
    fontSize: '14px',
    backgroundColor: theme.palette.level1,
    ':hover': { backgroundColor: theme.palette.colors.neutral0 },
    input: {
      margin: '4px 0 0',
      padding: '10px 16px',
      backgroundColor: 'none',
      textOverflow: 'ellipsis'
    },
    '&.Mui-error': {
      color: theme.palette.alert.main,
      borderColor: theme.palette.alert.main,
      borderBottom: '1px solid'
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.disabled,
      color: theme.palette.text.disabled
    },
    ...sx
  })
);

const ErrorMessage = (props: TypographyProps) => (
  <Typography sx={{ fontSize: '12px', color: 'alert.main' }} {...props} />
);

interface DateTimeSpanProps {
  prompt: string;
  start: Moment | null;
  setStart: (_datetime: Moment) => void;
  end: Moment | null;
  setEnd: (_datetime: Moment) => void;
  timezone?: string;
  dateCrossError: boolean;
}

const DateTimeSpan = ({ prompt, start, setStart, end, setEnd, timezone, dateCrossError }: DateTimeSpanProps) => {
  const { internalSearchV2 } = useFlags();
  const needsStart = !!end && !start;
  const needsEnd = !!start && !end;
  const startEndCross = start?.isAfter(end);
  const displayTimeZone = internalSearchV2 && timezone ? getTimeZoneAcronym(timezone) : ''

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 2, '.MuiFormControl-root': { height: '40px' } }}>
      <Box>
        <FieldLabel prompt={`${prompt} Start ${displayTimeZone}`} />
        <ODDateTimePicker
          inputId={`${kebabCase(prompt)}-start`}
          currentValue={start}
          changeValue={setStart}
          variant={FORM_VARIANT}
          timezone={timezone}
          isErrored={needsStart || startEndCross || dateCrossError}
        />
        {needsStart && <ErrorMessage>Start date required</ErrorMessage>}
        {startEndCross && <ErrorMessage>End date must be after the Start date</ErrorMessage>}
        {dateCrossError && <ErrorMessage>Drop Off date must be after the Pick Up date</ErrorMessage>}
      </Box>
      <Box>
        <FieldLabel prompt={`${prompt} End ${displayTimeZone}`} />
        <ODDateTimePicker
          inputId={`${kebabCase(prompt)}-end`}
          currentValue={end}
          changeValue={setEnd}
          variant={FORM_VARIANT}
          timezone={timezone}
          isErrored={needsEnd || startEndCross}
        />
        {needsEnd && <ErrorMessage>End time required</ErrorMessage>}
      </Box>
    </Box>
  );
};

interface NumberInputProps {
  prompt: string;
  id: string;
  value: number;
  setValue: (_newValue: number) => void;
}

const NumberInput = ({ prompt, id, value, setValue }: NumberInputProps) => {
  const { noExternalLoadsLimit } = useFlags();
  const maxRadius = noExternalLoadsLimit ? MIN_MAX_RADIUS : MAXIMUM_RADIUS;
  const cutoff = MILE_RADIUS_INCREMENT - 1;
  const maxCutoff = maxRadius - cutoff;
  const errored = isRadiusInvalid(value, maxRadius);

  return (
    <Box>
      <FieldLabel prompt={prompt} />
      <ODInput
        id={`${id}-radius`}
        value={value}
        onChange={(event) => setValue(parseFloat(event.target.value) || 0)}
        disableUnderline={true}
        error={errored}
        endAdornment={(
          <InputAdornment position="end">
            <ODAvatar
              variant="transparent"
              size="small"
              onClick={() => { if (value < maxCutoff) setValue((value || 0) + MILE_RADIUS_INCREMENT); }}
              disabled={value >= maxCutoff}
            >
              <AddSharp />
            </ODAvatar>
          </InputAdornment>
        )}
        startAdornment={(
          <InputAdornment position="start">
            <ODAvatar
              variant="transparent"
              size="small"
              onClick={() => { if (value > cutoff) setValue((value || 0) - MILE_RADIUS_INCREMENT); }}
              disabled={value <= cutoff}
            >
              <RemoveSharp />
            </ODAvatar>
          </InputAdornment>
        )}
        sx={{ '.MuiFilledInput-input': { textAlign: 'center' } }}
      />
      {errored && <ErrorMessage>{`Radius must be between 0 and ${maxRadius}`}</ErrorMessage>}
    </Box>
  );
};

const Subheading = ({ label, isRequired }: { label: string, isRequired: boolean }) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplate: '32px / 32px 1fr auto',
      gap: 2,
      alignItems: 'center',
      padding: '4px 16px',
      borderRadius: '4px',
      border: '1px solid',
      borderColor: 'level3'
    }}
  >
    <ManageSearchSharp sx={{ justifySelf: 'center' }} />
    <Typography><strong>{label}</strong></Typography>
    <Typography sx={{ color: 'text.secondary' }}>
      {isRequired ? '*Required' : '*Optional'}
    </Typography>
  </Box>
);

interface CityState {
  city_state: string;
  timezone?: string;
}

interface SearchDialogProps {
  subject: 'load' | 'driver';
  currentRow: DispatchingDriver | DispatchingLoad;
  currentCard: ReactElement;
  internalSearchTable: ReactElement;
  onClose: () => void;
  defaultSearch: SourceSearch | null;
  openSimRate?: () => void;
}

const SearchDialog = ({ subject, currentRow, currentCard, internalSearchTable, defaultSearch, openSimRate, onClose }: SearchDialogProps) => {
  const specifics = SUBJECT_SPECIFICS[subject];
  const { setSessionUid } = useContext(SessionContext);
  const { useFreightFinder, internalSearchV2, noExternalLoadsLimit } = useFlags();

  const initPickUpCity = useMemo(() => {
    if (!!defaultSearch) return { city_state: defaultSearch?.pickup_city_state, timezone: defaultSearch.pickup_tz };
    const driver = currentRow as DispatchingDriver;
    return driver?.avail_city ? { city_state: `${driver.avail_city}, ${driver.avail_state}`, timezone: currentRow?.avail_timezone } : null;
  }, []);
  const initPickUpStart = useMemo(() => {
    if (!!defaultSearch) return moment.tz(defaultSearch?.pickup, DATE_TIME_WITH_SEC_SPACE_DIV, DEFAULT_TIMEZONE).subtract(24, 'h');
    if (subject === LOAD) {
      const driver = currentRow as DispatchingDriver;
      return moment.tz(driver?.avail_datetime, AMERICAN_DATE_TIME, DEFAULT_TIMEZONE);
    }
    const load = currentRow as DispatchingLoad;
    return moment.tz(load?.pickup_start_et, SHORT_DATE_TIME, DEFAULT_TIMEZONE);
  }, []);
  const initPickUpEnd = useMemo(() => initPickUpStart.clone().add(!!defaultSearch ? 48 : 12, 'h'), []);
  const initDropOffCity = useMemo(() => {
    if (!!defaultSearch) return { city_state: defaultSearch?.dropoff_city_state, timezone: !!defaultSearch ? defaultSearch.dropoff_tz : undefined };
    return null;
  }, []);
  const initDropOffEnd = useMemo(() => {
    if (!!defaultSearch) return moment.tz(defaultSearch?.dropoff, DATE_TIME_WITH_SEC_SPACE_DIV, DEFAULT_TIMEZONE).add(24, 'h');
    return null;
  }, []);
  const initDropOffStart = useMemo(() => {
    if (!!defaultSearch && !!initDropOffEnd) {
      const diff = initPickUpStart.diff(initDropOffEnd, 'h', true);
      if (Math.abs(diff) < 48) return initPickUpStart;
      return initDropOffEnd.clone().subtract(48, 'h');
    }
    return null;
  }, []);

  const { resetValues, setSearch, loading, hasResults, setHasResults, count, page, rowsPerPage, setPage, setRowsPerPage } = useContext(SearchForContext);
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [searchMode, setSearchMode] = useState((internalSearchV2 && !defaultSearch) ? INTERNAL : EXTERNAL);
  const [queryType, setQueryType] = useState(BY_PARAMS);
  const [showToggle, setShowToggle] = useState(false);
  const [id, setId] = useState('');
  const [pickUpCity, setPickUpCity] = useState<CityState | null>(initPickUpCity);
  const [pickUpStart, setPickUpStart] = useState<Moment | null>(initPickUpStart);
  const [pickUpEnd, setPickUpEnd] = useState<Moment | null>(initPickUpEnd);
  const [pickUpRadius, setPickUpRadius] = useState(DEFAULT_RADIUS);
  const [dropOffCity, setDropOffCity] = useState<CityState | null>(initDropOffCity);
  const [dropOffStart, setDropOffStart] = useState<Moment | null>(initDropOffStart);
  const [dropOffEnd, setDropOffEnd] = useState<Moment | null>(initDropOffEnd);
  const [dropOffRadius, setDropOffRadius] = useState(DEFAULT_RADIUS);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [debouncedIdSearch] = useState(() => (debounce((search) => setSearch(search), 300)));

  const validateInputs = () => {
    if (queryType === BY_PARAMS) {
      const maxRadius = noExternalLoadsLimit ? MIN_MAX_RADIUS : MAXIMUM_RADIUS;
      if (!pickUpStart) return false;
      if (subject === LOAD) {
        if (!(pickUpCity && pickUpEnd)) return false;
        if ((pickUpEnd && !pickUpStart) || (dropOffEnd && !dropOffStart)) return false;
        if (pickUpStart?.isAfter(dropOffStart) || dropOffStart?.isBefore(pickUpStart)) return false;
      }
      if (isRadiusInvalid(pickUpRadius, maxRadius) || isRadiusInvalid(dropOffRadius, maxRadius)) return false;
    } else if (!id) return false;
    return true;
  };

  useEffect(() => {
    setSearchEnabled(validateInputs());
  }, [pickUpCity, pickUpStart, pickUpEnd, pickUpRadius, dropOffCity, dropOffStart, dropOffEnd, dropOffRadius, id]);

  const onReset = () => {
    setPickUpCity(initPickUpCity);
    setPickUpStart(initPickUpStart);
    setPickUpEnd(initPickUpEnd);
    setPickUpRadius(DEFAULT_RADIUS);
    setDropOffCity(initDropOffCity);
    setDropOffStart(initDropOffStart);
    setDropOffEnd(initDropOffEnd);
    setDropOffRadius(DEFAULT_RADIUS);
    setId('');
  };

  const handleSearch = (queryTypeOverride = '') => {
    const search = {
      ...(subject === DRIVER ? {} : {
        driverId: currentRow?.driver?.driver_id,
        driverPk: currentRow?.driver?.id,
      }),
      params: {
        pickUpCity: pickUpCity?.city_state,
        pickUpRadius,
        pickUpStart,
        pickUpEnd,
        dropOffCity: dropOffCity?.city_state,
        dropOffRadius,
        dropOffStart,
        dropOffEnd,
      }
    };

    if ((queryTypeOverride || queryType) === BY_ID) debouncedIdSearch({ ...search, keyword: id });
    else setSearch(search);

    setSearchEnabled(false);
    setHasResults(false);
  };

  useEffect(() => {
    if (validateInputs()) handleSearch();
    setSessionUid(uuidv4());
  }, []);

  useEffect(() => {
    handleSearch();
  }, [id]);

  const handleQueryTypeChange = (newType: string) => {
    setQueryType(newType);
    setHasResults(false);
    if (validateInputs()) handleSearch(newType);
  };

  const handleSearchModeChange = (newMode: string) => {
    setSearchMode(newMode);
    if (newMode === EXTERNAL) setQueryType(BY_PARAMS);
  };

  const handleClose = () => {
    resetValues();
    onClose();
  };

  const searchPanelWidth = searchPanelOpen ? '471px' : '56px';

  let simRatePerMi = 0;
  if (!!defaultSearch) {
    simRatePerMi = parseFloat(defaultSearch?.simulated_rate_per_mile);
  }

  const idPrefix = `${subject}-search-dialog`;

  return (
    <Dialog
      id={idPrefix}
      open={true}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'colors.lightBlue0',
          minHeight: 'calc(100vh - 32px)',
          minWidth: 'calc(100vw - 32px)',
          margin: '16px',
          boxShadow: 'none',
        },
        '&.MuiDialog-root': { zIndex: 1300 },
        '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, .25)' }
      }}
    >
      <Box onMouseEnter={() => setShowToggle(true)} onMouseLeave={() => setShowToggle(false)}>
        <Drawer
          variant="permanent"
          open={searchPanelOpen}
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: 'colors.white',
              minHeight: 'unset',
              minWidth: 'unset',
              height: 'calc(100vh - 32px)',
              width: searchPanelWidth,
              borderRadius: '4px 0 0 4px',
              borderColor: 'level3',
              '&.MuiPickersPopper-paper': {
                height: 'unset',
                width: 'unset'
              }
            },
          }}
        >
          <Box
            sx={{
              pl: 1,
              py: 1,
              alignItems: 'center',
              width: 'calc(100% - 8px)',
              display: 'grid',
              gridTemplateRows: '40px',
              gridTemplateColumns: '40px 1fr auto 16px',
              columnGap: 2,
              borderBottom: 1,
              borderColor: 'level3',
              '> svg': { justifySelf: 'center' }
            }}
          >
            {specifics.searchIcon}
            {searchPanelOpen && <Typography><strong>{specifics.modalTitle}</strong></Typography>}
            <Link id="clear-all" component="button" variant="secondary" sx={{ whiteSpace: 'nowrap' }} onClick={onReset}>
              Reset All
            </Link>
            {showToggle && (
              <ToggleButton open={searchPanelOpen} onClick={() => setSearchPanelOpen(!searchPanelOpen)}>
                {searchPanelOpen ? <KeyboardArrowLeftSharp sx={{ color: 'text.primary' }} /> : <KeyboardArrowRightSharp sx={{ color: 'text.primary' }} />}
              </ToggleButton>
            )}
          </Box>
          {searchPanelOpen && (
            <>
              <Box sx={{ p: 2 }}>
                {currentCard}
              </Box>
              <Box
                sx={{
                  p: 2,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 1 auto',
                  overflow: 'auto',
                  gap: 2,
                  borderTopWidth: '1px',
                  borderTopStyle: 'solid',
                  borderTopColor: 'level3',
                }}
              >
                {(internalSearchV2 && searchMode === INTERNAL) && (
                  <>
                    <ODToggle
                      selected={queryType}
                      options={[
                        { value: BY_PARAMS, label: specifics.paramsSearchPrompt },
                        { value: BY_ID, label: specifics.idSearchPrompt }
                      ]}
                      onChange={(_e, v) => handleQueryTypeChange(v)}
                    />
                    {queryType === BY_ID && (
                      <Box>
                        <FieldLabel prompt={specifics.idSearchPrompt} />
                        <ODInput
                          id={`${subject}-id-search`}
                          value={id}
                          onChange={(event) => setId(event.target.value)}
                          endAdornment={(
                            <InputAdornment position="end" sx={{ columnGap: 2, color: 'text.primary' }}>
                              {!!id && (
                                <ODCloseButton
                                  id="clear-id"
                                  variant="transparent"
                                  size="small"
                                  onClick={() => setId('')}
                                />
                              )}
                              <SearchSharp />
                            </InputAdornment>
                          )}
                          disableUnderline={true}
                          sx={{
                            '#clear-id': { display: 'none' },
                            '&:hover': { '#clear-id': { display: 'contents' } }
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
                {(queryType === BY_PARAMS && subject === LOAD) && (
                  <>
                    <Subheading label="Pick Up Details" isRequired />
                    <CitySearch
                      label="Enter Pick Up City"
                      selectedLocations={pickUpCity}
                      setSelectedLocations={setPickUpCity}
                    />
                    <NumberInput
                      prompt="Enter Pick Up Radius (miles)"
                      id="origin"
                      value={pickUpRadius}
                      setValue={setPickUpRadius}
                    />
                    <DateTimeSpan
                      prompt="Set Available for Pick Up"
                      start={pickUpStart}
                      setStart={setPickUpStart}
                      end={pickUpEnd}
                      setEnd={setPickUpEnd}
                      timezone={pickUpCity?.timezone}
                      dateCrossError={!!pickUpStart ? pickUpStart?.isAfter(dropOffStart) : false}
                    />
                    <Subheading label="Drop Off Details" isRequired={false} />
                    <CitySearch
                      label="Enter Drop Off City"
                      selectedLocations={dropOffCity}
                      setSelectedLocations={setDropOffCity}
                    />
                    <NumberInput
                      prompt="Enter Drop Off Radius (miles)"
                      id="destination"
                      value={dropOffRadius}
                      setValue={setDropOffRadius}
                    />
                    <DateTimeSpan
                      prompt="Set Available for Drop Off"
                      start={dropOffStart}
                      setStart={setDropOffStart}
                      end={dropOffEnd}
                      setEnd={setDropOffEnd}
                      timezone={dropOffCity?.timezone}
                      dateCrossError={!!dropOffStart ? dropOffStart?.isBefore(pickUpStart) : false}
                    />
                  </>
                )}
                {(queryType === BY_PARAMS && subject === DRIVER) && (
                  <>
                    <div>
                      <FieldLabel prompt="Set Available for Dispatch Before" />
                      <ODDateTimePicker
                        inputId=""
                        currentValue={pickUpStart}
                        changeValue={setPickUpStart}
                        variant={FORM_VARIANT}
                        // @ts-expect-error subject being confirmed means this will have an avail_tz
                        timezone={currentRow?.pickup_timezone}
                      />
                    </div>
                    <NumberInput
                      prompt="Enter Distance From Pick Up Radius (miles)"
                      id="origin"
                      value={pickUpRadius}
                      setValue={setPickUpRadius}
                    />
                  </>
                )}
              </Box>

              {queryType === BY_PARAMS && (
                <Box sx={{ p: '8px 16px', borderTop: 1, borderTopColor: 'level3' }}>
                  <ODButton
                    id={`search-${subject}s-${subject === LOAD ? `${searchMode}-` : ''}${queryType}`}
                    disabled={!searchEnabled}
                    variant="slideoutBlue"
                    onClick={() => { if (searchEnabled) handleSearch(); }}
                  >
                    Search
                  </ODButton>
                </Box>
              )}
            </>
          )}
        </Drawer>
      </Box>
      <Box
        sx={{
          width: `calc(100vw - 32px - ${searchPanelWidth})`,
          height: 'calc(100vh - 32px)',
          marginLeft: searchPanelWidth,
          position: 'absolute'
        }}
      >
        <Box
          sx={{
            height: 56,
            borderBottom: 1,
            borderBottomColor: 'level3',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            px: 2,
            justifyContent: 'space-between'
          }}
        >
          {(useFreightFinder && internalSearchV2 && subject === LOAD) ? (
            <ODTabs
              value={searchMode}
              setValue={(_e, v) => handleSearchModeChange(v)}
              tabOptions={[
                { value: INTERNAL, label: 'Internal Loads', icon: specifics.resultsIcon },
                { value: EXTERNAL, label: 'External Sources', icon: <SearchSharp />, isBeta: true }
              ]}
            />
          ) : (
            <Box
              sx={{
                height: 40,
                display: 'grid',
                gridTemplateColumns: '40px auto',
                alignItems: 'center',
                gap: 2,
                ml: -1,
                '> svg': { justifySelf: 'center' }
              }}
            >
              {specifics.resultsIcon}
              <Typography><strong>{specifics.resultsTitle}</strong></Typography>
            </Box>
          )}
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {simRatePerMi > 0 && (
              <>
                <Box
                  sx={{
                    height: '18px',
                    width: '18px',
                    backgroundColor: 'text.secondary',
                    borderRadius: '9px',
                    display: 'flex',
                    alignItems: 'center',
                    ':hover': {
                      backgroundColor: 'colors.neutral5',
                      cursor: 'pointer'
                    }
                  }}
                  onClick={openSimRate}
                >
                  <AttachMoneySharp sx={{ color: 'text.inverse', ml: '.5px' }} />
                </Box>
                <Typography sx={{ color: 'text.secondary' }}>
                  {`Simulated Rate / Mile: ${centsAbbreviate(simRatePerMi)}`}
                </Typography>
              </>
            )}
            <ODCloseButton onClick={handleClose} />
          </Box>
        </Box>
        <Box sx={{ m: 2, mb: 0, height: `calc(100% - ${searchMode === INTERNAL ? '130px' : '89px'})` }}>
          <Box sx={{ display: loading ? 'none' : 'contents' }}>
            {searchMode === INTERNAL && internalSearchTable}
            {searchMode === EXTERNAL && <LoadsSearchExternal />}
          </Box>
          {(loading || !hasResults) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                justifyContent: 'center',
                width: '100%',
                height: loading ? '100%' : '90%',
                color: loading ? 'text.primary' : 'text.secondary'
              }}
            >
              {loading && <ODLoaderLabeled loadingText={specifics.loading} color="optimalblue.main" />}
              {(!loading && !hasResults) && (
                <>
                  <InfoOutlined />
                  <Typography>{`No results were returned for this search. Kindly broaden your search to locate available ${subject}s.`}</Typography>
                </>
              )}
            </Box>
          )}
        </Box>
        {searchMode === INTERNAL && (
          <ODTablePagination
            sx={{ backgroundColor: 'level1', borderTop: 1, borderColor: 'level3', minHeight: '56px' }}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            // @ts-expect-error incompatible event types
            onPageChange={(e, newPage: number) => setPage(newPage)}
            // @ts-expect-error incompatible event types
            onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
            selectId={`${idPrefix}-page-select`}
            slotProps={{
              actions: {
                nextButton: { id: `${idPrefix}-page-next` },
                previousButton: { id: `${idPrefix}-page-previous` }
              }
            }}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default SearchDialog;
