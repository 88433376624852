import React, { useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { Box, Link, Typography } from '@mui/material';
import OpenInNewSharp from '@mui/icons-material/OpenInNewSharp';
import ViewListSharp from '@mui/icons-material/ViewListSharp';
import MailOutlineSharp from '@mui/icons-material/MailOutlineSharp';
import { ODAvatar, ODTable, ODTableBody, ODTableCell, ODTableContainer, ODTableHead, ODTableRow, ODTooltip } from '@OptimalDynamics/core-ai-common-ui';
import { useDispatch, useSelector } from 'react-redux';
import { CopyableLink } from '../../../common/CopyableLink';
import { getLocalizedIsoTimeRange, SHORT_DATE_TIME_TZ } from '../../../utils/datetimes';
import instance from '../../../utils/axios_instance';
import { SearchForContext } from '../../../utils/context/searchForContext';
import { formatWithUnit } from '../../../utils/math';
import ColumnHeaderSettings from '../../../common/ColumnHeaderSettings';
import { DispatchingHeader } from '../types';
import { LOAD_ID } from '../helpers/constants';
import { RootState } from '../../../store/reducers';
import { UpdateUserSettings } from '../../../store/actions';
import { SessionContext } from '../../../utils/context/sessionCtx';

const LOAD_DEETS = 'LOAD_DEETS';
const SOURCE_DEETS = 'SOURCE_DEETS';
const PICKUP_PREFIX = 'pickup';
const PICKUP_RADIUS = 'origin_to_pickup_miles';
const DROPOFF_PREFIX = 'dropoff';
const DROPOFF_RADIUS = 'destination_to_dropoff_miles';
const WEIGHT = 'weight';
const LENGTH = 'equipment_lengths';
const RATE_PER_MILES = 'rate_per_miles';
const DEADHEAD = 'empty_miles';
const AGE_IN_MINUTES = 'age_in_minutes';
const SOURCE_ACTION = 'book_link';

const divmod = (dividend: number, divisor: number) => [Math.floor(dividend / divisor), dividend % divisor];

interface ExternalSearchTableColumn {
  label: string;
  value: string;
  parent: string;
  visible: boolean;
  selectionDisabled: boolean;
  sortable: boolean;
  width: number;
  useTooltip: boolean;
}

const COLUMNS = [
  { label: 'Load ID', value: LOAD_ID, parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 120, useTooltip: false },
  { label: 'Pick Up Location', value: 'pickup_location', parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 150, useTooltip: true },
  { label: 'Pick Up Window', value: PICKUP_PREFIX, parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 200, useTooltip: false },
  { label: 'Pick Up Radius', value: PICKUP_RADIUS, parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 110, useTooltip: false },
  { label: 'Loaded Miles', value: 'loaded_miles', parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 100, useTooltip: false },
  { label: 'Drop Off Location', value: 'dropoff_location', parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 150, useTooltip: true },
  { label: 'Drop Off Window', value: DROPOFF_PREFIX, parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 200, useTooltip: false },
  { label: 'Drop Off Radius', value: DROPOFF_RADIUS, parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 110, useTooltip: false },
  { label: 'Equipment Type', value: 'equipment_types', parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 150, useTooltip: false },
  { label: 'Weight', value: WEIGHT, parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 80, useTooltip: false },
  { label: 'Length', value: LENGTH, parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 80, useTooltip: false },
  { label: 'Commodity Type', value: 'commodity', parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 150, useTooltip: true },
  { label: 'Stops', value: 'stops', parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 40, useTooltip: false },
  { label: 'Accessorial', value: 'accessorials', parent: LOAD_DEETS, visible: true, selectionDisabled: false, sortable: true, width: 150, useTooltip: true },
  { label: 'External Source', value: 'source', parent: SOURCE_DEETS, visible: true, selectionDisabled: true, sortable: true, width: 170, useTooltip: true },
  { label: 'Rate Per Mile', value: RATE_PER_MILES, parent: SOURCE_DEETS, visible: true, selectionDisabled: true, sortable: true, width: 140, useTooltip: false },
  { label: 'Driver Deadhead', value: DEADHEAD, parent: SOURCE_DEETS, visible: true, selectionDisabled: true, sortable: true, width: 150, useTooltip: false },
  { label: 'Age', value: AGE_IN_MINUTES, parent: SOURCE_DEETS, visible: true, selectionDisabled: true, sortable: true, width: 70, useTooltip: false },
  { label: 'Source Action', value: SOURCE_ACTION, parent: SOURCE_DEETS, visible: true, selectionDisabled: true, sortable: true, width: 150, useTooltip: false },
];

interface ExternalLoad {
  loaded_miles: number;
  load_id: string;
  shipper_name: string;
  commodity: string;
  accessorials: [];
  source: string;
  book_link: string;
  weight: number;
  equipment_types: string[];
  equipment_lengths: string[];
  empty_miles?: number;
  rate_per_miles: number | null;
  age_in_minutes: number;
  pickup_location: string;
  pickup_start: string;
  pickup_end: string;
  pickup_timezone: string;
  dropoff_location: string;
  dropoff_start: string;
  dropoff_end: string;
  dropoff_timezone: string;
  stops: number;
  total_stops: number;
  total_pickup: number;
  total_dropoff: number;
  total_other_types: number;
  is_email: boolean;
}

interface SearchParams {
  pickUpCity: string;
  pickUpRadius?: number;
  pickUpStart: Moment;
  pickUpEnd: Moment;
  dropOffCity?: string;
  dropOffRadius?: number;
  dropOffStart?: Moment;
  dropOffEnd?: Moment;
}

interface Search {
  driverPk: number;
  driverId: string;
  keyword?: string;
  params: SearchParams;
}

const formatData = (load: ExternalLoad, field: string) => {
  // @ts-expect-error TS objects to using a string as an index key
  const value = load[field];

  // If the source is an email, the book_link is expected to be null
  if (value === null && ![SOURCE_ACTION].includes(field)) return '-';

  switch (field) {
    case PICKUP_PREFIX:
    case DROPOFF_PREFIX:
      // @ts-expect-error TS objects to using a string as an index key
      const { [`${field}_start`]: start, [`${field}_end`]: end, [`${field}_timezone`]: timezone } = load;
      if (!!start) {
        if (!end) return moment(start).tz(timezone).format(SHORT_DATE_TIME_TZ);
        return getLocalizedIsoTimeRange(start, end, timezone);
      } return '-';
    case SOURCE_ACTION:
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {load.is_email ? (
            <>
              <MailOutlineSharp />
              <Typography>Email</Typography>
            </>
          ) : (
            <>
              <OpenInNewSharp sx={{ color: 'colors.semanticBlue' }} />
              <Link
                className="source-link"
                href={value}
                target="_blank"
                sx={{ color: 'colors.semanticBlue', textDecoration: 'underline' }}
              >
                Source Link
              </Link>
            </>
          )}
        </Box>
      );
    case LOAD_ID: 
      return (
        <CopyableLink copyText={value}>
          <Typography maxWidth={100} whiteSpace="nowrap" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {value}
          </Typography>
        </CopyableLink>
      );
    case LENGTH: 
      return formatWithUnit(value, 'ft');
    case WEIGHT: 
      return formatWithUnit(value, 'lbs');
    case DROPOFF_RADIUS:
    case PICKUP_RADIUS:
    case DEADHEAD:
      return formatWithUnit(Math.round(value), 'mi');
    case RATE_PER_MILES:
      return Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value);
    case AGE_IN_MINUTES:
      const [hours, minutes] = divmod(value, 60);
      const datum = `${minutes}m`;
      if (!hours) return datum;
      if (hours < 24) {
        return `${hours}h${datum}`;
      } 
      return `${Math.floor(hours / 24)}d`;
      
    default:
      if (Array.isArray(value) && value.length > 0) return value.join(', ');
      return value;
  }
};

const renderDataCell = (load: ExternalLoad, field: string, width: number, useTooltip: boolean, columns: ExternalSearchTableColumn[], index: number) => {
  const datum = formatData(load, field);
  const right = columns.filter((col) => col.visible).slice(index + 1).reduce((prev, curr) => prev + curr.width, 0);
  return (
    <ODTableCell
      sx={{
        width: `${width}px`,
        maxWidth: `${width}px`,
        minWidth: `${width}px`,
        '&:nth-last-child(-n+5)': {
          position: 'sticky',
          pr: 0,
          right: `${right}px !important`,
          zIndex: 3,
          backgroundColor: 'inherit'
        },
      }}
    >
      {(useTooltip && datum !== '-') ? (
        <ODTooltip title={datum}>
          <div>{datum}</div>
        </ODTooltip>
      ) : datum}
    </ODTableCell>
  );
};

const LoadsSearchExternal = () => {
  const { rowsPerPage, search, setLoading, setHasResults } = useContext(SearchForContext);
  const { sessionUid } = useContext(SessionContext);
  const { noExternalLoadsLimit, theUiBlueItself } = useFlags();
  const [results, setResults] = useState([]);
  const [columns, setColumns] = useState(cloneDeep(COLUMNS));
  const [settingsOpen, setSettingsOpen] = useState(false);
  const userSettings = useSelector((state: RootState) => state.userSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userSettings?.loadsSearchExternalColumns) {
      setColumns(userSettings.loadsSearchExternalColumns);
    }
  }, [userSettings?.loadsSearchExternalColumns]);

  useEffect(() => {
    const searchTyped = search as Search;
    const params: SearchParams = searchTyped.params;
    if (!params) return;
    const { pickUpStart, pickUpEnd, dropOffStart, dropOffEnd } = params;

    const request = {
      driver_id: searchTyped?.driverPk,
      origin: params?.pickUpCity,
      origin_radius: params?.pickUpRadius,
      destination_radius: params?.dropOffRadius,
      destination: params?.dropOffCity,
      pickup: pickUpStart?.millisecond(0)?.second(0), // query will not work if it has (m)sec
      dropoff: dropOffStart?.millisecond(0)?.second(0), // query will not work if it has (m)sec
      ...(!noExternalLoadsLimit && { limit: rowsPerPage }),
      pickup_duration: undefined as unknown as number,
      dropoff_duration: undefined as unknown as number,
      search_session_uid: sessionUid,
    };

    // Duration needs to be in *minutes* so we're getting the difference in minutes
    if (!!pickUpStart && !!pickUpEnd) request['pickup_duration'] = pickUpEnd.diff(pickUpStart, 'minutes');
    if (!!dropOffStart && !!dropOffEnd) request['dropoff_duration'] = dropOffEnd.diff(dropOffStart, 'minutes');

    setLoading(true);
    instance
      .post('/loads/external/', request)
      .then((res) => {
        const response = res.data.loads;
        setResults(response);
        setHasResults(response?.length > 0);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [search]);

  const updateHeaders = (header: DispatchingHeader) => {
    const colsUpdated = columns.map((item) => {
      if (item.label === header.label) {
        item.visible = !item.visible;
      }
      return item; 
    });
    setColumns(colsUpdated);
    dispatch(UpdateUserSettings({ ...userSettings, loadsSearchExternalColumns: colsUpdated }));
  };

  const visibleColumns = columns.filter((header) => header.visible);

  const srcColStartIdx = visibleColumns.findIndex((header) => header.parent === SOURCE_DEETS);
  const loadDetailsColumns = visibleColumns.slice(0, srcColStartIdx);
  const sourceDetailsColumns = visibleColumns.slice(srcColStartIdx);

  return (
    <Box
      sx={{
        width: '100%',
        height: 'max-content',
      }}
    >
      <ODTableContainer
        newStyling={theUiBlueItself}
        sx={{
          height: results?.length === 0 ? 'max-content' : 'calc(100vh - 121px)',
          '& .MuiTableCell-root > *': {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          },
          '& .MuiTableRow-root > .MuiTableCell-root:nth-last-of-type(5):not([colspan])': {
            borderLeft: 1,
            borderLeftColor: 'level3',
            px: 2
          },
          '& .MuiTableRow-root > .MuiTableCell-root:last-of-type:not([colspan])': {
            borderLeft: 'none'
          },
          '& .MuiTableRow-root > :nth-last-of-type(2)': {
            pr: 'inherit'
          },
        }}
      >
        <ODTable stickyHeader>
          <ODTableHead newStyling={theUiBlueItself}>
            <ODTableRow>
              <ODTableCell colSpan={loadDetailsColumns.length} sx={{ position: 'sticky', left: 0 }}>Load Details</ODTableCell>
              <ODTableCell
                colSpan={sourceDetailsColumns.length}
                sx={{ pl: 2, borderLeft: 1, borderLeftColor: 'level2', position: 'sticky', right: 0, zIndex: 4 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: '-12px' }}>
                  Source Details
                  <ODAvatar size="small" variant="transparent" onClick={() => setSettingsOpen(true)} sx={{ position: 'sticky', right: 0 }}>
                    <ViewListSharp />
                  </ODAvatar>
                </Box>
              </ODTableCell>
            </ODTableRow>
            <ODTableRow lastHeader={theUiBlueItself}>
              {visibleColumns.map((col, index) => {
                const right = visibleColumns.slice(index + 1).reduce((prev, curr) => prev + curr.width, 0);
                return (
                  <ODTableCell sx={{
                    width: `${col.width}px`,
                    maxWidth: `${col.width}px`,
                    minWidth: `${col.width}px`,
                    overflow: 'hidden',
                    '&:nth-last-child(-n+5)': {
                      position: 'sticky',
                      pr: 0,
                      right: `${right}px !important`,
                      zIndex: 4,
                      backgroundColor: 'inherit'
                    },
                  }}
                  >
                    {col.label}
                  </ODTableCell>
                );
              })}
            </ODTableRow>
          </ODTableHead>
          <ODTableBody>
            {results.map((load) => (
              <ODTableRow>
                {visibleColumns.map(({ value, width, useTooltip }, index) => renderDataCell(load, value, width, useTooltip, columns, index))}
              </ODTableRow>
            ))}
          </ODTableBody>
        </ODTable>
      </ODTableContainer>
      <ColumnHeaderSettings
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        // @ts-expect-error it's complaining that a child class has insufficient overlap with the parent
        onToggle={(col) => updateHeaders(col)}
        columns={columns}
        onReset={() => setColumns(cloneDeep(COLUMNS))}
        sx={{ '&.MuiDrawer-root': { zIndex: 1400 } }}
      />
    </Box>
  );
};

export default LoadsSearchExternal;
