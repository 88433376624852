import { TopLevelTabType } from '../../components/trailerAssignments/TopLevelTabs';
import { DetailTableRow } from '../../components/trailerAssignments/TAViewMatches/TADetailsTable';
import { TableRow } from '../../components/trailerAssignments/TrailerAssignmentsTable';
import { UPDATE_TRAILER_ASSIGNMENT_REDUCER } from '../constants/actionTypes';

const defaultFilterChoicesEntry = {
  order_id: [],
  commodity_type: [],
  commodity_id: [],
  shipper_id: [],
  origin_dispatch_region: [],
  pick_up_location: [],
  min_pick_up_window: null,
  max_pick_up_window: null,
  min_preloaded_trailer_arrival: null,
  max_preloaded_trailer_arrival: null,
  is_origin_live_load: [],
  destination_location: [],
  last_post_location: [],
  trailer__trailer_id: [],
  current_driver_or_team_id: [],
  min_destination_window: null,
  max_destination_window: null,
  suggested_equipment_type: [],
  trailer_details: [],
  yard_and_trailer_matches: [],
};
export const defaultMainTableFilterChoices = {
  [TopLevelTabType.ASSIGNED]: defaultFilterChoicesEntry,
  [TopLevelTabType.UNASSIGNED]: defaultFilterChoicesEntry,
};
export type TrailerAssignmentFilterChoices = typeof defaultFilterChoicesEntry;
export type TrailerAssignmentFilterChoicesContainer = typeof defaultMainTableFilterChoices;

export const defaultTADetailFilterChoices = {
  trailer__trailer_id: [],
  trailer__trailer_type: [],
  trailer__trailer_prev_load_commodity_type: [],
  trailer__trailer_prev_load_commodity_id: [],
  min_trailer_load_distance_miles: null,
  max_trailer_load_distance_miles: null,
  min_trailer_dwell_days: null,
  max_trailer_dwell_days: null,
  min_trailer_dwell_minutes: null,
  max_trailer_dwell_minutes: null,
  min_scheduled_trailer_service: null,
  max_scheduled_trailer_service: null,
  facility__name: [],
  yard_location: [],
};

export const defaultAssignmentHistoryFilterChoices = {
  order_id: [],
  trailer__trailer_prev_load_commodity_type: [],
  trailer__trailer_prev_load_commodity_id: [],
  trailer__trailer_type: [],
  min_trailer_load_distance_miles: null,
  max_trailer_load_distance_miles: null,
  min_trailer_dwell_days: null,
  max_trailer_dwell_days: null,
  min_trailer_dwell_minutes: null,
  max_trailer_dwell_minutes: null,
  facility__name: [],
  trailer_assignment: [],
  selected_yard: [],
  order_origin: [],
  trailer_assignment_requested_by: [],
  tms_label: [],
  min_date_assigned: null,
  max_date_assigned: null,
  min_preloaded_trailer_arrival: null,
  max_preloaded_trailer_arrival: null,
  min_scheduled_trailer_service: null,
  max_scheduled_trailer_service: null,
  min_destination_window: null,
  max_destination_window: null,
  order__commodity_type: [],
  order__commodity_id: [],
  order__shipper_id: [],
  pick_up_location: [],
  min_pick_up_window: null,
  max_pick_up_window: null,
  order__is_origin_live_load: [],
  destination_location: [],
  last_post_location: [],
  assigned_trailer: [],
  order__current_driver_or_team_id: [],
  order__origin_dispatch_region: [],
  order__suggested_equipment_type: [],
  yard_location: [],
  trailer_id: [],
  facility__type: [],
};

export type AssignmentHistoryFilterChoices = typeof defaultAssignmentHistoryFilterChoices;

const initialState: TrailerAssignmentState = {
  selectedLoadInfo: null,
  selectedTrailerInfo: null,
  showConfirmAssignmentModal: false,
  showEquipmentCheckModal: false,
  showTMSErrorModal: false,
  mainTableFilterChoices: defaultMainTableFilterChoices,
  prevMainTableFilterChoices: defaultMainTableFilterChoices,
  matchesFilterChoices: null,
  prevMatchesFilterChoices: null,
  assignmentHistoryTableFilterChoices: defaultAssignmentHistoryFilterChoices,
  prevAssignmentHistoryTableFilterChoices: defaultAssignmentHistoryFilterChoices,
  lastPathname: null,
  lastSearch: null,
};

export type TADetailFilterChoices = typeof defaultTADetailFilterChoices;

export type AllTADetailFilterChoices = Record<string, TADetailFilterChoices> | null;

interface TrailerAssignmentState {
  selectedLoadInfo: TableRow | null;
  selectedTrailerInfo: DetailTableRow | null;
  showConfirmAssignmentModal: boolean;
  showEquipmentCheckModal: boolean;
  showTMSErrorModal: boolean;
  mainTableFilterChoices: TrailerAssignmentFilterChoicesContainer;
  prevMainTableFilterChoices: TrailerAssignmentFilterChoicesContainer;
  matchesFilterChoices: AllTADetailFilterChoices;
  prevMatchesFilterChoices: AllTADetailFilterChoices;
  assignmentHistoryTableFilterChoices: AssignmentHistoryFilterChoices;
  prevAssignmentHistoryTableFilterChoices: AssignmentHistoryFilterChoices;
  lastPathname: string | null;
  lastSearch: string | null;
}

interface TrailerAssignmentAction {
  type: string;
  payload: any;
  error?: any;
}

const updateTrailerAssignment = (state: TrailerAssignmentState, action: TrailerAssignmentAction) => ({
  ...state,
  ...action.payload
});

const trailerAssignmentReducer = (state: TrailerAssignmentState = initialState, action: TrailerAssignmentAction | null = null): TrailerAssignmentState => {
  if (!action) return state;
  switch (action.type) {
    case UPDATE_TRAILER_ASSIGNMENT_REDUCER:
      return updateTrailerAssignment(state, action);
    default:
      return state;
  }
};

export default trailerAssignmentReducer;
