/* eslint-disable default-param-last */
import {
  SET_DRIVER_DISPATCHING_FILTER_OPTIONS,
  SET_DRIVERS_DISPATCHING_LIST,
  SET_DRIVERS_DISPATCHING_LIST_COUNT,
  SET_LOADS_DISPATCHING_LIST,
  SET_LOADS_DISPATCHING_LIST_COUNT,
  SET_DRIVER_STATUS,
  ADD_SELECTED_LOAD_ASSIGNMENT,
  REMOVE_SELECTED_LOAD_ASSIGNMENT,
  CLEAR_SELECTED_LOAD_ASSIGNMENT,
  SET_TOP_LOAD_OPTIONS,
  CLEAR_TOP_LOAD_OPTIONS,
  REMOVE_TOP_LOAD_OPTION,
  SET_OFFERED_LOAD_DETAILS,
  SET_DISPATCHING_ASSIGNMENT_LIST,
  SET_DISPATCHING_ASSIGNMENT_LIST_COUNT,
  CHECK_OR_SET_DATA_UPDATE,
  SET_DISPATCHING_ASSIGNMENT_HISTORY,
  SET_DISPATCHING_ASSIGNMENT_HISTORY_COUNT,
  SET_DISPATCHING_HISTORY_ACTIVE,
  SET_DISPATCHING_HISTORY_EXPIRATION_TIMING,
  SET_LOADING_STATUS,
  SET_LOAD_DISPATCHING_FILTER_OPTIONS,
  SET_DISPATCHING_ENGINE_RUN_ID,
  SET_DRIVERS_LIST,
  SET_ONE_ASSIGN_BY_LOAD,
  ADD_ASSIGN_BY_LOAD,
  REMOVE_ASSIGN_BY_LOAD,
  CLEAR_ASSIGN_BY_LOAD,
  BATCH_ADD_ASSIGN_BY_LOAD,
  SET_DISPATCHING_LOAD_OPTIONS,
  SET_DISPATCHING_PLANNING_HORIZON,
  STOP_POLLING_DISPATCHING,
  SET_DISPATCHING_DRIVER_MANAGER_FILTER_OPTIONS,
  SET_DRIVER_AVAILABILITY_DATA,
  SET_LOAD_AVAILABILITY_DATA,
  SET_KPI_CHARTS_DATA,
  SHOW_KPI_LOADER
} from '../constants/actionTypes';

import { removeByKeysValue } from '../../utils/helpers';

const initialState = {
  driversDispatchingList: [],
  driversDispatchingListCount: 0,
  loadsDispatchingList: [],
  loadsDispatchingListCount: 0,
  driverStatus: [],
  driverFilterOptions: null,
  loadFilterOptions: {},
  selectDriverIDs: [],
  selectLoadIDs: [],
  selectDriverLoadTable: {},
  selectMovementTable: {},
  topLoadOptions: [],
  offeredLoadDetails: {},
  assignmentList: [],
  assignmentListCount: null,
  isPollingDataUpdating: false,
  dataUpdated: null,
  taskId: null,
  history: [],
  historyCount: null,
  historyActive: false,
  expirationTiming: null,
  loadingStatus: false,
  engineRunId: null,
  planningHorizon: null,
  driversList: [],
  loadOptions: [],
  driverManagerOptions: [],
  driverAvailabilityData: null,
  loadAvailabilityData: null,
  kpiChartsData: null,
  showKpiLoad: false,
};

const setLoadingStatus = (state, { loadingStatus }) => ({
  ...state,
  loadingStatus,
});

const setDriversDispatchingList = (state, { list }) => ({
  ...state,
  driversDispatchingList: list,
  isPollingDataUpdating: false
});

const setDriversDispatchingListCount = (state, { count }) => ({
  ...state,
  driversDispatchingListCount: count
});

const setLoadsDispatchingList = (state, { list }) => ({
  ...state,
  loadsDispatchingList: list,
  isPollingDataUpdating: false
});

const setLoadsDispatchingListCount = (state, { count }) => ({
  ...state,
  loadsDispatchingListCount: count
});

const setDriverStatus = (state, { status }) => ({
  ...state,
  driverStatus: status
});

const setDriverAvailabilityData = (state, { data }) => ({
  ...state,
  driverAvailabilityData: data
});

const setLoadAvailabilityData = (state, { data }) => ({
  ...state,
  loadAvailabilityData: data
});

const setDriverDispatchingFilterOptions = (state, { options }) => ({
  ...state,
  driverFilterOptions: options
});

const setLoadDispatchingFilterOptions = (state, { options }) => ({
  ...state,
  loadFilterOptions: options
});

const setDispatchingEngineRunId = (state, { engineRunId }) => ({
  ...state,
  engineRunId
});

const setDispatchPlanningHorizon = (state, { planningHorizon }) => ({
  ...state,
  planningHorizon
});

const addLoadAssignment = (state, { payload }) => {
  const { driverID, loadID, movementID } = payload;
  const { selectDriverIDs: oldDriverIDs, selectDriverLoadTable: oldLoadTable, selectMovementTable: oldMovementTable } = state;

  oldLoadTable[driverID] = loadID;
  oldMovementTable[driverID] = movementID;

  return {
    ...state,
    selectDriverIDs: [...oldDriverIDs, driverID],
    selectDriverLoadTable: oldLoadTable,
    selectMovementTable: oldMovementTable,
  };
};

const removeLoadAssignment = (state, { driverID }) => {
  const { selectDriverIDs: oldDriverIDs, selectDriverLoadTable, selectMovementTable } = state;
  const selectDriverIDs = oldDriverIDs.filter((item) => item !== driverID);

  if (selectDriverLoadTable[driverID]) {
    delete selectDriverLoadTable[driverID];
    delete selectMovementTable[driverID];
  }

  return {
    ...state,
    selectDriverIDs,
    selectDriverLoadTable,
    selectMovementTable,
  };
};

const clearLoadAssignments = (state) => ({
  ...state,
  selectDriverIDs: [],
  selectDriverLoadTable: {},
  selectMovementTable: {},
});

const setTopLoadOptions = (state, { topLoadOptions }) => ({
  ...state,
  topLoadOptions
});

const removeTopLoadOption = (state, { topLoadOption }) => {
  const { topLoadOptions: prevOptions } = state;
  const { id: mvmtID, load_id: loadID, driver_id: driverID } = topLoadOption;

  const topLoadOptions = prevOptions.filter((option) => {
    const { id, load_id, driver_id } = option;
    return (id !== mvmtID && load_id !== loadID && driver_id !== driverID);
  });

  return {
    ...state,
    topLoadOptions,
  };
};

const clearTopLoadOptions = (state) => ({
  ...state,
  topLoadOptions: [],
});

const setOfferedLoadDetails = (state, { details }) => ({
  ...state,
  offeredLoadDetails: details
});

const setDispatchingAssignmentList = (state, { list }) => ({
  ...state,
  assignmentList: list
});

const setDispatchingAssignmentListCount = (state, { count }) => ({
  ...state,
  assignmentListCount: count
});

const checkOrSetDataUpdate = (state, { dataUpdated, taskId = null }) => {
  const { dataUpdated: prevUpdate } = state;

  if (prevUpdate === dataUpdated) {
    return state;
  }

  return {
    ...state,
    dataUpdated,
    taskId,
    isPollingDataUpdating: true
  };
};

const stopPollingDispatching = (state) => ({
  ...state,
  isPollingDataUpdating: false
});

const setDispatchingAssignmentHistory = (state, { history }) => ({
  ...state,
  history
});

const setDispatchingAssignmentHistoryCount = (state, { historyCount }) => ({
  ...state,
  historyCount
});

const setDispatchingHistoryActive = (state, { historyActive }) => ({
  ...state,
  historyActive
});

const setDispatchingHistoryExpirationTiming = (state, { expirationTiming }) => ({
  ...state,
  expirationTiming
});

const addedToAssignByLoad = (state, { payload }) => {
  const { loadID, driverID, movementID } = payload;
  const { selectLoadIDs: oldSelectLoadIDs, selectDriverLoadTable, selectMovementTable } = state;

  const selectLoadIDs = [...oldSelectLoadIDs, loadID];
  selectDriverLoadTable[loadID] = driverID;
  selectMovementTable[loadID] = movementID;

  return {
    ...state,
    selectLoadIDs,
    selectDriverLoadTable,
    selectMovementTable,
  };
};

const setOnlyOneAssignByLoad = (state, { payload }) => {
  const { loadID, driverID, movementID } = payload;

  return {
    ...state,
    selectLoadIDs: [loadID],
    selectDriverLoadTable: { [loadID]: driverID },
    selectMovementTable: { [loadID]: movementID },
  };
};

const removedAssignByLoad = (state, { payload }) => {
  const { loadID } = payload;
  const { selectLoadIDs: oldSelectLoadIDs, selectDriverLoadTable: oldDriverLoadTable, selectMovementTable: oldMovementTable } = state;
  const selectLoadIDs = oldSelectLoadIDs.filter((id) => id !== loadID);
  const selectDriverLoadTable = removeByKeysValue(loadID, oldDriverLoadTable);
  const selectMovementTable = removeByKeysValue(loadID, oldMovementTable);

  return {
    ...state,
    selectLoadIDs,
    selectDriverLoadTable,
    selectMovementTable,
  };
};

const clearAllFromAssignByLoad = (state) => ({
  ...state,
  selectLoadIDs: [],
  selectDriverLoadTable: {},
  selectMovementTable: {},
});

const batchAddAssignByLoad = (state, { payload }) => {
  const { loadIds, driverIds, movementIds } = payload;

  const selectDriverLoadTable = loadIds.reduce((table, id, index) => {
    table[id] = driverIds[index];
    return table;
  }, {});

  const selectMovementTable = loadIds.reduce((table, id, index) => {
    table[id] = movementIds[index];
    return table;
  }, {});

  return {
    ...state,
    selectLoadIDs: loadIds,
    selectDriverLoadTable,
    selectMovementTable,
  };
};

const setDriversList = (state, { driversList }) => ({
  ...state,
  driversList
});

const setKpiChartsData = (state, { charts }) => ({
  ...state,
  kpiChartsData: charts
});

const setDispatchingLoadOptions = (state, { loadOptions }) => ({
  ...state,
  loadOptions
});

const setDispatchingDriverManagerFilterOptions = (state, { options }) => ({
  ...state,
  driverManagerOptions: options
});

const setShowKpiLoader = (state, { show }) => ({
  ...state,
  showKpiLoad: show
});

const dispatchingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DRIVERS_DISPATCHING_LIST:
      return setDriversDispatchingList(state, action);
    case SET_DRIVERS_DISPATCHING_LIST_COUNT:
      return setDriversDispatchingListCount(state, action);
    case SET_LOADS_DISPATCHING_LIST:
      return setLoadsDispatchingList(state, action);
    case SET_LOADS_DISPATCHING_LIST_COUNT:
      return setLoadsDispatchingListCount(state, action);
    case SET_DRIVER_STATUS:
      return setDriverStatus(state, action);
    case SET_DRIVER_DISPATCHING_FILTER_OPTIONS:
      return setDriverDispatchingFilterOptions(state, action);
    case SET_LOAD_DISPATCHING_FILTER_OPTIONS:
      return setLoadDispatchingFilterOptions(state, action);
    case ADD_SELECTED_LOAD_ASSIGNMENT:
      return addLoadAssignment(state, action);
    case REMOVE_SELECTED_LOAD_ASSIGNMENT:
      return removeLoadAssignment(state, action);
    case CLEAR_SELECTED_LOAD_ASSIGNMENT:
      return clearLoadAssignments(state);
    case SET_TOP_LOAD_OPTIONS:
      return setTopLoadOptions(state, action);
    case CLEAR_TOP_LOAD_OPTIONS:
      return clearTopLoadOptions(state);
    case REMOVE_TOP_LOAD_OPTION:
      return removeTopLoadOption(state, action);
    case SET_OFFERED_LOAD_DETAILS:
      return setOfferedLoadDetails(state, action);
    case SET_DISPATCHING_ASSIGNMENT_LIST:
      return setDispatchingAssignmentList(state, action);
    case SET_DISPATCHING_ASSIGNMENT_LIST_COUNT:
      return setDispatchingAssignmentListCount(state, action);
    case CHECK_OR_SET_DATA_UPDATE:
      return checkOrSetDataUpdate(state, action);
    case STOP_POLLING_DISPATCHING:
      return stopPollingDispatching(state);
    case SET_DISPATCHING_ASSIGNMENT_HISTORY:
      return setDispatchingAssignmentHistory(state, action);
    case SET_DISPATCHING_ASSIGNMENT_HISTORY_COUNT:
      return setDispatchingAssignmentHistoryCount(state, action);
    case SET_DISPATCHING_HISTORY_ACTIVE:
      return setDispatchingHistoryActive(state, action);
    case SET_DISPATCHING_HISTORY_EXPIRATION_TIMING:
      return setDispatchingHistoryExpirationTiming(state, action);
    case SET_LOADING_STATUS:
      return setLoadingStatus(state, action);
    case SET_DISPATCHING_ENGINE_RUN_ID:
      return setDispatchingEngineRunId(state, action);
    case SET_DISPATCHING_PLANNING_HORIZON:
      return setDispatchPlanningHorizon(state, action);
    case ADD_ASSIGN_BY_LOAD:
      return addedToAssignByLoad(state, action);
    case SET_ONE_ASSIGN_BY_LOAD:
      return setOnlyOneAssignByLoad(state, action);
    case REMOVE_ASSIGN_BY_LOAD:
      return removedAssignByLoad(state, action);
    case CLEAR_ASSIGN_BY_LOAD:
      return clearAllFromAssignByLoad(state, action);
    case BATCH_ADD_ASSIGN_BY_LOAD:
      return batchAddAssignByLoad(state, action);
    case SET_DRIVERS_LIST:
      return setDriversList(state, action);
    case SET_DISPATCHING_LOAD_OPTIONS:
      return setDispatchingLoadOptions(state, action);
    case SET_DISPATCHING_DRIVER_MANAGER_FILTER_OPTIONS:
      return setDispatchingDriverManagerFilterOptions(state, action);
    case SET_DRIVER_AVAILABILITY_DATA:
      return setDriverAvailabilityData(state, action);
    case SET_LOAD_AVAILABILITY_DATA:
      return setLoadAvailabilityData(state, action);
    case SET_KPI_CHARTS_DATA:
      return setKpiChartsData(state, action);
    case SHOW_KPI_LOADER:
      return setShowKpiLoader(state, action);
    default:
      return state;
  }
};

export default dispatchingReducer;
